import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import { Progress } from "antd";
import { Select, MenuItem } from "@material-ui/core";
import {
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  LineChart,
  Line,
} from "recharts";
import {
  getEngagementByType,
  getEngagementScore,
  getMonthWiseEngagement,
} from "../../actions/assessment";
import DisengagedEmployeesGraph from "./graphs/DisengagedEmployeesGraph";
import EngagedEmployeesGraph from "./graphs/EngagedEmployeesGraph";
import "./index.css";
import UpArrow from "../../assets/svg_icons/up_arrow.svg";
import DownArrow from "../../assets/svg_icons/down_arrow.svg";

const EngagementAnalysis = ({ token, adminCorporateId }) => {
  const [engagementMonth, setEngagementMonth] = useState(
    new Date().getMonth() + 1
  );
  const [engagementYear, setEngagementYear] = useState(
    new Date().getFullYear()
  );
  const [engagementData, setEngagementData] = useState([]);

  const [monthlyEngagementYear, setMonthlyEngagementYear] = useState(
    new Date().getFullYear()
  );
  const [monthlyEngagementData, setMonthlyEngagementData] = useState([]);
  const [engagementScore, setEngagementScore] = useState(0);
  const [engagementInt, setEngagementInt] = useState(0);
  const [averageBenchmark_Eng, setAverageBenchmark_Eng] = useState(0);
  const [lastMonthScore_Eng, setLastMonthScore_Eng] = useState(0);
  const [benchmarkDifference, setBenchmarkDifference] = useState(0);
  const [lastMonthDifference, setLastMonthDifference] = useState(0);
  const [registrationYear, setRegistrationYear] = useState(2022);

  const handleEngagementYearChange = (e) => {
    setEngagementYear(e.target.value);
  };
  const handleEngagementMonthChange = (e) => {
    setEngagementMonth(e.target.value);
  };

  const handleMonthlyEngagementYearChange = (e) => {
    setMonthlyEngagementYear(e.target.value);
  };

  const calcEngagementScore = async () => {
    try {
      const res = await getEngagementScore(token, adminCorporateId);
      setEngagementScore(res.data.score);
      setEngagementInt(res.data.interpretation);
      setAverageBenchmark_Eng(res.data.average_benchmark);
      setLastMonthScore_Eng(res.data.previousMonthScore);
      setBenchmarkDifference(res.data.benchmark);
      setLastMonthDifference(res.data.score - res.data.previousMonthScore);
    } catch (error) {
      console.log(error);
    }
  };
  const getEngagementData = async () => {
    try {
      const res = await getEngagementByType(
        token,
        adminCorporateId,
        engagementYear,
        engagementMonth
      );
      setEngagementData(res.data.response);
      setRegistrationYear(res.data.registrationYear);
    } catch (error) {
      console.log(error);
    }
  };

  const getEngagementMontlyData = async () => {
    try {
      const res = await getMonthWiseEngagement(
        token,
        adminCorporateId,
        monthlyEngagementYear
      );
      setMonthlyEngagementData(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEngagementData();
  }, [engagementYear, engagementMonth]);

  useEffect(() => {
    getEngagementMontlyData();
  }, [monthlyEngagementYear]);
  useEffect(() => {
    calcEngagementScore();
  }, []);

  const COLORS = ["#7f6df9", "#f2505e", "#a781fb", "#f78630"];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const availableMonths = Array.from({ length: 12 }, (_, i) => i + 1).filter(
    (month) =>
      !(
        engagementYear === new Date().getFullYear() &&
        month > new Date().getMonth() + 1
      )
  );
  return (
    <>
      <div
        className="top-overview"
        style={{ display: "flex", marginLeft: "5%" }}
      >
        <div
          className="left-sectionstufftop"
          style={{
            marginTop: "3rem",
            height: "auto",
            width: "35%",
            padding: "20px 10px",
            borderRadius: "1.875rem",
            background: "#F6F8FF",
            boxShadow:
              "5px 0px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div className="top-lsst">
            <h3
              className="title-topoverview"
              style={{textAlign: "center",fontSize:"1.5em" }}
            >
              Engagement Score
            </h3>
            <div className="right-cbc">
              <div className="circle-rcbc">
                <Progress
                  type="circle"
                  percent={engagementScore*10}
                  format={(percent) => `${engagementScore}/10`}
                  strokeWidth={10}
                  strokeColor="#F9CE55"
                />
                <p>{engagementInt}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="right-details-div">
          <div className="avg-benchmark-div">
            {/* <AverageSVG /> */}
            <h6 className="title-topoverview" style={{ fontWeight: 500, fontSize:"1.5em" }}>
              Average benchmark: {averageBenchmark_Eng}
            </h6>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <div className="up-down-div">
                {benchmarkDifference > 0 ? (
                  <img src={UpArrow} alt="up_arrow" />
                ) : (
                  <img src={DownArrow} alt="up_arrow" />
                )}
                <Typography>{Math.abs(benchmarkDifference)}</Typography>
              </div>
              <h6
                className="title-topoverview"
                style={{
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  marginLeft: "0.75rem",
                }}
              >
                Average benchmark
              </h6>
            </div>
          </div>
          <div className="divider"></div>
          <div className="last-month-score-div">
            {/* <MonthSVG /> */}
            <h6 className="title-topoverview" style={{ fontWeight: 500, fontSize:"1.5em" }}>
              Last month's Score: {lastMonthScore_Eng}
            </h6>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <div className="up-down-div">
                {lastMonthDifference > 0 ? (
                  <img src={UpArrow} alt="up_arrow" />
                ) : (
                  <img src={DownArrow} alt="up_arrow" />
                )}
                <Typography>{Math.abs(lastMonthDifference)}</Typography>
              </div>
              <h6
                className="title-topoverview"
                style={{
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  marginLeft: "0.75rem",
                }}
              >
                Since Last Month
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div
        className="top-overview"
        style={{ marginTop: "2rem", width: "100%", marginLeft: "5%" }}
      >
        <div
          className="left-sectionstufftop"
          style={{
            height: "auto",
            width: "35%",
            borderRadius: "1.875rem",
            background: "#FFF",
            boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.25)",
            padding: "20px 10px",
          }}
        >
          <div
            className="top-lsst"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="pieChart-Div">
              <Box display="flex" justifyContent="flex-end">
                <Select
                  value={engagementYear}
                  onChange={handleEngagementYearChange}
                >
                  {Array.from(
                    {
                      length: new Date().getFullYear() - (registrationYear - 1),
                    },
                    (_, i) => (
                      <MenuItem value={registrationYear + i} key={i}>
                        {registrationYear + i}
                      </MenuItem>
                    )
                  )}
                </Select>
                <Select
                  value={engagementMonth}
                  onChange={handleEngagementMonthChange}
                >
                  {availableMonths.map((month, index) => (
                    <MenuItem key={month} value={month}>
                      {monthNames[index]}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <div className="pie-div">
                {engagementData.every((item) => item.value === 0) ? (
                  <Typography className="notFound-text">
                    No Data Found
                  </Typography>
                ) : (
                  <PieChart width={250} height={300}>
                    <Pie
                      data={engagementData}
                      fill="#8884d8"
                      dataKey="value"
                      label
                    >
                      {engagementData &&
                        engagementData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                    </Pie>
                  </PieChart>
                )}
              </div>
            </div>
            <div>
              <Typography
                style={{
                  marginBottom: "0.3rem",
                  fontWeight: "500",
                  fontSize: "1.25rem",
                  color: "#0D0D0D",
                  marginTop: "1rem",
                }}
              >
                Engagement By Type
              </Typography>
              <Typography style={{ fontSize: "0.8rem", color: "#AFACAC" }}>
                Find out which features are liked by employees
              </Typography>
              <ul>
                {engagementData.map((data, index) => (
                  <li>
                    <Typography
                      style={{ fontSize: "0.8rem", color: COLORS[index] }}
                    >
                      {data.name} - {data.value}%
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="top-overview">
          <div
            className="left-sectionstufftop"
            style={{
              height: "auto",
              width: "65%",
              marginLeft: "2rem",
              borderRadius: "1.875rem",
              background: "#FFF",
              boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.25)",
              padding: "20px 10px",
            }}
          >
            <div className="top-lsst">
              <div className="monthlyEngagement-graph">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Select
                    value={monthlyEngagementYear}
                    onChange={handleMonthlyEngagementYearChange}
                    style={{ marginLeft: "10vw" }}
                  >
                    {Array.from(
                      {
                        length:
                          new Date().getFullYear() - (registrationYear - 1),
                      },
                      (_, i) => (
                        <MenuItem value={registrationYear + i} key={i}>
                          {registrationYear + i}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </div>
                <LineChart
                  width={350}
                  height={400}
                  data={monthlyEngagementData}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis ticks={[0, 1, 2, 3, 4, 5]} domain={[0, 5]}/>
                  <Tooltip />
                  <Line type="monotone" dataKey="score" stroke="#8884d8" />
                </LineChart>
              </div>
              <Typography
                style={{
                  marginBottom: "0.3rem",
                  fontWeight: "500",
                  fontSize: "1.25rem",
                  color: "#0D0D0D",
                  marginTop: "1rem",
                }}
              >
                Engagement Monthly Split
              </Typography>
              <Typography style={{ fontSize: "0.8rem", color: "#AFACAC" }}>
                Find out in which months employees were most engaged
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div
        className="top-overview"
        style={{
          marginTop: "2rem",
          width: "100%",
          marginLeft: "5%",
        }}
      >
        <div
          className="left-sectionstufftop"
          style={{
            borderRadius: "2.29444rem",
            height: "550px",
            width: "37.5%",
            padding: "2rem",
            border: "0.765px solid var(--violet2, #C894C4)",
            background: "#F6F8FF",
          }}
        >
          <DisengagedEmployeesGraph
            token={token}
            adminCorporateId={adminCorporateId}
          />
        </div>
        <div
          className="left-sectionstufftop"
          style={{
            borderRadius: "2.29444rem",
            height: "550px",
            width: "37.5%",
            padding: "2rem",
            border: "0.765px solid var(--violet2, #C894C4)",
            background: "#F6F8FF",
            marginLeft: "2rem",
          }}
        >
          <EngagedEmployeesGraph
            token={token}
            adminCorporateId={adminCorporateId}
          />
        </div>
      </div>
    </>
  );
};

export default EngagementAnalysis;
