import React, { useState, useEffect } from "react";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentDissatisfied from "@material-ui/icons/SentimentDissatisfied";
import MoodBad from "@material-ui/icons/MoodBad";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import pic from "../../assets/images/happymood.gif";
import "./index.css";
import { loadLifeTimeMoodCount } from "../../actions/features";
import {
  loadAllMoods,
  trackMood,
  loadMoodHistory,
} from "../../actions/features";
import MoodTracker from "../../components/MoodTracker/MoodTracker";
import "antd/dist/antd.css";
import { Steps } from "antd";

// import happymood from "../../assets/images/happymood.gif";
// import angrymood from "../../assets/images/angrymood.gif";
// import anxiousmood from "../../assets/images/anxiousmood.gif";
// import sadmood from "../../assets/images/sadmood.gif";
// import neutralmood from "../../assets/images/neutralmood.gif";
// import mobiletopbar from "../../assets/images/mobiletopbar.png";

import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Slider from "@material-ui/core/Slider";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Mood from "@material-ui/icons/Mood";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import { useHistory, useLocation } from "react-router-dom";


const { Step } = Steps;

export const MoodTrackerScreen = (props) => {
  const [currentDate, setCurrentDate] = useState();
  const [monday, setMonday] = useState("");
  const [sunday, setSunday] = useState("");
  const userToken = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const [moodCount, setMoodCount] = useState({});
  const [selectedMood, setSelectedMood] = useState("Happy");
  const [moods, setMoods] = useState([]);
  const [noOfDaysTracked, setNoOfDaysTracked] = useState(0);
  const [weekMap, setWeekMap] = useState({});
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    let sundayDate = getDateOnSunday(new Date());
    setSunday(sundayDate);
    let mondayDate = getDateOnMonday(new Date());
    setMonday(mondayDate);
    allMoods();
    loadMoodCount();
    loadAllMoodHistory(sundayDate);
  }, []);

  const loadMoodCount = async () => {
    try {
      const res = await loadLifeTimeMoodCount(userToken);
      setMoodCount(res.data);
    } catch (err) {
      
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const loadAllMoodHistory = async (date) => {
    try {
      const res = await loadMoodHistory({ date }, userToken);
      setNoOfDaysTracked(res.data.noOfDaysTracked);
      setWeekMap(res.data.weeklyTracker);
      dispatch(stopLoading())
    } catch (err) {
      dispatch(stopLoading())
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const allMoods = async () => {
    try {
      const res = await loadAllMoods(userToken);
      setMoods(res.data.allMoods);
      let latestMood = res.data.latestMood;
      if (latestMood === "M1") {
        setSelectedMood("Angry");
      }
      if (latestMood === "M2") {
        setSelectedMood("Anxious");
      }
      if (latestMood === "M3") {
        setSelectedMood("Sad");
      }
      if (latestMood === "M4") {
        setSelectedMood("Neutral");
      }
      if (latestMood === "M5") {
        setSelectedMood("Happy");
      }
    } catch (err) {
      dispatch(stopLoading())
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const sendSelectedMood = async (currentStep) => {
    try {
      dispatch(startLoading());
      let currentMood = moods[currentStep];
      const res = await trackMood({ moodID: currentMood.moodID }, userToken);
      if (currentStep === 0) setSelectedMood("Angry");
      if (currentStep === 1) setSelectedMood("Anxious");
      if (currentStep === 2) setSelectedMood("Sad");
      if (currentStep === 3) setSelectedMood("Neutral");
      if (currentStep === 4) setSelectedMood("Happy");
      loadMoodCount();
      loadAllMoodHistory(sunday);
      dispatch(stopLoading())
    } catch (err) {
      dispatch(stopLoading())
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const getMonthName = (i) => {
    i = Number(i) + 1;
    if (i === 1) {
      return "Jan";
    } else if (i === 2) {
      return "Feb";
    } else if (i === 3) {
      return "Mar";
    } else if (i === 4) {
      return "Apr";
    } else if (i === 5) {
      return "May";
    } else if (i === 6) {
      return "Jun";
    } else if (i === 7) {
      return "Jul";
    } else if (i === 8) {
      return "Aug";
    } else if (i === 9) {
      return "Sep";
    } else if (i === 10) {
      return "Oct";
    } else if (i === 11) {
      return "Nov";
    } else if (i === 12) {
      return "Dec";
    }
  };

  const getDateOnMonday = (currentDate) => {
    let dateRequested = currentDate;
    let daysToSubtract =
      dateRequested.getDay() === 0 ? 6 : dateRequested.getDay() - 1;
    let dateOnMonday = new Date(
      dateRequested.setDate(dateRequested.getDate() - daysToSubtract)
    );
    return dateOnMonday;
  };

  const getDateOnSunday = (currentDate) => {
    let dateRequested = currentDate;
    let daysToAdd =
      dateRequested.getDay() === 0 ? 0 : 7 - dateRequested.getDay();
    let dateOnSunday = new Date(
      dateRequested.setDate(dateRequested.getDate() + daysToAdd)
    );
    return dateOnSunday;
  };
  const muiTheme = createTheme({
    overrides: {
      MuiSlider: {
        thumb: {
          color: "#3D41D7",
        },
        track: {
          color: "white",
        },
        rail: {
          color: "black",
        },
        mark: {
          color: "white",
        },
        root: {
          height: "100px",
        },
      },
    },
  });
  return (
    <div className="dashboardofboxes">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <p className="dashboard-heading">Hey {userInfo.fullName}, welcome back!</p>
      <div className="containers-dashboardboxed mybookingsdashboard">
        <div className="left-mbd">
          <div className="mood-container">
            <MoodTracker
              selectedMood={selectedMood}
              sendSelectedMood={sendSelectedMood}
            />
          </div>
          <div className="bottom-lmbd">
            <p className="top-blmd">
              <label>No. of days tracked</label>
              <label>{noOfDaysTracked}</label>
            </p>
            <p className="top-blmd" style={{ marginTop: 20, border: "none" }}>
              <label>Mood Tracked This Week</label>
              <label></label>
            </p>
            <div className="rightleft-date">
              <input
                type="date"
                onChange={(e) => {
                  let newDate = new Date(e.target.value);
                  setCurrentDate(newDate);
                }}
              />
              <div
                className="icons-rld"
                onClick={() => {
                  let date = new Date(monday);
                  date.setDate(date.getDate() - 1);
                  let dateOnMonday = getDateOnMonday(date);
                  let dateOnSunday = getDateOnSunday(date);
                  if (dateOnMonday.getTime() !== monday.getTime()) {
                    setSunday(dateOnSunday);
                    setMonday(dateOnMonday);
                    loadAllMoodHistory(dateOnSunday);
                  }
                }}
              >
                <ChevronLeftIcon />
              </div>
              {monday && sunday && (
                <p>
                  {monday.getDate()} {getMonthName(monday.getMonth())} -{" "}
                  {sunday.getDate()} {getMonthName(sunday.getMonth())}
                </p>
              )}
              <div
                className="icons-rld"
                onClick={() => {
                  if (!(sunday >= new Date())) {
                    let date = new Date(sunday);
                    date.setDate(date.getDate() + 1);
                    let dateOnMonday = getDateOnMonday(date);
                    let dateOnSunday = getDateOnSunday(date);
                    if (dateOnMonday.getTime() !== monday.getTime()) {
                      setSunday(dateOnSunday);
                      setMonday(dateOnMonday);
                      loadAllMoodHistory(dateOnSunday);
                    }
                  }
                }}
              >
                <ChevronRightIcon />
              </div>
            </div>
            <div className="days-bar">
              <div
                className={weekMap["1"] === 0 ? "block-db" : "block-db active"}
              >
                <p>Mon</p>
                <div className="bar-bdb"></div>
              </div>
              <div
                className={weekMap["2"] === 0 ? "block-db" : "block-db active"}
              >
                <p>Tue</p>
                <div className="bar-bdb"></div>
              </div>
              <div
                className={weekMap["3"] === 0 ? "block-db" : "block-db active"}
              >
                <p>Wed</p>
                <div className="bar-bdb"></div>
              </div>
              <div
                className={weekMap["4"] === 0 ? "block-db" : "block-db active"}
              >
                <p>Thurs</p>
                <div className="bar-bdb"></div>
              </div>
              <div
                className={weekMap["5"] === 0 ? "block-db" : "block-db active"}
              >
                <p>Fri</p>
                <div className="bar-bdb"></div>
              </div>
              <div
                className={weekMap["6"] === 0 ? "block-db" : "block-db active"}
              >
                <p>Sat</p>
                <div className="bar-bdb"></div>
              </div>
              <div
                className={weekMap["0"] === 0 ? "block-db" : "block-db active"}
              >
                <p>Sun</p>
                <div className="bar-bdb"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-mbd">
          <div className="lifetimemoodcount">
            <p className="title-ltmc">Lifetime Mood Count</p>
            <div className="inner-ltmc">
              <div
                className="container-ltmc"
                style={{ backgroundColor: "rgba(251, 241, 216, 1)" }}
              >
                <p className="title-cltmc">Happy</p>
                <SentimentVerySatisfiedIcon />
                <p className="times-cltmc">{moodCount.M5 || 0} times</p>
              </div>
              <div
                className="container-ltmc"
                style={{ backgroundColor: "rgba(232, 235, 255, 1)" }}
              >
                <p className="title-cltmc">Neutral</p>
                <SentimentSatisfiedIcon />
                <p className="times-cltmc">{moodCount.M4 || 0} times</p>
              </div>
              <div
                className="container-ltmc"
                style={{ backgroundColor: "rgba(255, 233, 232, 1)" }}
              >
                <p className="title-cltmc">Sad</p>
                <SentimentVeryDissatisfiedIcon />
                <p className="times-cltmc">{moodCount.M3 || 0} times</p>
              </div>
              <div
                className="container-ltmc"
                style={{ backgroundColor: "rgba(251, 241, 216, 1)" }}
              >
                <p className="title-cltmc">Anxious</p>
                <MoodBad />
                <p className="times-cltmc">{moodCount.M2 || 0} times</p>
              </div>
              <div
                className="container-ltmc"
                style={{ backgroundColor: "rgba(220, 246, 233, 1)" }}
              >
                <p className="title-cltmc">Angry</p>
                <SentimentDissatisfied />
                <p className="times-cltmc">{moodCount.M1 || 0} times</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoodTrackerScreen;
