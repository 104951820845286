import React, { useEffect, useState } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { getEngagedDisengagedEmployee } from "../../../actions/assessment";
import Box from "@mui/material/Box";

export default function DisengagedEmployeesGraph({ token, adminCorporateId }) {
  const [disengagedData, setDisengagedData] = useState([]);
  const [disengagedYear, setDisengagedYear] = useState(
    new Date().getFullYear()
  );
  const [disengagedMonth, setdisengagedMonth] = useState(
    new Date().getMonth() + 1
  );
  const [registrationYear, setRegistrationYear] = useState(2022);

  const handleDisengagedyearChange = (e) => {
    setDisengagedYear(e.target.value);
  };
  const handleDisengagedMonthChange = (e) => {
    setdisengagedMonth(e.target.value);
  };

  const getDisEngagedEmployeeData = async () => {
    try {
      const res = await getEngagedDisengagedEmployee(
        token,
        adminCorporateId,
        disengagedYear,
        disengagedMonth
      );
      setDisengagedData(res.data.topDisengagedEmployees);
      setRegistrationYear(res.data.year);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDisEngagedEmployeeData();
  }, [disengagedYear, disengagedMonth]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const availableMonths = Array.from({ length: 12 }, (_, i) => i + 1).filter(
    (month) =>
      !(
        disengagedYear === new Date().getFullYear() &&
        month > new Date().getMonth() + 1
      )
  );

  return (
    <div className="top-lsst">
      <Typography
        style={{
          marginBottom: "0.3rem",
          fontWeight: "500",
          fontSize: "1.25rem",
          color: "#0D0D0D",
        }}
      >
        Disengaged Employees
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <div>
          <Select value={disengagedYear} onChange={handleDisengagedyearChange}>
            {Array.from(
              { length: new Date().getFullYear() - (registrationYear - 1) },
              (_, i) => (
                <MenuItem value={registrationYear + i} key={i}>
                  {registrationYear + i}
                </MenuItem>
              )
            )}
          </Select>
          <Select
            value={disengagedMonth}
            onChange={handleDisengagedMonthChange}
          >
            {availableMonths.map((month, index) => (
              <MenuItem key={month} value={month}>
                {monthNames[index]}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Box>

      <div className="employees">
        {disengagedData.map((employee) => (
          <div
            style={{
              display: "flex",
              backgroundColor: "#FFF",
              borderRadius: "0.38244rem",
              border: "1.224px solid #96A8FF",
              padding: "0.75rem",
              marginBottom: "1rem",
              fontSize: "1rem",
            }}
          >
            <div
              style={{
                background: "#96A8FF",
                fontWeight: "450",
                padding: "4px 1rem",
                borderRadius: "0.19119rem",
              }}
            >
              {" "}
              <Typography>{employee.score}</Typography>
            </div>
            <div
              style={{
                padding: "4px 2rem",
              }}
            >
              <Typography>
                {`${employee.first_name} ${employee.last_name}`}{" "}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
