import axios from "axios"

export const bookSession = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/bookSession",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

// export const CreateMeetingApi1=async(data)=>
//   await axios.post(
//     "https://api.cluster.dyte.in/v2/meetings",
//     data,
//     // {
//     //   // headers: {
//     //   //   Authorization: `Bearer ${token}`,
//     //   // },
//     // }
//   )

/*export const payment = async (data, token) =>
  await axios.post(process.env.REACT_APP_SERVER_URI + "/api/payment", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })*/

export const rescheduleSlot = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/rescheduleEvent",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const cancelSlot = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/cancelEvent",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const postFeedback = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/submitFeedback",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const checkLastSessionFeedback = (token) => {
  return axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/checkFeedback",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const setTaskComplete = async (data, token) =>
  await axios.post(process.env.REACT_APP_SERVER_URI + "/api/submitTask", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const sendReminder = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/sendReminder",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const sendCaseNotes = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/sendCaseNotes",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const sendUserAssignedTask = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/sendUserAssignedTask",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
