import axios from "axios";
axios.defaults.withCredentials = true;


export const createNewWorkshop = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/workshop",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );




export const loadallb2bhr = async () => {
  await axios.post(
    `${process.env.REACT_APP_SERVER_URI}/api/loadallb2bhr`,
    {
      // headers: {
      //     Authorization: `Bearer ${token}`,
      // },
    }
  );
}

export const loadAllsurvey = async (token) =>
  await axios.post(
    `${process.env.REACT_APP_SERVER_URI}/api/getAllsurvey`,
    {
      // headers: {
      //     Authorization: `Bearer ${token}`,
      // },
    }
  );





export const updatesurvey = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/surveyupdate",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deletesurvey = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/surveydelete",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const createstartchallenge = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/startchallenge",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );


export const viewallchallenge = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/viewallchallenge",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );


// export const 
export const viewmychallenge = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/viewmychallenge",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const viewhrchallenge = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/viewhrchallenge",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const createstartsurvey = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/createsurvey",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );






export const updateWorkshop = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/workshop/updateWorkshop",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const disableWorkshop = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/workshop/disableWorkshop",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const getSingleWorkshop = async (token, id) =>
  await axios.get(
    `${process.env.REACT_APP_SERVER_URI}/api/workshop/getWorkshop?id=${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const getAllActiveWorkshops = async (token) =>
  await axios.get(
    `${process.env.REACT_APP_SERVER_URI}/api/workshop/activeWorkshops`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const joinWorkshop = async (token, data) =>
  await axios.post(
    `${process.env.REACT_APP_SERVER_URI}/api/workshop/joinWorkshop`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const getCurrentWorkshops = async (token, date) =>
  await axios.post(
    `${process.env.REACT_APP_SERVER_URI}/api/workshop/currentWorkshops`,
    { date },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );


export const deletechallenge = async (data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/deletechallenge",
    data,
    // {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // }
  );
export const deleteworkshop = async (data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/workshop/workshopdelete",
    data,
    // {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // }
  );
export const getAllWorkshops = async (token, id) =>
  await axios.get(
    `${process.env.REACT_APP_SERVER_URI}/api/workshop/allWorkshops`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  export const addWorkshop = async (token, data) =>
    await axios.post(
      `${process.env.REACT_APP_SERVER_URI}/api/workshop/addWorkshop`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );