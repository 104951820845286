import React from "react";
import { useState } from 'react';

import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const reversedPayload = [...payload].reverse();
    return (
      <div style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc" }}>
        <p>{`Q${label+1}`}</p>
        {reversedPayload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color, fontWeight: "500" }}>
            {`${entry.name}: ${entry.value}%`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const SurveyChart = ({ data }) => {
  const transformedData = data.map((question, index) => {
    const transformedQuestion = { index };
    const total = Object.values(question.options).reduce((acc, curr) => acc + curr, 0);
    if(total !== 0){
      Object.keys(question.options).forEach(option => {
        const percentage = (question.options[option] / total) * 100;
        transformedQuestion[option] = parseFloat(percentage.toFixed(2));
      });
    }
    else{
      Object.keys(question.options).forEach(option => {
        transformedQuestion[option] = 0;
      });
    }
    return transformedQuestion;
  });
  const fill = ["#49AF7C", "#F9CA47", "#89D4AF", "#7E8ED9", "#B4BDE9"]
  return (
    <>
      <ResponsiveContainer width={500} height={400}>
        <BarChart data={transformedData} barSize={50}>
          <XAxis dataKey="index" tickFormatter={(index) => `Q${index + 1}`} />
          <YAxis tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          {Object.keys(data[0].options).map((option, optionIndex) => (
            <Bar
              key={option}
              dataKey={option}
              fill={fill[optionIndex % fill.length]}
              stackId="a"
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <div>
        {data.map((e, index) => (
          <p key={index}>Q{index + 1}: {e.question}</p>
        ))}
      </div>
    </>
  );
}

export default SurveyChart;