import React, { useState, useEffect } from "react";
import "./index.css";
import SectionBarChart from "../../components/SectionBarChart";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import SessionLineChart from "../../components/SessionLineChart";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import {
  getB2BFeedbacks,
  getB2BFilteredSessionInfo,
} from "../../actions/dataLoad";
import Rating from "@material-ui/lab/Rating";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import RatingStats from "../../components/Ratings/RatingStats";
import SessionAreaChart from "../../components/SessionAreaChart";
import { Select, MenuItem } from "@material-ui/core";
import Box from "@mui/material/Box";
import { useUserTokenSelector } from "store/reducers";

const obj = {
  ChartData: [
    { name: "Jan", uv: 0 },
    { name: "Feb", uv: 0 },
    { name: "Mar", uv: 0 },
    { name: "Apr", uv: 0 },
    { name: "May", uv: 0 },
    { name: "Jun", uv: 0 },
    { name: "Jul", uv: 0 },
    { name: "Aug", uv: 0 },
    { name: "Sep", uv: 0 },
    { name: "Oct", uv: 0 },
    { name: "Nov", uv: 0 },
    { name: "Dec", uv: 0 },
  ],
  sessionChartData: [
    { name: "1 Session", uv: 0 },
    { name: "2 Sessions", uv: 0 },
    { name: "3 Sessions", uv: 0 },
    { name: "4 Sessions", uv: 0 },
    { name: "4+ Sessions", uv: 0 },
  ],
};

const teamArray = ["Sales","Design","Product"]
const locationArray = ["Pune","Bangalore"]

export const Sessions = ({ adminCorporateId }) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [sessionCount, setSessionCount] = useState(obj.sessionChartData);
  const [monthlySessionData, setMonthlySessionData] = useState(obj.ChartData);
  const [selectedGender, setSelectedGender] = useState("Overall");
  const [totalSessions, setTotalSessions] = useState("");
  const [userTakingSessions, setUserTakingSessions] = useState("");
  const [monthlyBookingDataPercentage, setMonthlyBookingDataPercentage] =
    useState("");
  const [BookingDataPercentage, setBookingDataPercentage] = useState("");
  const [ratingData, setRatingData] = useState([]);
  const [totalRatings, setTotalRatings] = useState(0);
  const [averageRating, setAverageRating] = useState(0.0);
  const userToken = useUserTokenSelector();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState("All Time");
  const [years, setYears] = useState([currentYear]);
  const [response, setResponse] = useState({});
  const [selectedSessionsYear, setSelectedSessionsYear] = useState("All Time");
  const [overallDataResponse, setOverAllDataResponse] = useState({});
  const [selectedTherapyYear, setSelectedTherapyYear] = useState("All Time");
  const [selectedDepartment, setSelectedDepartment] = useState("All Teams");
  const [selectedLocation, setSelectedLocation] = useState("All Locations");
  const [teams, setTeams] = useState([]);
  const [locations, setLocations] = useState([]);

  function handleYearChange(event) {
    const nowSelectedYear = event.target.value;
    setSelectedYear(nowSelectedYear);
  }
  function handleTherapyYearChange(event) {
    const nowSelectedTherapyYear = event.target.value;
    setSelectedTherapyYear(nowSelectedTherapyYear);
  }
  function handleYearChangeSessions(event) {
    const nowSelectedSessionsYear = event.target.value;
    setSelectedSessionsYear(nowSelectedSessionsYear);
  }

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    getAllB2BFeedbacks(selectedGender);
  }, []);

  useEffect(() => {
    getAllB2BSessionInfo();
    getAllB2BFeedbacks();
  }, [selectedGender, selectedDepartment, selectedLocation]);

  useEffect(() => {
    const res = response;
    setMonthlySessionData(
      res?.data?.B2BSessionInfoData[selectedYear]?.monthlySessionData ||
        obj.ChartData
    );
  }, [selectedYear]);

  useEffect(() => {
    const res = response;
    setUserTakingSessions(
      res?.data?.B2BSessionInfoData[selectedTherapyYear]?.usersBookedSessions
    );
  }, [selectedTherapyYear]);

  useEffect(() => {
    const res = response;
    setSessionCount(
      res?.data?.B2BSessionInfoData[selectedSessionsYear]?.sessionCount ||
        obj.sessionChartData
    );
  }, [selectedSessionsYear]);

  const getAllB2BSessionInfo = async () => {
    try {
      const res = await getB2BFilteredSessionInfo(
        userToken,
        adminCorporateId,
        selectedDepartment,
        selectedLocation,
        selectedGender.toLowerCase(),
      );
      setResponse(res);
      if (selectedGender.toLowerCase() === "overall") {
        setOverAllDataResponse(res);
      }
      const yearsData= res.data.yearsArray;
      const tempYears = yearsData.map((year) => year);
      tempYears.sort((a, b) => b - a);
      tempYears.push("All Time");
      setYears(tempYears);
      const teamsData= teamArray;
      const tempTeams = teamsData.map((team) => team);
      tempTeams.push("All Teams");
      setTeams(tempTeams);
      const locationsData= locationArray;
      const tempLocations = locationsData.map((location) => location);
      tempLocations.push("All Locations");
      setLocations(tempLocations);
      setMonthlySessionData(
        res?.data?.B2BSessionInfoData[selectedYear]?.monthlySessionData.length >
          0
          ? res?.data?.B2BSessionInfoData[selectedYear]?.monthlySessionData
          : obj.ChartData
      );
      setSessionCount(
        res?.data?.B2BSessionInfoData[selectedSessionsYear]?.sessionCount.length > 0
          ? res.data?.B2BSessionInfoData[selectedSessionsYear].sessionCount
          : obj.sessionChartData
      );
      setTotalSessions(
        res?.data?.B2BSessionInfoData[selectedYear]?.totalSessions
      );
      setUserTakingSessions(
        res?.data?.B2BSessionInfoData[selectedTherapyYear]?.usersBookedSessions
      );
      setMonthlyBookingDataPercentage(
        res?.data?.B2BSessionInfoData[selectedYear]
          ?.monthlyBookingDataPercentage
      );
      setBookingDataPercentage(
        res?.data?.B2BSessionInfoData[selectedYear]?.BookingDataPercentage
      );
    } catch (err) {
      showError(err.response.data.error);
    }
  };

  const getAllB2BFeedbacks = async () => {
    try {
      const res = await getB2BFeedbacks(userToken, adminCorporateId, selectedDepartment, selectedLocation, selectedGender);
      const data = res.data.feedbacks;
      let ratings = [0, 0, 0, 0, 0];
      let sum = 0;
      data.map((rating) => {
        ratings[5 - rating.StarRating]++;
        sum += rating.StarRating;
      });
      setRatingData(ratings);
      setAverageRating(sum / data.length);
      setTotalRatings(data.length);
      setFeedbacks(res.data.feedbacks);
    } catch (err) {
      showError(err.response.data.error);
    }
  };
  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };
  return (
    <div className="overview usersstuff sessionsstuff">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <div className="top-overview">
        <div
          className="box-bubbles"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <div className="select-gender-div">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h5 className="select-gender-heading">
                Select Filters{" "}
                <span style={{ fontSize: "0.75rem" }}>
                  (it will work for all graphs){" "}
                </span>
              </h5>{" "}
            </div>
            <Box className="filters">
              <Select
                value={selectedGender}
                onChange={(event) => handleGenderChange(event)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value="Overall">Overall</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select> 
              {/* team and location filter */}
                  {/* <Select
                  value={selectedDepartment}
                  onChange={(event) => setSelectedDepartment(event.target.value)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                  >
                  {
                    teams.map((team) => (
                      <MenuItem value={team}>{team}</MenuItem>
                    ))
                  }
                  </Select>
                  <Select
                    value={selectedLocation}
                    onChange={(event) => setSelectedLocation(event.target.value)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                  {
                    locations.map((location) => ( <MenuItem value={location}>{location}</MenuItem>))
                  }
                  </Select> */}
            </Box>
          </div>
          <div style={{ width: "100%",height:"fit-content",marginBottom:"20px" }} className="left-sectionstufftop">
            <div
              className="top-lsst"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
                width: "90%",
              }}
            >
              <h3 className="title-topoverview" style={{fontSize:"1.5em"}}>Sessions Over Time </h3>
              <Box sx={{ minWidth: 120 }}>
                <Select
                  value={selectedYear}
                  onChange={(e) => handleYearChange(e)}
                  label="Year"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  {years.map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </Box>
            </div>
            <div style={{ marginLeft: 0 }} className="bottom-lsst">
              <div className="right-blsst">
                <SectionBarChart data={monthlySessionData} />
              </div>
            </div>
          </div>
          <div className="mid-graphs">
            <div
              style={{
                width: "100%",
                height: "fit-content",
                padding:"20px"
              }}
              className="left-sectionstufftop"
            >
              <h3 className="title-topoverview" style={{fontSize:"1.5em"}}>Recent Feedbacks</h3>
              {feedbacks.length > 0 && (
                <div style={{ margin: "1%" }}>
                  <RatingStats
                    ratings={ratingData}
                    ratingAverage={averageRating.toFixed(1)}
                    raterCount={totalRatings}
                  />
                </div>
              )}
            </div>

            <div className="right-sessionsstuff">
              <div className="container-ses">
                <Box>
                  <Select
                    value={selectedTherapyYear}
                    onChange={(e) => handleTherapyYearChange(e)}
                    label="Year"
                  >
                    {years.map((year) => (
                      <MenuItem value={year}>{year}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <p className="count-clus">{userTakingSessions}</p>
                <p className="desc-clus">
                  <PersonOutlineIcon /> Users taking Therapy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-overview"
        style={{ borderRadius: "12px", height: "fit-content", alignSelf:"center",margin:"auto" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
            height:"auto"
          }}
          className="top-lsst"
        >
          <h3 className="title-topoverview" style={{fontSize:"1.5em"}}>Users taking Repeat sessions</h3>
          <Box sx={{ minWidth: 120 }}>
            <Select
              value={selectedSessionsYear}
              onChange={(e) => handleYearChangeSessions(e)}
              label="Year"
            >
              {years.map((year) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </Box>
        </div>
        <div className="bottom-lsst">
          <div style={{ marginBottom: "4%" }} className="right-blsst">
            {/* <SectionLineChart /> */}
            <SessionAreaChart data={sessionCount} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sessions;
