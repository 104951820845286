import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./SessionFeedbackPopup.css";
import { RiStarSFill } from "react-icons/ri";
import { RiStarSLine } from "react-icons/ri";

const commentsOptions = {
  "1-3": [
    {
      title: "Therapist not on Time",
      emoji: "⌚︎",
    },
    {
      title: "App Issue",
      emoji: "📱",
    },
    {
      title: "Network Issue",
      emoji: "🚫",
    },
    {
      title: "Therapist wasn’t Listening",
      emoji: "🙉",
    },
    {
      title: "Unhelpful Approach",
      emoji: "😡",
    },
    {
      title: "Camera was Off",
      emoji: "👩‍💻",
    },
    {
      title: "Dissatisfied",
      emoji: "👎",
    },
    {
      title: "Other Concern",
      emoji: "😣",
    },
  ],
  "4-5": [
    {
      title: "Great Listener",
      emoji: "👂",
    },
    {
      title: "Great Communicator",
      emoji: "😃",
    },
    {
      title: "Felt Safe",
      emoji: "☺️",
    },
    {
      title: "Punctuality",
      emoji: "⏰",
    },
    {
      title: "Positive Attitude",
      emoji: "😌",
    },
    {
      title: "Felt Supported",
      emoji: "🫂",
    },
    {
      title: "Feeling Calm",
      emoji: "😇",
    },
    {
      title: "Loved Therapy",
      emoji: "🥰",
    },
  ],
};

const avatar = {
  female:
    "https://ucarecdn.com/5c10944e-91bc-4ee6-a259-23565aac6100/avatar3.png",
  male: "https://ucarecdn.com/e6d48801-632e-4d97-b297-bd15292f07ed/avatar2.png",
  other:
    "https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png",
};

const SessionFeedbackPopup = ({
  isOpen,
  onSessionRatingSubmit,
  sessionData
}) => {
  const [rating, setRating] = useState(0);
  const [selectedComments, setSelectedComments] = useState([]);
  const [message, setMessage] = useState("");
  const [ratingError, setRatingError] = useState(false);
  const [commentsError, setCommentsError] = useState(false);
  const { therapistName, therapistId, sessionId } = sessionData;

  const handleCommentClick = (e) => {
    setCommentsError(false);
    const { value, checked } = e.target;
    if (checked) {
      setSelectedComments((prev) => [...prev, value]);
    } else {
      setSelectedComments((prev) => prev.filter((comment) => comment !== value));
    }
  };

  const handleStarClick = (newRating) => {
    setRatingError(false);
    if (newRating > 3 && rating <= 3) {
      setSelectedComments([]);
    }
    else if (newRating <= 3 && rating > 3) {
      setSelectedComments([]);
    }
    setRating(newRating);
  }

  const handleRatingSubmit = () => {
    setRatingError(false);
    setCommentsError(false);

    if (rating === 0) {
      setRatingError(true);
      return;
    }
    else if (selectedComments.length === 0) {
      setCommentsError(true);
      return;
    }
    onSessionRatingSubmit({rating, sessionId, therapistId, message, comments: selectedComments});
  }

  return (
    <>
      {isOpen && (
        <div className="modal-container rating-popup">
          <div className="rating-div">
            <div className="rating-content">
              <h5 className="popup-heading">How was your last conversation?</h5>
              <img
                src={avatar["female"]}
                alt="user-avatar"
                className="user-img"
              />
              <h4 className="user-name">{therapistName}</h4>
              <div className="rating">
                {[...Array(5)].map((_, index) => (
                  <span key={index} onClick={() => handleStarClick(index+1)}>
                    {index < rating ? (
                      <RiStarSFill size={30} color="#FFAC33" />
                    ) : (
                      <RiStarSLine size={30} color="#B2B2B2" />
                    )}
                  </span>
                ))}
              </div>
              {ratingError && <p className="rating-error">Provide rating</p>}
              <ul className="options">
                {rating > 0 &&
                  (rating > 3
                    ? [...commentsOptions["4-5"]]
                    : [...commentsOptions["1-3"]]
                  ).map((comment, i) => (
                    <li>
                      <div className="option-div">
                        <p className="option-emoji">{comment.emoji}</p>
                        <p className="option-title">{comment.title}</p>
                      </div>

                      <label class="checkcontainer">
                        <input
                          key={comment.title}
                          type="checkbox"
                          name="ratingsub"
                          onChange={handleCommentClick}
                          value={comment.title}
                        />
                        <span class="radiobtn"></span>
                      </label>
                    </li>
                  ))}
              </ul>
              {commentsError && (
                <p className="rating-error">Select atleast one option</p>
              )}
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="ratingdesc"
                placeholder="Add your feedback"
                className="rating-desc"
              ></textarea>
              <button onClick={handleRatingSubmit} type="button" className="submit-btn">
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SessionFeedbackPopup;