import React, { PureComponent } from "react"
import {
  Radar,
  RadarChart,
  PolarAngleAxis,
  ResponsiveContainer,
} from "recharts"

const data = [
  {
    subject: "Mood 1",
    A: 10,
    fullMark: 10,
  },
  {
    subject: "Mood 2",
    A: 4,
    fullMark: 110,
  },
  {
    subject: "Mood 4",
    A: 3,
    fullMark: 10,
  },
  {
    subject: "Mood 3",
    A: 2,
    fullMark: 10,
  },
  {
    subject: "Mood 5",
    A: 1,
    fullMark: 10,
  },
]

export default class RadarChartOverview extends PureComponent {
  static demoUrl = "https://codesandbox.io/s/simple-radar-chart-rjoc6"

  render() {
    return (
      <ResponsiveContainer width='100%' height='100%'>
        <RadarChart cx='50%' cy='50%' outerRadius='60%' data={this.props.data}>
          {/* <PolarGrid /> */}
          <PolarAngleAxis dataKey='subject' />
          {/* <PolarRadiusAxis /> */}
          <Radar
            name='Mike'
            dataKey='A'
            stroke='#96A8FF'
            fill='#E8EBFF'
            fillOpacity={1}
            dot={{ fill: "#96A8FF", color: "#E8EBFF", r: 5 }}
          />
        </RadarChart>
      </ResponsiveContainer>
    )
  }
}
