import { useState } from "react";
import "./TeamLocationPopup.css";

const TeamLocationPopup = ({ isOpen, onSubmit, teamLocations }) => {
  const [selectedTeamName, setSelectedTeamName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleTeamNameChange = (e) => {
    setSelectedTeamName(e.target.value);
    setSelectedLocation("");
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  return (
    isOpen && (
      <div className="modal-container location-popup">
        <div className="location-div">
          <div className="location-content">
            <h3>Please select your Team and Location</h3>
            <div className="location-select-cont">
              <select value={selectedTeamName} onChange={handleTeamNameChange}>
                <option value="">Select Team</option>
                {Object.keys(teamLocations).map((teamName, idx) => (
                  <option key={idx} value={teamName}>
                    {teamName}
                  </option>
                ))}
              </select>
              <select value={selectedLocation} onChange={handleLocationChange}>
                <option value="">Select Location </option>
                {teamLocations[selectedTeamName]?.map((location, idx) => (
                  <option key={idx} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>
            <button disabled={!(selectedTeamName && selectedLocation)} onClick={() => onSubmit(selectedTeamName, selectedLocation)}>Submit</button>
          </div>
        </div>
      </div>
    )
  );
};

export default TeamLocationPopup;
