import React from "react";
import { useState } from "react";
import {
  BsArrowLeft,
  BsArrowRight,
  BsArrowUpRight,
  BsArrowUpLeft,
  BsArrowUp,
} from "react-icons/bs";
import "./MoodTracker.css";
const MoodTracker = ({ selectedMood, sendSelectedMood }) => {
  let emojis = [
    { emoji: "😰", mood: "Anxious", arrow: "BsArrowLeft" },
    { emoji: "😥", mood: "Sad", arrow: "BsArrowUpLeft" },
    { emoji: "😐", mood: "Neutral", arrow: "BsArrowUp" },
    { emoji: "🙂", mood: "Happy", arrow: "BsArrowUpRight" },
    { emoji: "😤", mood: "Angry", arrow: "BsArrowRight" },
  ];
  return (
    <div className="mood-card">
      <p className="mood-heading">How are you feeling today?</p>
      <div className="semi-circle"></div>
      <div className="mood-emojis">
        {emojis.map((emoji, index) => {
          return (
            <div
              key={index}
              className={
                selectedMood === emoji.mood
                  ? "mood-emoji-circle active"
                  : "mood-emoji-circle"
              }
              onClick={() => {
                sendSelectedMood((index + 1) % 5);
              }}
            >
              <span role="img" aria-label={emoji.mood}>
                {emoji.emoji}
              </span>
            </div>
          );
        })}
      </div>
      <div className="mood-arrows">
        {emojis.map((emoji, index) => {
          return (
            <div
              key={index}
              className={
                selectedMood === emoji.mood ? "mood-arrow active" : "mood-arrow"
              }
            >
              {emoji.arrow === "BsArrowLeft" && <BsArrowLeft size={30} />}
              {emoji.arrow === "BsArrowRight" && <BsArrowRight size={30} />}
              {emoji.arrow === "BsArrowUpRight" && <BsArrowUpRight size={30} />}
              {emoji.arrow === "BsArrowUpLeft" && <BsArrowUpLeft size={30} />}
              {emoji.arrow === "BsArrowUp" && <BsArrowUp size={30} />}
            </div>
          );
        })}
      </div>
      <p className="mood">{selectedMood}</p>
    </div>
  );
};

export default MoodTracker;
