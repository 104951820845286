import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import LeftMenu from "./LeftMenu";
import "./index.css";
import HeaderDashboardB2BHR from "./HeaderDashboardB2BHR";
import Users from "../Containers/Users";
import Sessions from "../Containers/Sessions";
import Overview from "../Containers/Overview";
import Support from "../Containers/Support/index.js";
import StartChallenge from "../Containers/StartChallenge/index.js";
import ViewChallenge from "../Containers/ViewChallenge/index.js";
import Organizational from "../Containers/Organizational/index.js";
import StartSurvey from "../Containers/StartSurvey/index.js";
import App from "../Containers/SlackAnalysis/index.js";
import { gridColumnsTotalWidthSelector } from "@material-ui/data-grid";

export const HR_OVERVIEW = "HR_OVERVIEW";
export const HR_USERS = "HR_USERS";
export const HR_SESSIONS = "HR_SESSIONS";
export const HR_ASSESSMENT_SCORE = "HR_ASSESSMENT_SCORE";
export const HR_ZEN_ANALYTICS = "HR_ZEN_ANALYTICS";
export const HR_SUPPORT = "HR_SUPPORT";

const HRDashboard = (props) => {
  const { adminCorporateId } = props;
  const [selectedTab, setSelectedTab] = useState(HR_OVERVIEW);

  useEffect(() => {
    //Update the routes only in case of HR not when we are in HR Dashboard via Admin
    if (props.routeProps) {
      const history = props.history;
      if (selectedTab === HR_OVERVIEW) {
        history.push("/appsmith/dashboard/overview");
      } else if (selectedTab === HR_SESSIONS) {
        history.push("/appsmith/dashboard/sessions");
      } else if (selectedTab === HR_USERS) {
        history.push("/appsmith/dashboard/users");
      } else if (selectedTab === HR_SUPPORT) {
        history.push("/appsmith/dashboard/support");
      } else if (selectedTab === HR_ZEN_ANALYTICS) {
        history.push("/appsmith/dashboard/slack");
      } else if (selectedTab === HR_ASSESSMENT_SCORE) {
        history.push("/appsmith/dashboard/assessment-score");
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (props.routeProps) {
      const { activeTab } = props.routeProps;
      setSelectedTab(activeTab);
    }
  }, []);

  return (
    <div>
      <HeaderDashboardB2BHR
        selectedTab={selectedTab}
        categoryId={props.categoryId}
        handleLogout={props.handleLogout}
        showOverView={() => {
          setSelectedTab(HR_OVERVIEW);
        }}
        showUsers={() => {
          setSelectedTab(HR_USERS);
        }}
        showSessions={() => {
          setSelectedTab(HR_SESSIONS);
        }}
        showOrganizational={() => {
          setSelectedTab(HR_ASSESSMENT_SCORE);
        }}
        showSlack={() => {
          setSelectedTab(HR_ZEN_ANALYTICS);
        }}
        showSupport={() => {
          setSelectedTab(HR_SUPPORT);
        }}
      />
      <div className="wholeapp">
        <LeftMenu
          selectedTab={selectedTab}
          showOverView={() => {
            setSelectedTab(HR_OVERVIEW);
          }}
          showUsers={() => {
            setSelectedTab(HR_USERS);
          }}
          showSessions={() => {
            setSelectedTab(HR_SESSIONS);
          }}
          showOrganizational={() => {
            setSelectedTab(HR_ASSESSMENT_SCORE);
          }}
          showSlack={() => {
            setSelectedTab(HR_ZEN_ANALYTICS);
          }}
          showSupport={() => {
            setSelectedTab(HR_SUPPORT);
          }}
        />
        <div className={`right-app-stuff ${selectedTab === HR_SESSIONS && "somepaddingbottomras"} `}>
          {(() => {
            switch (selectedTab) {
              case HR_OVERVIEW:
                return <Overview adminCorporateId={adminCorporateId || "default"} />;
              case HR_SESSIONS:
                return <Sessions adminCorporateId={adminCorporateId || "default"} />;
              case HR_USERS:
                return <Users adminCorporateId={adminCorporateId || "default"} />;
              case HR_ZEN_ANALYTICS:
                return <App adminCorporateId={adminCorporateId || "default"} />;
              case HR_ASSESSMENT_SCORE:
                return <Organizational adminCorporateId={adminCorporateId || "default"} />;
              case HR_SUPPORT:
                return <Support />;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default withRouter(HRDashboard);
