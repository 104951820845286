import axios from "axios";
import analytics from "../assets/JSONs/HRDemoData/analytics.json";
import engagement_analysis from "assets/JSONs/HRDemoData/engagement_analysis.json";
import happiness_score from "assets/JSONs/HRDemoData/happiness_score.json";
import hr_overview from "assets/JSONs/HRDemoData/hr_overview.json";
import assessment_score from "assets/JSONs/HRDemoData/assessment_score.json";
import store from "../store/store";

export const getOverview = async (token, adminCorporateId, year, department, location, gender) =>
{
  if(store.getState().user.isDemoUser){
    // let result=hr_overview.getOverview[year][department].concat(hr_overview.getOverview[year][location],hr_overview.getOverview[year][gender])
    let result=hr_overview.getOverview[year]
    return {
      data:{
        "result":result,
        "yearsArray":[2023,2024],
      }
    }
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          "/api/assessment/overview/" +
          adminCorporateId +
          "/" +
          year,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getAssessment = async (token, adminCorporateId, quizId) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI +
      "/api/assessment/getAssessment/" +
      adminCorporateId +
      "/" +
      quizId,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getMentalHealthData = async (token, adminCorporateId, year, department, location, gender) =>
{
  if (store.getState().user.isDemoUser) {
    const currentMonth = new Date().getMonth();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let mentalHealthData = assessment_score.getMentalHealthData[year].graphData;

    if (year === "All Time") {
        const originalAllTimeData = assessment_score.getMentalHealthData["All Time"].graphData["All Time"];
        let allTimeData = JSON.parse(JSON.stringify(originalAllTimeData)); // Clone the data to avoid repeated subtraction

        let totalSubtractValues = {};
        allTimeData.forEach(item => {
            totalSubtractValues[item.name] = 0;
        });

        for (let i = currentMonth; i < months.length; i++) {
            let monthData = assessment_score.getMentalHealthData["2024"].graphData[months[i]] || [];
            monthData.forEach(item => {
                totalSubtractValues[item.name] += item.value;
            });
        }

        allTimeData.forEach(item => {
            item.value -= totalSubtractValues[item.name];
            if (item.value < 0) item.value = 0; // Ensure no negative values
        });

        mentalHealthData = { "All Time": allTimeData };
    }

    return { 
        data: {
            "graphData": mentalHealthData,
            "yearArray": [2023, 2024],
        }
    };
}

  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          "/api/assessment/mentalHealth/" +
          adminCorporateId +
          "/" +
          year,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getStressChartData = async (token, adminCorporateId) =>
{
  if(store.getState().user.isDemoUser){
    return {data:assessment_score.getStressChartData};
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          "/api/assessment/stress/" +
          adminCorporateId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}
  
export const getMentalScore = async (token, adminCorporateId) =>
{
  if(store.getState().user.isDemoUser){
    return {data:assessment_score.getMentalScore}
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/mentalScore/` +
          adminCorporateId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}
  
export const loadQuizNames = async (token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + `/api/assessment/quizData`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const getHappinessScore = async (token, adminCorporateId) =>
{
  if(store.getState().user.isDemoUser){
    return {data:happiness_score.getHappinessScore}
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/zen/happinessScore/` +
          adminCorporateId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getSentimentScore = async (token, adminCorporateId, year) =>
{
  if(store.getState().user.isDemoUser){
    return {data:happiness_score.getSentimentScore[year]};
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/zen/sentiment/` +
          adminCorporateId +
          "/" +
          year,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const gethappinessScoreSplit = async (token, adminCorporateId, year, user) =>
{
  if(store.getState().user.isDemoUser){
    return {data:happiness_score.gethappinessScoreSplit};
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/zen/happinessScoreSplit/` +
          adminCorporateId +
          "/" +
          year,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getEmployeeMood = async (token, adminCorporateId, year, month) =>
{
  if(store.getState().user.isDemoUser){
    return {data:happiness_score.getEmployeeMood[year][month]}
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/zen/employeeListing/` +
          adminCorporateId +
          "/" +
          year +
          "/" +
          month,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getEngagementByType = async (
  token,
  adminCorporateId,
  year,
  month,
) =>
{
  if(store.getState().user.isDemoUser){
    return {data:engagement_analysis.getEngagementByType[year][month]};
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
        `/api/assessment/zen/engagementByType/` +
        adminCorporateId + "/" + year + "/" + month,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getMonthWiseEngagement = async (token, adminCorporateId, year) =>
{
  if(store.getState().user.isDemoUser){
    return {data:engagement_analysis.getMonthWiseEngagement[year]}
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/zen/engagementSplit/` +
          adminCorporateId +
          "/" +
          year,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

  export const getTriviaLeaderboard = async (
    token,
    adminCorporateId,
    triviaType,
  ) => {
    if (store.getState().user.isDemoUser) {
      return {data:analytics.getTriviaLeaderboard[triviaType.split(':')[0].trim()]}
    }
    else{
      try{
        const response = await axios.get(
          process.env.REACT_APP_SERVER_URI +
            `/api/assessment/zen/triviaLeaderboard/` +
            adminCorporateId +
            "/" +
            triviaType,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response;
      } catch (e){
        console.log(error);
        throw error;
      }
    }
  };  

export const getEngagedDisengagedEmployee = async (
  token,
  adminCorporateId,
  year,
  month
) =>
{
  if (store.getState().user.isDemoUser) {
    return {data:engagement_analysis.getEngagedDisengagedEmployee[year][month]}
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/zen/engagedDisengagedEmployees/` +
          adminCorporateId +
          "/" +
          year +
          "/" +
          month,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
};  

export const getEngagementScore = async (token, adminCorporateId, user) =>
{ 
  if(store.getState().user.isDemoUser){
    return {data:engagement_analysis.getEngagementScore}
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/zen/engagementScore/` +
          adminCorporateId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getSurveys = async (
  token,
  adminCorporateId,
) => {
  if (store.getState().user.isDemoUser) {
    return {data:analytics.getSurveys}
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/surveyGraphFilter/` +
          adminCorporateId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
};

export const getSurveyContent = async (
  token,
  adminCorporateId,
  surveyType,
  date
) => {
  if (store.getState().user.isDemoUser) {
    return {data:analytics.getSurveyContent[surveyType][date]}
  }
  else{
    try{
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URI +
          `/api/assessment/surveyGraphContent/` +
          adminCorporateId +
          "/" +
          surveyType +
          "/" +
          date,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
};
