import React, { useState, useEffect, Component } from "react";
import { useDyteClient } from '@dytesdk/react-web-core';
import { DyteMeeting } from '@dytesdk/react-ui-kit';
import { useParams } from "react-router-dom";

export const Startchallengequestion = (props) => {
    const [meeting, initMeeting] = useDyteClient();
    // const { meetingg } = useDyteClient();
    const [tokendyte, setstateforrtokendyte] = useState('');
    const [formeetid, setstateformeetid] = useState('');

    const searchParams = useParams();

    function addParticipantApi() {

        // setstateformeetid(searchParams.meetid)
        // let url = 'https://api.cluster.dyte.in/v2/meetings/'
        // const data = JSON.stringify({
        //     "name": userName,
        //     "picture": "https://i.imgur.com/test.jpg",
        //     "preset_name": "group_call_host",
        //     "custom_participant_id": "123456789"
        // })
        // fetch(url + meeting_Id + "/" + "participants", {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Authorization: 'Basic ZTAyZGRmY2EtMDdkMS00MzFjLTgyZDItNzkzMTIzMDdmOTQ2OjBiZWNhZjIxOTkzZGE3N2Y3YTcx'
        //     },
        //     body: data
        // })
        //     .then(responseJson => responseJson.json())
        //     .then(responseJson => {
        //         if (responseJson != undefined && responseJson.success == true) {
        //             setstateforrtokendyte(responseJson.data.token)
        //         }
        //         else {
        //             console.log('error')
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     });




    };



    async function meetingIdFunc() {

    }

    useEffect(() => {


    }, []);

    // return <div>hi {formeetid}</div>
    return (<>
        {/* <LeftMenu /> */}
        <div class="questions_sec">

            <h3>Question 1</h3>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>


        </div>

        <div class="questions_sec">

            <h3>Question 2</h3>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>


        </div>

        <div class="questions_sec">

            <h3>Question 3</h3>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>

            <div class="form-check">
                <input type="checkbox" value="" id="flexCheckDefault" />
                <label for="flexCheckDefault">
                    Lorem ipsum dolar sit me
                </label>
            </div>


        </div>


    </>)
}

export default Startchallengequestion;