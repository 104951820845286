import React, { useState, useEffect } from "react";
import OverviewLineChart from "../../components/OverviewLineChart";
import RadarChartOverview from "../../components/RadarChartOverview";
import "./index.css";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import { getB2BMoodData, getMoodScoreData } from "../../actions/dataLoad";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import OverviewChart from "../../components/OverviewChart";
import { getOverview } from "../../actions/assessment";
import Box from "@mui/material/Box";
import { Select, MenuItem } from "@material-ui/core";
import { useLoadingSelector, useUserTokenSelector } from "store/reducers";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import { useLocation } from "react-router-dom";
const obj = {
  totalScore: 0,
  moodScoreChartData: [
    { name: "Jan", uv: 0 },
    { name: "Feb", uv: 0 },
    { name: "Mar", uv: 0 },
    { name: "Apr", uv: 0 },
    { name: "May", uv: 0 },
    { name: "Jun", uv: 0 },
    { name: "Jul", uv: 0 },
    { name: "Aug", uv: 0 },
    { name: "Sep", uv: 0 },
    { name: "Oct", uv: 0 },
    { name: "Nov", uv: 0 },
    { name: "Dec", uv: 0 },
  ],
};

export const Overview = ({ history, adminCorporateId }) => {
  const userToken = useUserTokenSelector();
  const [moodScoreChartData, setMoodScoreChartData] = useState(
    obj.moodScoreChartData
  );
  const [totalMoodScore, setTotalMoodScore] = useState(0);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [moodRadarChartDate, setMoodRadarChartDate] = useState([]);
  const [overviewItems, setOverviewItems] = useState([]);
  const [years, setYears] = useState([]);
  const [painYears, setPainYears] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [mPointYear, setMPointYear] = useState("All Time");
  const [checkData, setCheckData] = useState(0);
  const [moodRes, setMoodRes] = useState({});
  const dispatch = useDispatch();
  const isLoading = useLoadingSelector();
  const [selectedGender, setSelectedGender] = useState("Overall");
  const [teams, setTeams] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Teams");
  const [selectedLocation, setSelectedLocation] = useState("All Locations");
  const location = useLocation();

  const teamArray = ["Sales","Design","Product"]
  const locationArray = ["Pune","Bangalore"]
  function handleYearChange(event) {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);
  }
  function mhandleYearChange(event) {
    const mPointYear = event.target.value;
    setMPointYear(mPointYear);
  }

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    loadAllData();
    loadMoodScoreData();
  }, []);

  useEffect(() => {
    getOverviewData();
  }, [mPointYear, selectedDepartment, selectedLocation, selectedGender]);

  useEffect(() => {
    const res = moodRes;
    // const score = res?.[selectedYear]?.totalScore;
    // setMoodScoreChartData(
    //   res?.[selectedYear]?.moodScoreChartData || obj.moodScoreChartData
    // );
    // setTotalMoodScore(score || obj.totalScore);
    loadMoodScoreData();
  }, [selectedYear,selectedDepartment,selectedLocation,selectedGender]);

  const loadAllData = async () => {
    dispatch(startLoading())
    await getAllB2BMoodData();
    dispatch(stopLoading())
  };
  const getOverviewData = async () => {
    try {
      dispatch(startLoading());
      const res = await getOverview(userToken, adminCorporateId, mPointYear, selectedDepartment, selectedLocation, selectedGender);
      const yearsData = res.data.yearsArray;
      const painYearArray = yearsData.map((year) => year);
      painYearArray.push("All Time");
      setPainYears(painYearArray);
      const teamsData = teamArray;
      const teamsArray = teamsData.map((team) => team);
      teamsArray.push("All Teams");
      setTeams(teamsArray);
      const locationsData = locationArray;
      const locationsArray = locationsData.map((location) => location);
      locationsArray.push("All Locations");
      setLocations(locationsArray);
      setCheckData(res.data.result.length);
      setOverviewItems(res.data.result);
      dispatch(stopLoading());
    } catch (error) {
      dispatch(stopLoading());
      console.log(error);
    }
  };
  const getAllB2BMoodData = async () => {
    try {
      dispatch(startLoading());
      const res = await getB2BMoodData(userToken, adminCorporateId);
      setMoodRadarChartDate(res.data.moodChartData);
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const loadMoodScoreData = async () => {
    try {
      const res = await getMoodScoreData(userToken, adminCorporateId, selectedDepartment, selectedLocation, selectedGender);
      const yearsData = res.data.yearsArray;
      const years = yearsData.map((year) => year);
      years.sort((a, b) => b - a);
      setYears(years.length > 0 ? years : [currentYear]);
      const teamsData = teamArray;
      const teams = teamsData.map((team) => team);
      teams.push("All Teams");
      setTeams(teams);
      const locationsData = locationArray;
      const locations = locationsData.map((location) => location);
      locations.push("All Locations");
      setLocations(locations);
      setMoodRes(res.data.moodData);
      setMoodScoreChartData(res.data?.moodData[selectedYear]?.moodScoreChartData || obj.moodScoreChartData);
      setTotalMoodScore(res.data?.moodData[selectedYear]?.totalScore || obj.totalScore);
    } catch (err) {
      dispatch(stopLoading());
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  return (
    <div className="overview">
      {isLoading ? (
        <></>
      ) : (
        <>
        {/* gender location and team filter */}
          {/* <div className="select-div">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 className="select-gender-heading">
                  Select Filters{" "}
                  <span style={{ fontSize: "0.75rem" }}>
                    (it will work for all graphs){" "}
                  </span>
                </h5>{" "}
              </div>
              <Box className="filters">
                <Select
                  value={selectedGender}
                  onChange={(event) => setSelectedGender(event.target.value)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  <MenuItem value="Overall">Overall</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
                <Select
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                  label="Department"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  {
                    teams.map((team) => (
                      <MenuItem value={team}>{team}</MenuItem>
                    ))
                  }
                </Select>
                <Select
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                  label="Location"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  {
                    locations.map((location) => (
                      <MenuItem value={location}>{location}</MenuItem>
                    ))
                  }
                </Select>
              </Box>
          </div> */}
          <div className="topoverview-header">
            <h3 className="title-topoverview painpoint" style={{fontSize:"1.5em"}}>Major Pain Points </h3>
            <Box>
              <Select
                value={mPointYear}
                onChange={(e) => mhandleYearChange(e)}
                label="Year"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Set your desired max height
                    },
                  },
                }}
              >
                {painYears.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </Box>
          </div>
          <div className="top-overview" style={{ height: "350px", }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {error}
              </Alert>
            </Snackbar>
            {checkData === 0 ? (
              <h3 style={{ margin: "0px auto" }}>No Data Found</h3>
            ) : (
              <OverviewChart items={overviewItems} />
            )}
          </div>
          <div className="bottom-overview">
            <div className="left-bov">
              <h3 className="title-topoverview" style={{fontSize:"1.5em"}}>Overall Mood Dynamics</h3>
              <div className="chart-lbov" style={{ marginBottom: "3%" }}>
                <RadarChartOverview data={moodRadarChartDate} />
              </div>
            </div>
            <div className="right-bov">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 className="title-topoverview" style={{fontSize:"1.5em"}}>Overall Happiness Score</h3>
                <Box >
                  <Select
                    value={selectedYear}
                    onChange={(e) => handleYearChange(e)}
                    label="Year"
                  >
                    {years.map((year) => (
                      <MenuItem value={year}>{year}</MenuItem>
                    ))}
                  </Select>
                </Box>
              </div>

              <div className="status-rbov">
                <h4 className="count-rightbov">
                  {totalMoodScore.toFixed(1)}/5
                </h4>
                <div className="status-emoji">
                  <EmojiEmotionsIcon />
                </div>
              </div>
              <div className="lineargraphstatus">
                <OverviewLineChart
                  data={moodScoreChartData}
                  totalScore={totalMoodScore}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Overview;
