import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
// import pic from "../../assets/images/happymood.gif";
// import tea from "../../assets/images/cuptea.PNG";
import AddIcon from "@material-ui/icons/Add";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import "antd/dist/antd.css";
import { Progress, Steps } from "antd";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Loading from "../../components/Utilities/Loading/Loading.js";
import MoodTracker from "../../components/MoodTracker/MoodTracker.js";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import { loadAllMoods, trackMood, loadProgress } from "../../actions/features.js";
import { getBlogData } from "../../actions/dataLoad.js";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import Slider from "@material-ui/core/Slider";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useLoadingSelector, useUserInfoSelector, useUserTokenSelector } from "store/reducers.js";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions.js";
// const { Step } = Steps
const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#3D41D7",
      },
      track: {
        color: "white",
      },
      rail: {
        color: "black",
      },
      mark: {
        color: "white",
      },
      root: {
        height: "100px",
      },
    },
  },
});

const tea = "https://ucarecdn.com/d0001aa1-888d-48c8-9e7b-aef03de1a389/cuptea.png";
export const UserOverview = (props) => {
  const [selectedMood, setSelectedMood] = useState("Happy");
  const [moods, setMoods] = useState([]);
  const history = useHistory();
  const userToken = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const isLoading = useLoadingSelector();
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([
    {
      img: tea,
      title: "Blog 1",
    },
    {
      img: tea,
      title: "Blog 2",
    },
    {
      img: tea,
      title: "Blog 3",
    },
    {
      img: tea,
      title: "Blog 4",
    },
  ]);
  const [MindfullnessContent, setMindfullnessContent] = useState([]);
  const [AssessmentQuizzes, setAssessmentQuizzes] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(0);
  const [changedSlide, setChangedSlide] = useState(true);
  const [changedSlideProgress, setChangedSlideProgress] = useState(true);
  const [sliderBarStuff, setSliderBarStuff] = useState([
    { title: "Update Mood" },
    { title: "Practice Mindfulness" },
    { title: "Book Therapy" },
    { title: "Take Assessment" },
  ]);
  const [selectedProgress, setSelectedProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [selectedAssessment, setSelectedAssessment] = useState({
    name: "",
    number: "",
  });
  const [progressPercentage, setProgressPercentage] = useState(0);
  const location = useLocation();

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  useLayoutEffect(() => {
    dispatch(startLoading());
  }, []);

  useEffect(() => {
    allMoods();
    getAllBlogData();
    userProgress();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setChangedSlide(false);
      setChangedSlideProgress(false);
      setTimeout(() => {
        if (selectedBlog === blogs.length - 1) {
          setSelectedBlog(0);
          setChangedSlide(true);
        } else {
          setSelectedBlog((prev) => prev + 1);
          setChangedSlide(true);
        }
        if (selectedProgress === sliderBarStuff.length - 1) {
          setSelectedProgress(0);
          setChangedSlideProgress(true);
        } else {
          setSelectedProgress((prev) => prev + 1);
          setChangedSlideProgress(true);
        }
      }, 10);
    }, 4000);
    return () => {
      clearInterval(timer);
    };
  }, [selectedBlog, selectedProgress]);

  const getAllBlogData = async () => {
    try {
      dispatch(startLoading());
      const res = await getBlogData(userToken);
      setBlogs(res.data.blogs);
      setAssessmentQuizzes(res.data.AssessmentQuizzes);
      let randomQuiz = randomIntFromInterval(0, res.data.AssessmentQuizzes.length - 1);
      setSelectedAssessment(res.data.AssessmentQuizzes[randomQuiz]);
      setMindfullnessContent(res.data.MindfullnessContent);
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname } }), 3000);
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const userProgress = async () => {
    try {
      dispatch(startLoading());
      const res = await loadProgress(userToken);
      setProgressPercentage(parseInt(res.data.progress.totalProgress));
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      showError(err.response.data.error);
    }
  };

  const getRandomAssessment = () => {
    let randomQuiz = randomIntFromInterval(0, AssessmentQuizzes.length - 1);
    setSelectedAssessment(AssessmentQuizzes[randomQuiz]);
  };

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const allMoods = async () => {
    try {
      dispatch(startLoading());
      const res = await loadAllMoods(userToken);
      setMoods(res.data.allMoods);
      let latestMood = res.data.latestMood;
      if (latestMood === "M1") {
        setSelectedMood("Angry");
      }
      if (latestMood === "M2") {
        setSelectedMood("Anxious");
      }
      if (latestMood === "M3") {
        setSelectedMood("Sad");
      }
      if (latestMood === "M4") {
        setSelectedMood("Neutral");
      }
      if (latestMood === "M5") {
        setSelectedMood("Happy");
      }
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname } }), 3000);
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const sendSelectedMood = async (currentStep) => {
    try {
      dispatch(startLoading());
      let currentMood = moods[currentStep];
      const res = await trackMood({ moodID: currentMood.moodID }, userToken);
      if (currentStep === 0) setSelectedMood("Angry");
      if (currentStep === 1) setSelectedMood("Anxious");
      if (currentStep === 2) setSelectedMood("Sad");
      if (currentStep === 3) setSelectedMood("Neutral");
      if (currentStep === 4) setSelectedMood("Happy");
      getAllBlogData();
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname } }), 3000);
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="dashboardofboxes">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <p className="dashboard-heading">Hey {userInfo.fullName}, welcome back!</p>
      <div className="containers-dashboardboxed dashboardbooking">
        <div className="top-containerbooking">
          <Link to="/sessionBooking" className="booknow-link">
            <div className="booknow-card">
              <p className="firstheading">1 on 1 counseling sessions</p>
              <p className="bottomheading">Completely Confidential</p>
              <div className="book-btn">
                <p>Book Now!</p>
              </div>
              <div className="book-img">
                <img src="https://ucarecdn.com/615a7cd9-58a4-4fa2-b876-10f63f4544cd/BookImg.png" />
              </div>
            </div>
          </Link>
          <MoodTracker selectedMood={selectedMood} sendSelectedMood={sendSelectedMood} />
        </div>
        <p className="moodcontent-heading">Mood Generated Content</p>
        <div className="bottom-containerbooking">
          <div className="left-cbc">
            {changedSlide && blogs.length !== 0 && (
              <div
                className="container-lcbc"
                onClick={(e) => {
                  openInNewTab(blogs[selectedBlog].post_link);
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="blogimage-cbc">
                  <img src="https://ucarecdn.com/d0001aa1-888d-48c8-9e7b-aef03de1a389/cuptea.png" alt="cuptea" />
                </div>
                <p className="latestupdates-cbc">{blogs[selectedBlog].post_title}</p>
              </div>
            )}
          </div>
          <div className="mid-container">
            <div
              className="top-mcbc"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                let randomContent = randomIntFromInterval(0, MindfullnessContent.length - 1);
                openInNewTab(MindfullnessContent[randomContent]);
              }}
            >
              <PlayCircleFilledIcon className="playicon-mcbc" />
              <p>Practise</p>
              <p>Mindfulness</p>
            </div>
            <div
              className="bottom-mcbc"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                /*let randomQuiz = randomIntFromInterval(
                  0,
                  AssessmentQuizzes.length - 1
                )*/
                if (selectedAssessment.number !== "") {
                  history.push(`/quiz/${selectedAssessment.number}`);
                }
                getRandomAssessment();
              }}
            >
              <EqualizerIcon />
              <p>Take {selectedAssessment.name} Assessment</p>
            </div>
          </div>
          <div className="progress-container">
            <div className="circle-rcbc">
              <Progress
                type="circle"
                percent={progressPercentage}
                strokeWidth={10}
                strokeColor="rgba(249, 206, 85, 1)"
              />
              {changedSlideProgress && sliderBarStuff.length !== 0 && <p>{sliderBarStuff[selectedProgress].title}</p>}
            </div>
          </div>
        </div>
      </div>
      {/* <Steps
              current={currentStep}
              onChange={(e) => {
                setCurrentStep(e)
              }}
            >
              <Step />
              <Step />
              <Step />
              <Step />
              <Step />
            </Steps> */}
    </div>
  );
};

export default UserOverview;
