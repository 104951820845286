import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { Modal, Box, Typography, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { addGender, checkGender } from "../actions/auth";
import { checkTeamLocation, logout, updateTeamLocation } from "../actions/auth";
import { logoutUser } from "store/actions";
import { useLoadingSelector, useUserTokenSelector } from "store/reducers";
import { checkLastSessionFeedback, postFeedback } from "actions/bookSlot";

import LeftMenuB2B from "./LeftMenuB2B";
import UserOverview from "../Containers/UserOverview/index";
import HeaderDashboard from "./HeaderDashboard";
import Support from "../Containers/Support/index.js";
import Workshops from "../Containers/Workshops/Workshops";
import SessionFeedbackPopup from "./SessionFeedbackPopup/SessionFeedbackPopup";
import TeamLocationPopup from "./TeamLocationPopup/TeamLocationPopup";
import MoodTrackerScreen from "Containers/MoodTrackerScreen";
import Assessments from "Containers/Assessments";
import UserWallet from "Containers/UserWallet";
import Mindfulness from "Containers/Mindfulness";
import UserBookings from "Containers/UserBookings";
import "./index.css";
import MyProfilePopup from "Containers/MyProfile/MyProfile";

export const OVERVIEW = "OVERVIEW";
export const MOOD_TRACKER = "MOOD_TRACKER";
export const MY_BOOKINGS = "MY_BOOKINGS";
export const MINDFULNESS = "MINDFULNESS";
export const ASSESSMENTS = "ASSESSMENTS";
export const WORKSHOPS = "WORKSHOPS";
export const MY_PROFILE = "MY_PROFILE";
export const SUPPORT = "SUPPORT";
export const WALLET = "WALLET";

export const UserDashboard = (props) => {
  const [selectedTab, setSelectedTab] = useState(OVERVIEW);
  const [modalOpen, setModalOpen] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [imagePopup, setImagePopup] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [referralPopup, setReferralPopup] = useState("");
  const [userId, setSignedupId] = useState("");
  const [sessionFeedbackPopupState, setSessionFeedbackPopupState] = useState({ show: false, data: {} });
  const [teamLocationPopupState, setTeamLocationPopupState] = useState({ show: false, teamLocations: [] });
  const [successMessage, setSuccessMessage] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userToken = useUserTokenSelector();
  const isLoading = useLoadingSelector();

  useEffect(() => {
    if (props.workshopId) {
      setSelectedTab(WORKSHOPS);
    }
    const signup = new URLSearchParams(location.search).get("googleAuth");
    const userid = new URLSearchParams(location.search).get("userId");
    if (signup == null) {
      setGoogleAuth(false);
    } else {
      showModal(userid);
    }
    checkTeamLocation(userToken)
      .then((res) => {
        // show team location popup to user if user hasn't submitted data yet
        if (!res.data.isSubmitted) {
          const { teamLocations } = res.data;
          setTeamLocationPopupState({ show: true, teamLocations: teamLocations });
        }
      })
      .catch((e) => {});
    checkLastSessionFeedback(userToken)
      .then((res) => {
        // show feedback popup to user if user hasn't rated last completed session already
        if (!res.data.isFeedbackGiven) {
          const { therapistName, therapistId, sessionId } = res.data;
          setSessionFeedbackPopupState({ show: true, data: { therapistName, therapistId, sessionId } });
        }
      })
      .catch((e) => {});
  }, []);

  const showModal = async (userid) => {
    try {
      const res = await checkGender(userid);
      if (res.data.AvatarNotSelected == true) {
        setModalOpen(true);
        setSignedupId(userid);
        setGoogleAuth(true);
      } else {
        setModalOpen(false);
        setSignedupId("");
        setGoogleAuth(false);
      }
    } catch (error) {
      setModalOpen(false);
      setSignedupId("");
      setGoogleAuth(false);
      showError(error.response.data.error);
    }
  };

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (selectedTab === OVERVIEW) {
      history.push("/dashboard/overview");
    } else if (selectedTab === MINDFULNESS) {
      history.push("/dashboard/mindfulness");
    } else if (selectedTab === WORKSHOPS) {
      history.push("/dashboard/workshops");
    } else if (selectedTab === MOOD_TRACKER) {
      history.push("/dashboard/mood-tracker");
    } else if (selectedTab === WALLET) {
      history.push("/dashboard/wallet");
    } else if (selectedTab === SUPPORT) {
      history.push("/dashboard/support");
    } else if (selectedTab === MY_BOOKINGS) {
      history.push("/dashboard/my-bookings");
    } else if (selectedTab === ASSESSMENTS) {
      history.push("/dashboard/assessments");
    }
  }, [selectedTab]);

  useEffect(() => {
    if (props.routeProps) {
      const { activeTab } = props.routeProps;
      setSelectedTab(activeTab);
    }
    if (props.showBookings) {
      setSelectedTab(MY_BOOKINGS);
    }
  }, []);

  const handleLogout = () => {
    logout(userToken);

    logoutUser(dispatch);

    window.localStorage.removeItem("auth");

    history.push({
      pathname: "/signin",
      state: {
        loggedOutMessage: "You have been logged out. Please login again.",
      },
    });
  };
  const handleModalSubmit = async () => {
    if (selectedImage.length === 0) {
      setImagePopup("Please choose an Avatar");
    } else {
      setImagePopup("");
    }
    if (referralCode.length > 0) {
      if (referralCode.length != 5) {
        setReferralPopup("Enter a Valid Code");
      }
    }
    if (selectedImage !== "") {
      if (referralCode.length > 0) {
        if (referralCode.length != 5) {
          return;
        }
      }
      /*if (referralCode.length === 5) {
        try {
          const res = await checkReferral({ referralCode });
          const found = res.data.found;
          if (!found) {
            setReferralPopup("Referral Code Not Found");
            return;
          }
        } catch (e) {
          setReferralPopup(e.response.data.error);
        }
      }*/
      //Update the Gender and referral Code
      let avatar = "man";
      if (selectedImage === 1) avatar = "woman";
      if (selectedImage === 2) avatar = "man";
      if (selectedImage === 3) avatar = "other";

      try {
        const res1 = await addGender(
          {
            avatar: avatar,
            referralCode: referralCode,
          },
          userToken
        );

        // if (referralCode.length === 5) {
        //   const res2 = await addUserId({ referralCode, userId: userId, email: a  });
        // }

        setModalOpen(false);
      } catch (error) {
        setReferralPopup(error.response.data.error);
      }
    }
  };

  const handleSessionFeedbackSubmit = async ({ rating, sessionId, therapistId, message, comments }) => {
    try {
      await postFeedback({ rating, bookingID: sessionId, therapistID: therapistId, message, comments }, userToken);
      setSessionFeedbackPopupState({ show: false, data: {} });
    } catch (err) {
      showError("An error occurred!");
    }
  };

  const onTeamLocationSubmit = async (teamName, teamLocation) => {
    try {
      await updateTeamLocation({ teamName, teamLocation }, userToken);
      setTeamLocationPopupState({ show: false, data: [] });
    } catch (err) {
      showError("An error occurred!");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxWidth: "90%",
  };
  return (
    <div>
      {googleAuth && (
        // popup to ask user's gender after signing up with Google
        <Modal
          onBackdropClick={() => setModalOpen(true)}
          open={modalOpen}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleModalClose();
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ textAlign: "center" }}>
              <Typography variant={"h5"}>Almost There...</Typography>
            </div>
            <div className="chooseavataricon">
              <p>Select Your Gender*</p>
              <div className="inner-cai">
                <img
                  src="https://ucarecdn.com/5c10944e-91bc-4ee6-a259-23565aac6100/avatar3.png"
                  className={`${selectedImage === 1 && "active"}`}
                  onClick={() => setSelectedImage(1)}
                />
                <img
                  src="https://ucarecdn.com/e6d48801-632e-4d97-b297-bd15292f07ed/avatar2.png"
                  className={`${selectedImage === 2 && "active"}`}
                  onClick={() => setSelectedImage(2)}
                />
                <img
                  src="https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png"
                  className={`${selectedImage === 3 && "active"}`}
                  onClick={() => setSelectedImage(3)}
                />
              </div>
              {imagePopup.length > 0 && (
                <p className="alert-field-fisl" style={{ color: "red" }}>
                  {imagePopup}
                </p>
              )}
            </div>
            <div className="formsinnerfmc">
              <input
                type="text"
                placeholder="Referral Code(Optional)"
                maxLength={5}
                value={referralCode.toUpperCase()}
                onChange={(e) => setReferralCode(e.target.value)}
              />
            </div>
            {referralPopup.length > 0 && <p className="alert-field-fisl">{referralPopup}</p>}
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => handleModalSubmit()}
                style={{ marginTop: "10px", textAlign: "center" }}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </Box>
        </Modal>
      )}

      {/* Dashboard navbar & tab navigation for mobile   */}
      <HeaderDashboard
        selectedTab={selectedTab}
        categoryId={props.categoryId}
        shouldWalletAppear={props.showWallet}
        workshopId={props.workshopId}
        handleLogout={handleLogout}
        toggleProfilePopup={() => setShowProfilePopup((val) => !val)}
        showOverView={() => {
          setSelectedTab(OVERVIEW);
        }}
        showMoodTracker={() => {
          setSelectedTab(MOOD_TRACKER);
        }}
        showMyBookings={() => {
          setSelectedTab(MY_BOOKINGS);
        }}
        showAssessments={() => {
          setSelectedTab(ASSESSMENTS);
        }}
        showWallet={() => {
          setSelectedTab(WALLET);
        }}
        showMindfulness={() => {
          setSelectedTab(MINDFULNESS);
        }}
        showWorkshops={() => {
          setSelectedTab(WORKSHOPS);
        }}
        showSupport={() => {
          setSelectedTab(SUPPORT);
        }}
      />

      <div className="wholeapp c2capp b2bapp">
        <LeftMenuB2B
          workshopId={props.workshopId}
          shouldWalletAppear={props.showWallet}
          selectedTab={selectedTab}
          showOverView={() => {
            setSelectedTab(OVERVIEW);
          }}
          showMoodTracker={() => {
            setSelectedTab(MOOD_TRACKER);
          }}
          showMyBookings={() => {
            setSelectedTab(MY_BOOKINGS);
          }}
          showAssessments={() => {
            setSelectedTab(ASSESSMENTS);
          }}
          showWallet={() => {
            setSelectedTab(WALLET);
          }}
          showMindfulness={() => {
            setSelectedTab(MINDFULNESS);
          }}
          showSupport={() => {
            setSelectedTab(SUPPORT);
          }}
          showWorkshops={() => {
            setSelectedTab(WORKSHOPS);
          }}
        />
        <div className={`right-app-stuff`}>
          {(() => {
            switch (selectedTab) {
              case OVERVIEW:
                return <UserOverview handleLogout={handleLogout} />;
              case MOOD_TRACKER:
                return <MoodTrackerScreen handleLogout={handleLogout} />;
              case MY_BOOKINGS:
                return <UserBookings handleLogout={handleLogout} />;
              case ASSESSMENTS:
                return <Assessments showError={showError} />;
              case MOOD_TRACKER:
                return <MoodTrackerScreen handleLogout={handleLogout} />;
              case WALLET:
                return props.showWallet ? <UserWallet handleLogout={handleLogout} /> : <></>;
              case MINDFULNESS:
                return <Mindfulness />;
              case WORKSHOPS:
                return <Workshops workshopId={props.workshopId} />;
              case SUPPORT:
                return <Support />;
            }
          })()}
        </div>
      </div>
      <SessionFeedbackPopup
        isOpen={!teamLocationPopupState.show && sessionFeedbackPopupState.show}
        onSessionRatingSubmit={handleSessionFeedbackSubmit}
        sessionData={sessionFeedbackPopupState.data}
      />
      <TeamLocationPopup
        isOpen={teamLocationPopupState.show}
        onSubmit={onTeamLocationSubmit}
        teamLocations={teamLocationPopupState.teamLocations}
      />
      <MyProfilePopup
        isOpen={showProfilePopup}
        toggleProfilePopup={() => setShowProfilePopup((val) => !val)}
        showError={showError}
        showMessage={(msg) => setSuccessMessage(msg)}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage("")}>
        <Alert onClose={() => setSuccessMessage("")} severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserDashboard;
