import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default class SectionBarChart extends PureComponent {
  state = {
    activeIndex: new Date().getMonth(),
  };

  handleClick = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { activeIndex } = this.state;
    const { data } = this.props;
    // const activeItem = data[activeIndex];
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={500} height={300} data={data}>
          <XAxis dataKey="name" />
          <Tooltip />
          <Bar name="sessions" dataKey="uv" fill="#379E90" />
          {/* {data.map((entry, index) => (
              <Cell
                cursor='pointer'
                fill={index === activeIndex ? "#7947F7" : "#F5F0FF"}
                key={`cell-${index}`}
              />
            ))}
          </Bar> */}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
