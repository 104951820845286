import React, { useState, useEffect } from "react";
// import { getMentalHealthData } from "../../actions/assessment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import { useUserTokenSelector } from "store/reducers";

const renderCustomizedLabel = (props) => {
  const { x, y, width, value } = props;
  const radius = 10;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#fff"
        textAnchor="top"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};

export default function MentalHealthGraph({ mentalHealthData }) {
  const [open, setOpen] = useState(false);
  const userToken = useUserTokenSelector();

  return (
    <BarChart
      width={600}
      height={300}
      data={mentalHealthData}
      barCategoryGap={50}
      margin={{
        top: 10,
        right: 0,
        left: 0,
        bottom: 30,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        label={{
          position: "insideBottom",
          value: "Assessment Name",
          offset: -20,
          fill: "8884d8",
        }}
      />
      <YAxis
        label={{
          position: "insideBottomLeft",
          value: "Submission Count",
          angle: -90,
          offset: 20,
          fill: "8884d8",
        }}
      />
      <Tooltip />
      <Bar name="Submissions" dataKey="value" fill="rgb(55, 158, 144)" minPointSize={5}>
        <LabelList dataKey="name" fontSize={10} position={"top"} />
      </Bar>
    </BarChart>
  );
}
