import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import {
  getHappinessScore,
  getSentimentScore,
  gethappinessScoreSplit,
  getEmployeeMood,
} from "../../actions/assessment";
import { ResponsiveContainer, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area } from "recharts";
import Box from "@mui/material/Box";
import { Select, MenuItem, Typography } from "@material-ui/core";
import "./index.css";
import "../UserOverview/index.css";
import "antd/dist/antd.css";
import UpArrow from "../../assets/svg_icons/up_arrow.svg";
import DownArrow from "../../assets/svg_icons/down_arrow.svg";
import BurnedOutEmployees from "./graphs/BurnedoutEmployee";
import HappyEmployees from "./graphs/HappyEmployee";

const HappinessScore = ({ token, adminCorporateId }) => {
  const [mentalScore, setMentalScore] = useState(0);
  const [mentalInt, setMentalInt] = useState(0);
  const [averageBenchmark, setAverageBenchmark] = useState(0);
  const [lastMonthScore, setLastMonthScore] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [happinessScoreData, setHappinessScoreData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [sentimentData, setSentimentData] = useState({});
  const monthArray = [
    { Jan: "January" },
    { Feb: "February" },
    { Mar: "March" },
    { Apr: "April" },
    { May: "May" },
    { Jun: "June" },
    { Jul: "July" },
    { Aug: "August" },
    { Sep: "September" },
    { Oct: "October" },
    { Nov: "November" },
    { Dec: "December" },
  ];
  let monthNo = new Date().getMonth();
  const [selectedMonth, setSelectedMonth] = useState(Object.keys(monthArray[monthNo])[0]);
  const [benchmarkDifference, setBenchmarkDifference] = useState(0);
  const [lastMonthDifference, setLastMonthDifference] = useState(0);
  const [selectedHappinessYear, setSelectedHappinessYear] = useState(new Date().getFullYear());

  const calcMentalScore = async () => {
    try {
      const res = await getHappinessScore(token, adminCorporateId);
      setMentalScore(res.data.score);
      setMentalInt(res.data.interpretation);
      setAverageBenchmark(res.data.average_benchmark);
      setLastMonthScore(res.data.previousMonthHappinessScore);
      setBenchmarkDifference(res.data.benchmark);
      setLastMonthDifference(res.data.score - res.data.previousMonthHappinessScore);
    } catch (error) {
      console.log(error);
    }
  };

  const calcSentimentScore = async () => {
    try {
      const res = await getSentimentScore(token, adminCorporateId, selectedYear);
      const selectedYearData = res.data;
      const sentiData = {};
      // Loop through each month object in responseData
      selectedYearData.forEach((monthObj) => {
        const [month, data] = Object.entries(monthObj)[0];
        const positive = data[0]?.value;
        const negative = data[1]?.value;
        sentiData[month] = { positive, negative };
      });
      setSentimentData(sentiData);
    } catch (error) {
      console.log(error);
    }
  };

  const calcHappinessScoreSplit = async () => {
    try {
      const res = await gethappinessScoreSplit(token, adminCorporateId, selectedHappinessYear);
      const data = Object.entries(res.data[selectedHappinessYear]).map(([month, score]) => ({
        month,
        score: Number(score),
      }));

      setHappinessScoreData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    calcMentalScore();
  }, []);
  useEffect(() => {
    calcSentimentScore();
  }, [selectedYear]);
  useEffect(() => {
    calcHappinessScoreSplit();
  }, [selectedHappinessYear]);

  useEffect(() => {}, [selectedMonth, selectedYear]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleHappinessYearChange = (e) => {
    setSelectedHappinessYear(e.target.value);
  };

  const CustomizedXAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <>
      <div className="top-overview" style={{ display: "flex", marginLeft: "5%" }}>
        <div
          className="left-sectionstufftop"
          style={{
            marginTop: "3rem",
            height: "auto",
            width: "35%",
            padding: "20px 10px",
            borderRadius: "1.875rem",
            background: "#F6F8FF",
            boxShadow: "5px 0px 4px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div className="top-lsst">
            <h3 className="title-topoverview" style={{ textAlign: "center", fontSize: "1.5em" }}>
              Happiness Score
            </h3>
            <div className="right-cbc">
              <div className="circle-rcbc">
                <Progress
                  type="circle"
                  percent={mentalScore * 20}
                  format={(percent) => `${mentalScore}/5`}
                  strokeWidth={10}
                  strokeColor="#F9CE55"
                />
                <p>{mentalInt}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-details-div">
          <div className="avg-benchmark-div">
            <h6 className="title-topoverview" style={{ fontWeight: 500, fontSize: "1.5em" }}>
              Average benchmark: {averageBenchmark}
            </h6>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <div className="up-down-div">
                {benchmarkDifference > 0 ? (
                  <img src={UpArrow} alt="up_arrow" />
                ) : (
                  <img src={DownArrow} alt="up_arrow" />
                )}
                <Typography>{Math.abs(benchmarkDifference)}</Typography>
              </div>
              <h6
                className="title-topoverview"
                style={{
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  marginLeft: "0.75rem",
                }}
              >
                Average benchmark
              </h6>
            </div>
          </div>
          <div className="divider"></div>
          <div className="last-month-score-div">
            {/* <MonthSVG /> */}
            <h6 className="title-topoverview" style={{ fontWeight: 500, fontSize: "1.5em" }}>
              Last month's Score: {lastMonthScore}
            </h6>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <div className="up-down-div">
                {lastMonthDifference > 0 ? (
                  <img src={UpArrow} alt="up_arrow" />
                ) : (
                  <img src={DownArrow} alt="up_arrow" />
                )}
                <Typography>{Math.abs(lastMonthDifference.toFixed(1))}</Typography>
              </div>
              <h6
                className="title-topoverview"
                style={{
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  marginLeft: "0.75rem",
                }}
              >
                Since Last Month
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="top-overview" style={{ marginTop: "2rem", width: "100%", marginLeft: "5%" }}>
        <div
          className="left-sectionstufftop"
          style={{
            height: "auto",
            width: "35%",
            borderRadius: "1.875rem",
            background: "#FFF",
            boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.25)",
            padding: "20px 10px",
          }}
        >
          <div
            className="top-lsst"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="senti-dis-div">
              <Box display="flex" justifyContent="flex-end">
                <div>
                  <Select value={selectedYear} onChange={handleYearChange}>
                    <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                    <MenuItem value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</MenuItem>
                    <MenuItem value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</MenuItem>
                  </Select>
                  <Select value={selectedMonth} onChange={handleMonthChange}>
                    {monthArray.map((month, index) => {
                      if (index > monthNo && selectedYear === new Date().getFullYear()) {
                        return null;
                      }
                      return (
                        <MenuItem key={index} value={Object.keys(month)[0]}>
                          {Object.values(month)[0]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </Box>
              {(() => {
                if (!(sentimentData[selectedMonth]?.positive && sentimentData[selectedMonth]?.negative)) {
                  return <Typography style={{ marginTop: "1rem", textAlign: "center" }}>No data found</Typography>;
                }
                const positivePercent =
                  (sentimentData[selectedMonth]?.positive * 100) /
                  (sentimentData[selectedMonth]?.positive + sentimentData[selectedMonth]?.negative);
                const negativePercent = 100 - positivePercent;

                return (
                  <>
                    <div
                      className="right-cbc"
                      style={{
                        marginTop: "1rem",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div className="circle-rcbc">
                        <Progress
                          type="circle"
                          percent={positivePercent}
                          format={(string) => ``}
                          strokeWidth={10}
                          strokeColor="#F9CE55"
                          trailColor="#379E90"
                          strokeLinecap="square"
                        />
                      </div>
                      <div style={{ alignSelf: "flex-start" }}>
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "1rem",
                            marginTop: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              backgroundColor: "#F9CE55",
                              marginRight: 5,
                              borderRadius: "50%",
                            }}
                          ></div>
                          <Typography
                            style={{
                              fontSize: "0.75rem",
                            }}
                          >
                            Positive {" " + positivePercent.toFixed(1)}%
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "1rem",
                            marginTop: "0.25rem",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              backgroundColor: "#379E90",
                              marginRight: 5,
                              borderRadius: "50%",
                            }}
                          ></div>
                          <Typography
                            style={{
                              fontSize: "0.75rem",
                            }}
                          >
                            Negative {" " + negativePercent.toFixed(1)}%
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          marginBottom: "0.3rem",
                          fontWeight: "500",
                          fontSize: "1.25rem",
                          color: "#0D0D0D",
                          marginTop: "1rem",
                        }}
                      >
                        Sentiment Distribution
                      </Typography>
                      <Typography style={{ fontSize: "0.8rem", color: "#AFACAC" }}>
                        Find out the sentiment distribution of the employees{" "}
                      </Typography>
                    </div>
                  </>
                );
              })()}
            </div>
          </div>
        </div>

        <div className="top-overview">
          <div
            className="left-sectionstufftop"
            style={{
              height: "auto",
              width: "65%",
              marginLeft: "2rem",
              borderRadius: "1.875rem",
              background: "#FFF",
              boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.25)",
              padding: "20px 10px",
            }}
          >
            <div className="top-lsst">
              <div className="happi-score-div">
                <Box display="flex" justifyContent="flex-end" marginBottom="20px">
                  <div>
                    <Select value={selectedHappinessYear} onChange={handleHappinessYearChange}>
                      <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</MenuItem>
                    </Select>
                  </div>
                </Box>
                <ResponsiveContainer width="100%" height={390}>
                  <AreaChart data={happinessScoreData}>
                    <CartesianGrid strokeDasharray="3 3" />

                    <XAxis
                      dataKey="month"
                      tick={<CustomizedXAxisTick />}
                      interval={0}
                      tickLine={false}
                      height={60}
                      padding={{ left: 20, right: 20 }}
                    />
                    <YAxis ticks={[0, 1, 2, 3, 4, 5]} domain={[0, 5]} />
                    <Tooltip />
                    <Area type="monotone" dataKey="score" stroke="#96A8FF" fill="#96A8FF" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <Typography
                style={{
                  marginBottom: "0.3rem",
                  fontWeight: "500",
                  fontSize: "1.25rem",
                  color: "#0D0D0D",
                  marginTop: "1rem",
                }}
              >
                Happiness Score Monthly Split
              </Typography>
              <Typography style={{ fontSize: "0.8rem", color: "#AFACAC" }}>
                Find out in which months employees were most engaged{" "}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div
        className="top-overview"
        style={{
          marginTop: "2rem",
          width: "100%",
          marginLeft: "5%",
        }}
      >
        <div
          className="left-sectionstufftop"
          style={{
            borderRadius: "2.29444rem",
            height: "550px",
            width: "37.5%",
            padding: "2rem",
            border: "0.765px solid var(--violet2, #C894C4)",
            background: "#F6F8FF",
          }}
        >
          <BurnedOutEmployees token={token} adminCorporateId={adminCorporateId} />
        </div>

        <div
          className="left-sectionstufftop"
          style={{
            borderRadius: "2.29444rem",
            height: "550px",
            width: "37.5%",
            padding: "2rem",
            border: "0.765px solid var(--violet2, #C894C4)",
            background: "#F6F8FF",
            marginLeft: "2rem",
          }}
        >
          <HappyEmployees token={token} adminCorporateId={adminCorporateId} />
        </div>
      </div>
    </>
  );
};

export default HappinessScore;
