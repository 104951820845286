import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import { SlArrowDown } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";

import Chart from "./graphs/Chart";
import SurveyChart from "./graphs/SurveyChart";
import { getSurveyContent, getSurveys, getTriviaLeaderboard } from "../../actions/assessment";
import Loading from "../../components/Utilities/Loading/Loading.js";
import { startLoading, stopLoading } from "store/actions";

const Analytics = ({ token, adminCorporateId }) => {

  const trivia = [
    {
      "name":"Pop Music",
      "value":"Pop Music :musical_note::dancer:",
    },
    {
      "name":"General Knowledge",
      "value":"General Knowledge :thought_balloon::question::exclamation:",
    },
    {
      "name":"Nature and Animals",
      "value":"Nature and Animals :elephant::dog2::leaves::herb:",
    },
    {
      "name":"Sports",
      "value":"Sports :soccer::runner::swimmer:",
    },
    {
      "name":"Art and Culture",
      "value":"Art and Culture :art::confetti_ball:",
    },
    {
      "name":"Geography",
      "value":"Geography :globe_with_meridians::earth_africa:",
    },
    {
      "name":"Cars and Bikes",
      "value":"Cars and Bikes :car::bike:",
    },
    {
      "name":"Food Trivia",
      "value":"Food Trivia",
    },
    {
      "name":"World History",
      "value":"World History :globe_with_meridians::crown:",
    }, 
    {
      "name":"Fact or Crap",
      "value":"Fact or Crap :poop: :speak_no_evil:"
    },
    {
      "name":"Cricket",
      "value":"Cricket :cricket_bat_and_ball:",
    },
    {
      "name":"Football",
      "value":"Football :soccer:",
    }
  ]
  const [triviaType, setTriviaType] = useState(
    "Pop Music :musical_note::dancer:"
  );
  const [leaderboardData, setLeaderboardData] = useState({});
  const [leaderboardRankData, setLeaderboardRankData] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState("");
  const [selectedSurveyDate, setSelectedSurveyDate] = useState(0);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [surveys, setSurveys] = useState(false);
  const [showDates, setShowDates] = useState(false);
  const [surveyData, setSurveyData] = useState();
  const { user } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  const handleTriviaTypeChange = (e) => {
    setTriviaType(e.target.value);
  };

  const getTriviaLeaderboardData = async () => {
    try {
      const res = await getTriviaLeaderboard(
        token,
        adminCorporateId,
        triviaType
      );
      setLeaderboardData(res.data);
      setLeaderboardRankData(res.data.userCorrectCounts);
    } catch (error) {
      console.log(error);
    }
  };

  const getSurveysData = async () => {
    try {
      dispatch(startLoading());
      const res = await getSurveys(
        token,
        adminCorporateId
      );
      const surveys = Object.keys(res.data);
      setSurveys(res.data);
      setSelectedSurvey(surveys[0]);
      setSelectedSurveyDate(surveys[0].length()-1);
      getSurveyGraphData();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(stopLoading());
    }
  }
  const getSurveyGraphData = async () => {
    try {
      dispatch(startLoading());
      const date = surveys[selectedSurvey][selectedSurveyDate]
      const res = await getSurveyContent(
        token,
        adminCorporateId,
        selectedSurvey,
        date
      )
      setSurveyData(res.data);
    } catch (error) {
      console.log(error);
    } finally{
      dispatch(stopLoading());
    }
  }

  useEffect(() => {
    getTriviaLeaderboardData();
  }, [triviaType]);

  useEffect(() => {
    getSurveysData();
  }, []);

  useEffect(() => {
    getSurveyGraphData();
  }, [selectedSurvey, selectedSurveyDate]);
  return (
    <>
        <div
          className="left-sectionstufftop"
          style={{
            borderRadius: "12px",
            height: "auto",
            padding: "20px 30px",
            alignSelf:"center",
            margin:"30px auto 0px auto",
            width:"fit-content"
          }}
        >
            <div style={{ display: "flex", flexDirection: "row",justifyContent:"space-between",alignItems:"center",width:"100%" }}>
              <h3 style={{ fontSize: "1.5em", fontWeight:"600" }}>
                Trivia Leaderboard
              </h3>
              <Select
                value={triviaType}
                onChange={handleTriviaTypeChange}
                style={{ marginLeft: "25vw" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Set your desired max height
                    },
                  },
                }}
              >
                {
                  trivia.map((e, index) => (
                    user.isDemoUser && index > 3 ? null : <MenuItem key={index} value={e.value}>{e.name}</MenuItem>
                  ))
                }
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "20px 0px",
              }}
            >
              <div style={{ width: "50%" }}>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "2rem",
                  }}
                >
                  Date of Trivia: {leaderboardData.date}
                </Typography>
                {leaderboardRankData.map((user, index) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "12px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "5rem",
                      }}
                    >
                      {index + 1 <= 3 ? (
                        <div
                          style={{
                            fontSize: "2rem",
                          }}
                        >
                          {index + 1 === 1 && "🥇"}
                          {index + 1 === 2 && "🥈"}
                          {index + 1 === 3 && "🥉"}
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "1rem",
                            marginLeft: "12px",
                            fontWeight: "500",
                          }}
                        >
                          {index + 1}
                        </div>
                      )}
                    </div>
                    <div style={{ fontSize: "1rem", fontWeight: "500" }}>
                      {index + 1 <= 3 ? (
                        <p>
                          {user.userName.charAt(0).toUpperCase() +
                            user.userName.slice(1)}
                        </p>
                      ) : (
                        <p
                          style={{
                            marginLeft: "11px",
                          }}
                        >
                          {user.userName.charAt(0).toUpperCase() + user.userName.slice(1)}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div style={{ width: "55%" }}>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "2rem",
                    textAlign:"right"
                  }}
                >
                  Total Employees in Channel: {leaderboardData.totalUser}
                </Typography>
                <div>
                <Chart
                  notEngagedUser={leaderboardData.notParticipatedUser}
                  engagedUsers={leaderboardData.engagedUsers}
                />
                </div>
              </div>
            </div>
        </div>
      <div className="analytics-bottom">
        <div className="survey-div">
          <div className="survey-top">
            <h3 className="survey-title">Survey Results</h3>
            <div className="survey-topright">
              <Select
                value={selectedSurvey}
                onChange={(e) => {
                  setSelectedSurvey(e.target.value);
                  setShowDates(true);
                }}
                onOpen={() => setIsSelectOpen(true)}
                onClose={() => setIsSelectOpen(false)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 300, 
                    },
                  },
                }}
                inputProps={{ IconComponent: () => null, IndicatorSeparator: () => null, sx: {backgroundColor: 'white'} }}
                disableUnderline={true}
                className="survey-select"
              >
                {Object.keys(surveys).map((survey) => (
                  <MenuItem value={survey} key={survey} onClick={() => setShowDates(!showDates)}>
                    <div className="survey-select-item">
                      <p>{survey}</p>
                      <SlArrowDown />
                    </div>
                  </MenuItem>
                ))}
              </Select>
              {
                showDates && (
                  <div>
                    <Select
                      value={selectedSurveyDate}
                      onChange={(e) => setSelectedSurveyDate(e.target.value)}
                      className="survey-select-date"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 300, // Set your desired max height
                          },
                        },
                      }}
                      disableUnderline={true}
                    >
                      {surveys[selectedSurvey].map((date, index) => (
                        <MenuItem value={index} key={index}>
                          {date}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )
              }
              {surveyData && (
                <div className="responses-div">
                  <p>Responses: {surveyData.responseCount}</p>
                </div>
              )}
            </div>
          </div>
          {surveyData && surveyData.finalArray && (
            <SurveyChart data={surveyData.finalArray} />
          )}
        </div>
      </div>
    </>
  );
};

export default Analytics;