import React, { useEffect, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import { loadAllQuiz } from "actions/quiz";

export const Assessments = ({ showError }) => {
  const [assessments, setAssessments] = useState([]);
  const [questions, setQuestions] = useState([]);
  //const [allAnswers, setAllAnswers] = useState([])
  const [answers, setAnswers] = useState([]);
  const userInfo = useUserInfoSelector();
  const userToken = useUserTokenSelector();
  const history = useHistory();
  const dispatch = useDispatch();

  const loadAssessments = async () => {
    try {
      dispatch(startLoading());
      const res = await loadAllQuiz(userToken);
      setAssessments(res.data);
      dispatch(stopLoading());
    } catch (err) {
      console.warn(err);
      dispatch(stopLoading());
      if (err.response?.data?.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname } }), 3000);
      } else {
        showError(err.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    loadAssessments();
  }, []);

  return (
    <div className="dashboardofboxes">
      <p className="dashboard-heading">Hey {userInfo.fullName}, welcome back!</p>
      {assessments && (
        <div className="container-dpu">
          {assessments.map((a) => {
            return (
              <Link to={`/dashboard/assessments/${a.urlSlug}`} style={{ textDecoration: "none", color: "inherit" }}>
                <div className="inner-container-dbu">
                  <p>{a.number}</p>
                  <p>{a.name}</p>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Assessments;
