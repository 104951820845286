import React, { PureComponent } from "react"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts"

export const DashboardListAreaChart = (props) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <AreaChart
        width='100%'
        height='100%'
        data={props.weeklySavings}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <YAxis />
        <XAxis dataKey='name' />
        <Tooltip />
        <Legend />
        <Area
          type='monotone'
          dataKey='uv'
          stackId='1'
          stroke='#8884d8'
          fill='#EDC5F9'
        />
        {/* <Area type="monotone" dataKey="pv" stackId="1" stroke="#DE86D6" fill="#FBE5F7" /> */}
      </AreaChart>
    </ResponsiveContainer>
  )
}
export default DashboardListAreaChart
