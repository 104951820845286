import React, { useState, useEffect } from "react";
import "./index.css";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import UsersAreaChart from "../../components/UsersAreaChart";
import GenderPieChart from "../../components/GenderPieChart";
import { getClientUsers, getRepeatUsers } from "../../actions/dataLoad";
import LoadingSeparate from "../../components/Utilities/Loading/LoadingSeparate";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Select, MenuItem } from "@material-ui/core";
import Box from "@mui/material/Box";
import { useUserTokenSelector } from "store/reducers";
import { useLocation } from "react-router-dom";

let teamsData = ["Sales","IT"]
let locationsData = ["Bangalore","Pune"]

export const Overview = ({ history, adminCorporateId }) => {
  const userToken = useUserTokenSelector();
  const [uniqueUsers, setUniqueUsers] = useState("");
  const [genderChartData, setGenderChartData] = useState([]);
  const [signedUpUsers, setSignedUpUsers] = useState("");
  const [monthlyLoginData, setMonthlyLoginData] = useState([]);
  const [repeatUsers, setRepeatUsers] = useState("");
  const [currMonthSessionCount, setCurrMonthSessionCount] = useState("");
  const [sessionCountChange, setSessionCountChange] = useState("");
  const [uniqueUserChange, setUniqueUserChange] = useState("");
  //const [setLoadingClient, setLoadingClient] = useState(false)
  //const [setLoadingRepeat, setLoadingRepeat] = useState(false)
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("All Teams");
  const [selectedLocation, setSelectedLocation] = useState("All Locations");
  const [teams, setTeams] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedGender, setSelectedGender] = useState("Overall");
  const location = useLocation();

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    const teams = teamsData.map((team) => team);
    teams.push("All Teams");
    setTeams(teams);
    const locations = locationsData.map((location) => location);
    locations.push("All Locations");
    setLocations(locations);
    getAllClientUsers();
    getAllRepeatUsers();
  }, []);

  useEffect(() => {
    getAllClientUsers();
    getAllRepeatUsers();
  },[selectedTeam, selectedLocation, selectedGender]);

  const getAllClientUsers = async () => {
    try {
      const res = await getClientUsers(userToken, adminCorporateId, selectedTeam, selectedLocation, selectedGender);
      setSignedUpUsers(res.data.signedUpUsers);
      setGenderChartData(res.data.genderChartData);
    } catch (err) {
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const getAllRepeatUsers = async () => {
    try {
      const res = await getRepeatUsers(userToken, adminCorporateId, selectedTeam, selectedLocation, selectedGender);
      setUniqueUsers(res.data.uniqueUsers);
      setMonthlyLoginData(res.data.monthlyLoginData);
      setCurrMonthSessionCount(res.data.currMonthSessionCount);
      setSessionCountChange(res.data.sessionCountChange);
      setUniqueUserChange(res.data.uniqueUserChange);
    } catch (err) {
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  return (
    <div className="overview usersstuff">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>

      {/* gender location and team filter */}
        {/* <div className="select-div">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h5 className="select-gender-heading">
              Select Filters{" "}
              <span style={{ fontSize: "0.75rem" }}>(it will work for all graphs){" "}</span>
            </h5>{" "}
          </div>
          <Box className="filters">
            <Select
              value={selectedGender}
              onChange={(event) => setSelectedGender(event.target.value)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value="Overall">Overall</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
            <Select
            value={selectedTeam}
            onChange={(e) => setSelectedTeam(e.target.value)}
            label="Department"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
                PaperProps: {
                  style: {
                  maxHeight: 200, // Set your desired max height
                },
              },
            }}
          >
            { teams.map((team) => ( <MenuItem value={team}>{team}</MenuItem> )) }
          </Select>
          <Select
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            label="Location"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Set your desired max height
                    },
                  },
                }}
              >
                {
                  locations.map((location) => (
                    <MenuItem value={location}>{location}</MenuItem>
                  ))
                }
              </Select>
            </Box>
        </div> */}
      <h3 className="title-topoverview" style={{ marginLeft: "7.5%",fontSize:"1.5em",marginBottom:"40px" }}>
        User Breakup
      </h3>
      {/* <div className="filters">
        <Box className="filterbox">
          <Select
            value={selectedTeam}
            onChange={(e) => setSelectedTeam(e.target.value)}
            label="Department"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
                PaperProps: {
                  style: {
                  maxHeight: 200, // Set your desired max height
                },
              },
            }}
          >
            { teams.map((team) => ( <MenuItem value={team}>{team}</MenuItem> )) }
          </Select>
        </Box>
        <Box>
          <Select
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            label="Location"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Set your desired max height
                    },
                  },
                }}
              >
                {
                  locations.map((location) => (
                    <MenuItem value={location}>{location}</MenuItem>
                  ))
                }
              </Select>
            </Box>
          </div> */}
      <div
        className="top-overview"
        style={{ display: "flex", height: "300px", alignItems: "center" }}
      >
        {/* <div
          className="box-bubbles"
          id="new-sign-up"
          style={{
            display: "flex",
            height: "100%",
            width: "30%",
            marginRight: "30px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="left-bb-users">
            <h3 className="title-topoverview" style={{ textAlign: "center" }}>
              New Sign-ups this Month
            </h3>
            <h3 className="title-topoverview" style={{ color: "#FEB37D" }}>
              {uniqueUsers}
            </h3>
            <h3
              className="title-topoverview sectionaladd"
              style={{ color: sessionCountChange <= 0 ? "#FF5177" : "#32CD32" }}
            >
              <ControlPointIcon /> {uniqueUserChange}%
            </h3>
          </div>
        </div> */}
        <div
          className="box-bubbles"
          style={{
            display: "flex",
            height: "100%",
            width: "55%",
            position: "relative",
            flexDirection: "column",
          }}
        >
          <div
            className="mid-bb-users"
            style={{ width: "100%", marginLeft: 0 }}
          >
            <h3 className="title-topoverview" style={{ textAlign: "center",fontSize:"1.5em",marginBottom:"50px" }}>
              User Logins
            </h3>
            <UsersAreaChart data={monthlyLoginData} />
          </div>
        </div>
      </div>
      <div className="bottom-overview">
        <div className="left-usersstuff">
          <div className="container-lus">
            <p className="title-clus">All Time</p>
            <p className="count-clus">{signedUpUsers}</p>
            <p className="desc-clus">
              <PersonOutlineIcon /> Signed up users
            </p>
          </div>
        </div>
        <div className="right-usersstuff">
          <h3 className="title-topoverview" style={{fontSize:"1.5em"}}>Gender Ratio</h3>
          <div className="pie-chart-usersstuff">
            <GenderPieChart data={genderChartData} />
          </div>
          <div className="info-pie">
            <div className="inner-infopie">
              <div
                className="colorbox"
                style={{ backgroundColor: "#FF305E" }}
              ></div>
              <p>Male</p>
            </div>
            <div className="inner-infopie">
              <div
                className="colorbox"
                style={{ backgroundColor: "#7947F7" }}
              ></div>
              <p>Female</p>
            </div>
            <div className="inner-infopie">
              <div
                className="colorbox"
                style={{ backgroundColor: "#FF8832" }}
              ></div>
              <p>Other</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
