import { Container, Row, Col } from "react-bootstrap";
import "./index.css";

const Support = () => {
  return (
    <div className="dashboardofboxes">
      <Container>
        <Row>
          <Col sm={12} md={12} style={{ marginTop: "30px" }}>
            <img
              src="https://ucarecdn.com/0c8a6217-7ce3-4333-8420-645865a9e2f8/support.gif"
              alt="support"
              className="sp-image"
            />
          </Col>
          <Col sm={12} md={12}>
            <p className="sp-text">
              Stuck with something? We are here to help you.
            </p>
            <p className="sp-text">
              Reach out to us at{" "}
              <button
                className="email-support-button"
                onClick={() => {
                  window.open("mailto:contact@goodlives.in", "_blank");
                }}
              >
                contact@goodlives.in
                <svg
                  stroke="currentColor"
                  fill="none"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginLeft: "0.5rem" }}
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
              </button>{" "}
              or talk to us on{" "}
              <button
                className="whatsapp-support-button"
                onClick={() => {
                  window.open("https://wa.me/917478530132", "_blank");
                }}
              >
                Whatsapp
                <img
                  src="https://ucarecdn.com/aca8ecac-d2e8-499b-88fc-9b02496fcb36/whatsappsmall.svg"
                  alt="GoodLives_Home_Whatsapp"
                  style={{ marginLeft: "0.5rem" }}
                ></img>
              </button>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Support;
