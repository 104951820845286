import React from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";

const Chart = ({ notEngagedUser, engagedUsers }) => {
  const data = [
    { name: "Not Engaged Users", value: notEngagedUser },
    { name: "Engaged Users", value: engagedUsers },
  ];

  const COLORS = ["#96A8FF", "#379E90"]; // Define colors for your pie chart segments

  return (
    <PieChart width={400} height={250}>
      <Pie data={data} fill="#8884d8" dataKey="value" label>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend />
    </PieChart>
  );
};

export default Chart;
