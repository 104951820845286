import React, { useEffect, useState } from "react";
import { Select, MenuItem, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import { getEmployeeMood } from "../../../actions/assessment";

export default function HappyEmployees({ token, adminCorporateId }) {
  const [selectedHappyEmpYear, setSelectedHappyEmpYear] = useState(
    new Date().getFullYear()
  );
  const [selectedHappyEmpMonth, setSelectedHappyEmpMonth] = useState(
    new Date().getMonth() + 1
  );
  const [happyEmployees, setHappyEmployees] = useState([]);

  const getHappyEmployeeData = async () => {
    try {
      const res = await getEmployeeMood(
        token,
        adminCorporateId,
        selectedHappyEmpYear,
        selectedHappyEmpMonth
      );
      const employeeData = res.data;
      const happyEmployees =
        employeeData.find((data) => data.name === "Happy Employees")?.value ||
        [];
      setHappyEmployees(happyEmployees);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHappyEmployeeData();
  }, [selectedHappyEmpYear, selectedHappyEmpMonth]);

  const handleHappyEmpYearChange = (e) => {
    setSelectedHappyEmpYear(e.target.value);
  };

  const handleHAppyEmpMonthChange = (e) => {
    setSelectedHappyEmpMonth(e.target.value);
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const availableMonths = Array.from({ length: 12 }, (_, i) => i + 1).filter(
    (month) =>
      !(
        selectedHappyEmpYear === new Date().getFullYear() &&
        month > new Date().getMonth() + 1
      )
  );
  return (
    <div className="top-lsst">
      <Typography
        style={{
          marginBottom: "0.3rem",
          fontWeight: "500",
          fontSize: "1.25rem",
          color: "#0D0D0D",
        }}
      >
        Happy Employees
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Select
          value={selectedHappyEmpYear}
          onChange={handleHappyEmpYearChange}
        >
          {Array.from({ length: new Date().getFullYear() - 2021 }, (_, i) => (
            <MenuItem value={2022 + i} key={i}>
              {2022 + i}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={selectedHappyEmpMonth}
          onChange={handleHAppyEmpMonthChange}
        >
          {availableMonths.map((month, index) => (
            <MenuItem key={month} value={month}>
              {monthNames[index]}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <div className="employees">
        {happyEmployees &&
          happyEmployees.map((employee, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                backgroundColor: "#FFF",
                borderRadius: "0.38244rem",
                border: "1.224px solid #96A8FF",
                padding: "0.75rem",
                marginBottom: "1rem",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  background: "#96A8FF",
                  fontWeight: "450",
                  padding: "4px 1rem",
                  borderRadius: "0.19119rem",
                  minWidth: "10%",
                  color:""
                }}
              >
                {" "}
                <Typography>{employee.score}</Typography>
              </div>
              <div
                style={{
                  padding: "4px 1rem",
                  minWidth: "40%",
                }}
              >
                <Typography>{employee.name}</Typography>
              </div>

              <div
                style={{
                  padding: "4px 1rem",
                  minWidth: "40%",
                }}
              >
                <Typography>
                  Mood Inputs: {employee.moodInputs} times
                </Typography>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
