import React, { useState } from "react";
import Loading from "../../components/Utilities/Loading/Loading";
import EngagementAnalysis from "./EngagementAnalysis";
import Analytics from "./Analytics";
import HappinessScore from "./HappinessScore";
import { Button } from "@material-ui/core";
import { useUserTokenSelector } from "store/reducers";

const App = ({ adminCorporateId }) => {
  const [activeComponent, setActiveComponent] = useState("HappinessScore");
  const userToken = useUserTokenSelector();

  const renderComponent = (componentName) => {
    setActiveComponent(componentName);
  };

  return (
    <div className="overview usersstuff">
      <div className="zen-options">
        <Button
          variant="contained"
          onClick={() => renderComponent("HappinessScore")}
        >
          Happiness Score
        </Button>
        <Button
          variant="contained"
          onClick={() => renderComponent("EngagementAnalysis")}
          style={{ margin: "0rem 2rem" }}
        >
          Engagement Analysis
        </Button>
        <Button
          variant="contained"
          onClick={() => renderComponent("Analytics")}
        >
          Analytics
        </Button>
      </div>
      {activeComponent === "HappinessScore" && (
        <HappinessScore token={userToken} adminCorporateId={adminCorporateId} />
      )}
      {activeComponent === "EngagementAnalysis" && (
        <EngagementAnalysis token={userToken} adminCorporateId={adminCorporateId} />
      )}
      {activeComponent === "Analytics" && (
        <Analytics token={userToken} adminCorporateId={adminCorporateId} />
      )}
    </div>
  );
};

export default App;
