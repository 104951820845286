import React, { PureComponent } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Label,
  Bar,
  ResponsiveContainer,
} from "recharts";

export default class SessionAreaChart extends PureComponent {
  state = {
    activeIndex: 3,
  };

  render() {
    const { data } = this.props;
    const uniqueWholeNumbers = Array.from(
      new Set(data.map((entry) => Math.floor(entry.uv)))
    );
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={400}
          data={data}
          margin={{
            bottom: 20,
          }}
        >
          <XAxis dataKey="name">
            <Label
              value="Number of Repeat sessions"
              // offset={10}
              dy={1}
              fill="#379E90"
              position="bottom"
            />
          </XAxis>
          <YAxis tickCount={uniqueWholeNumbers.length}>
            <Label
              value="Number Of Users"
              offset={20}
              fill="#379E90"
              position="insideBottomLeft"
              angle={-90}
            />
          </YAxis>
          <Tooltip />

          <Bar name="Users" dataKey="uv" fill="#379E90" />

        </BarChart>
      </ResponsiveContainer>
    );
  }
}
