import React, {useState, useEffect} from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label
} from "recharts";
import {
  getAssessment,
  loadQuizNames,
} from "../../actions/assessment"
import Loading from "../Utilities/Loading/Loading";
import { useUserTokenSelector } from "store/reducers";
import { useHistory, useLocation } from "react-router-dom";



export const CustomizedDot = (props) => {
  const { cx, cy, value, payload } = props;
  if (payload.name === "Moderate Signs") {
    return (
        
      <svg id="emoji" 
		  x={cx-115}
		  y={cy-20}
		  viewBox="0 0 600 600"xmlns="http://www.w3.org/2000/svg">
		  <g id="line-supplement">
			<path d="M42.8472,45.6583c0-2.592-3.834-4.694-6.869-4.694s-6.869,2.101-6.869,4.694,3.659.9248,6.694.9248S42.8472,48.25,42.8472,45.6583Z"/>
		  </g>
		  <g id="color">
			<path fill="#fcea2b" d="M35.9772,12.9583a23,23,0,1,0,23,23A23.026,23.026,0,0,0,35.9772,12.9583Z"/>
			<path fill="#92d3f5" d="M35.9772,24.7689A23.0392,23.0392,0,0,1,58.2053,41.8636a23,23,0,1,0-44.4561,0A23.0389,23.0389,0,0,1,35.9772,24.7689Z"/>
			<path d="M42.8472,45.6583c0-2.592-3.834-4.694-6.869-4.694s-6.869,2.101-6.869,4.694,3.659.9248,6.694.9248S42.8472,48.25,42.8472,45.6583Z"/>
		  </g>
		  <g id="line">
			<path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M29.3564,22.669a8.44,8.44,0,0,0-9.0659,4.4981"/>
			<path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M51.664,27.1671a8.44,8.44,0,0,0-9.0659-4.4981"/>
			<circle cx="35.9772" cy="35.9583" r="23" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2"/>
			<path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" d="M42.8472,45.6583c0-2.592-3.834-4.694-6.869-4.694s-6.869,2.101-6.869,4.694,3.659.9248,6.694.9248S42.8472,48.25,42.8472,45.6583Z"/>
			<path d="M29.9772,32.9583a3,3,0,1,1-3-3,3.0011,3.0011,0,0,1,3,3"/>
			<path d="M47.9772,32.9583a3,3,0,1,1-3-3,3.0011,3.0011,0,0,1,3,3"/>
		  </g>
	</svg>
    );
  }
  if (payload.name === "No Signs") {
    return (
	  <svg id="emoji"
	    x={cx-115}
	    y={cy-20}
        viewBox="0 0 600 600"
		xmlns="http://www.w3.org/2000/svg">
		<g id="color">
			<circle cx="36" cy="36" r="23" fill="#fcea2b"/>
			<path fill="#fff" d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"/>
			<path fill="#ea5a47" d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"/>
		</g>
		<g id="hair"/>
		<g id="skin"/>
		<g id="skin-shadow"/>
		<g id="line">
		<polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="25.168 27.413 31.755 31.427 25.168 35.165"/>
		<polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="46.832 27.413 40.245 31.427 46.832 35.165"/>
		<circle cx="36" cy="36" r="23" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
		<path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"/>
		<path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"/>
	  </g>
	</svg>


    );
  }
  if (payload.name === "Mild Signs") {
    return (
      <svg id="emoji" 
		  x={cx-115}
		  y={cy-20}
		  viewBox="0 0 600 600"
		  xmlns="http://www.w3.org/2000/svg">
		  <g id="color">
			<circle cx="36" cy="36" r="24" fill="#fcea2b"/>
			<path fill="#92d3f5" d="M54.25,28.1758a4.2422,4.2422,0,0,1-4.2442-4.2427c0-4.2026,4.211-8.4619,4.253-8.5044.1543.1729,4.2343,4.3228,4.2343,8.5039A4.241,4.241,0,0,1,54.25,28.1758Z"/>
			<path fill="#fff" d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"/>
			<path fill="#ea5a47" d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"/>
		  </g>
		  <g id="hair"/>
		  <g id="skin"/>
		  <g id="skin-shadow"/>
		  <g id="line">
			<path fill="none" stroke="#000" stroke-linejoin="round" stroke-width="2" d="M54.25,28.1758a4.2422,4.2422,0,0,1-4.2442-4.2427c0-4.2026,4.211-8.4619,4.253-8.5044.1543.1729,4.2343,4.3228,4.2343,8.5039A4.241,4.241,0,0,1,54.25,28.1758Z"/>
			<path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M48.0945,16.433A22.9582,22.9582,0,1,0,58.4938,31.1785"/>
			<path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"/>
			<path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"/>
			<path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M31.6941,33.4036a4.7262,4.7262,0,0,0-8.6382,0"/>
			<path fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M48.9441,33.4036a4.7262,4.7262,0,0,0-8.6382,0"/>
		  </g>
	</svg>
    );
  }
  if (payload.name === "Severe Signs") {
    return (
      <svg id="emoji" 
		  x={cx-115}
		  y={cy-20}
		  viewBox="0 0 600 600"
		  xmlns="http://www.w3.org/2000/svg">
		  <g id="color">
			<path transform="translate(0 0)" fill="#fcea2b" d="M36,12.9583a23,23,0,1,0,23,23h0A23.026,23.026,0,0,0,36,12.9583Z"/>
		  </g>
		  <g id="line">
			<circle cx="36" cy="35.9583" r="23" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2"/>
			<path transform="translate(0 0)" stroke="#000" stroke-miterlimit="10" stroke-width="2.5104" d="M35.7778,43.6811a4.1686,4.1686,0,1,0,.0005,0Z"/>
			<g>
			  <g>
				<path transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M41.27,30.9367a5.4893,5.4893,0,1,1,10.9786,0"/>
				<path transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M44.93,30.9367a1.83,1.83,0,0,1,3.66,0"/>
			  </g>
			  <path transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M41.27,30.9367a7.3149,7.3149,0,0,0,7.3107,7.3191h.0084"/>
			  <path transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M44.93,30.9367a3.66,3.66,0,0,0,7.319.121q.001-.06,0-.121"/>
			</g>
			<g>
			  <g>
				<path transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M19.3155,30.9367a5.4893,5.4893,0,0,1,10.9786,0"/>
				<path transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M22.975,30.9367a1.83,1.83,0,0,1,3.66,0"/>
			  </g>
			  <path transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M19.3155,30.9367a7.3149,7.3149,0,0,0,7.3107,7.3191h.0084"/>
			  <path transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M22.975,30.9367a3.66,3.66,0,1,0,7.3191.1148q.0009-.0573,0-.1148"/>
			</g>
		  </g>
	</svg>

    );
  }
  return (
    <svg
      x={cx - 10}
      y={cy - 10}
      width={20}
      height={20}
      fill="green"
      viewBox="0 0 1024 1024"
    >
      <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
    </svg>
  );
};

export default function AssessmentGraph({adminCorporateId}) {
  const [allQuizNames, setAllQuizNames] = useState([])
  const [open, setOpen] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false);
	const userToken = useUserTokenSelector();
 
  const [quizId, setQuizId] = useState("");
  const [assessmentData, setAssessmentData] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const showError = (text) => {
    setError(text)
    setOpen(true)
  }
  const getAssessmentData = async (id)=>{
    try {
      setLoading(true)
      const res = await getAssessment(userToken, adminCorporateId, id)
      setAssessmentData(res.data)
      setLoading(false)
    } catch (error) {
      
    }
  }
  const getQuizNames = async () => {
    try {
      const res = await loadQuizNames(userToken)
      setAllQuizNames(res.data)
	  setQuizId(res.data[0]._id.toString())
	  getAssessmentData(res.data[0]._id.toString())
    } catch (err) {
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error)
		setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error)
      }
    }
  }
  const handleChange = async (event) => {
    setQuizId(event.target.value);
    await getAssessmentData(event.target.value);
  }
  useEffect(()=>{
	  getQuizNames();
  }, [])
  return (
    <div>
		<div style={{textAlign:"right"}}>
			<select  value={quizId} onChange={(event) => handleChange(event) }>
				{ allQuizNames.map(quiz => <option  value={quiz._id.toString()}>{quiz.name}</option>)}
			</select>
		</div>
		
		<div>
			<ResponsiveContainer width={500} height={300}>
				<LineChart
				  width={500}
				  height={300}
				  data={assessmentData}
				  margin={{
					top: 10,
					right: 20,
					left: 20,
					bottom: 30
				  }}
				>
				  <CartesianGrid strokeDasharray="3 3" />
				  <XAxis dataKey="name" padding={{top:50}}>
						<Label value="Severity of Problem" offset={-20} fill="#8884d8" position="insideBottom"/>  
					</XAxis>
				  <YAxis
					type="number"
					allowDecimals={false}
				  >
					<Label value="Number Of Employees" offset={20} fill="#8884d8" position="insideBottomLeft" angle={-90}/>  
				 </YAxis>
				  <Tooltip />
				  <Line
					name="No. of Employees"
					type="monotone"
					dataKey="pv"
					stroke="#8884d8"
					dot={<CustomizedDot />}
				  />
				</LineChart>
			</ResponsiveContainer>
		</div>
    </div>
  );
}

// export default AssessmentGraph