import React, { useState, useEffect } from "react"
import "./index.css"
import EventAvailableIcon from "@material-ui/icons/EventAvailable"
import TrendingDownIcon from "@material-ui/icons/TrendingDown"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { walletSessionsInfo } from "../../actions/dataLoad"
import DashboardListAreaChart from "../../components/DashboardListAreaChart"
import Alert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import { useLoadingSelector, useUserInfoSelector, useUserTokenSelector } from "store/reducers"
import { useDispatch } from "react-redux"
import { startLoading, stopLoading } from "store/actions"
import { useHistory, useLocation } from "react-router-dom"

export const UserWallet = (props) => {
  const [currentDate, setCurrentDate] = useState("")
  const [currentDateSavings, setCurrentDateSavings] = useState("")
  const [selectedMonth, setSelectedMonth] = useState("August")
  const currentYear=new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [walletSessionBalance, setWalletSessionBalnce] = useState("")
  const [transactions, setTransactions] = useState([])
  const [currency, setCurrency] = useState("");
  const userToken = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const [userName, setUserName] = useState("")
  const [weeklySavings, setWeeklySavings] = useState([])
  const [open, setOpen] = useState(false)
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const years = [currentYear-2,currentYear-1,currentYear];

  const showError = (text) => {
    setError(text)
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false)
    }
    setOpen(false)
  }

  useEffect(() => {
    let today = new Date()
    setCurrentDate(today)
    setCurrentDateSavings(today)
    setSelectedMonth(getMonthName(today.getMonth()))
    loadAllWalletSessionInfo()
  }, [])

  const loadAllWalletSessionInfo = async () => {
    try {
      dispatch(startLoading());
      const res = await walletSessionsInfo(userToken)
      setWalletSessionBalnce(res.data.walletSessionBalance)
      setTransactions(res.data.transactions)
      let currencySymbol = res.data.currency === "INR" ? "₹" : "$"
      setCurrency(currencySymbol)
      setUserName(res.data.userName)
      setWeeklySavings(res.data.weeklySavings)
      dispatch(stopLoading())
    } catch (err) {
      dispatch(stopLoading())
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error)
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error)
      }
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const getMonthName = (i) => {
    i = Number(i) + 1
    if (i === 1) {
      return "January"
    } else if (i === 2) {
      return "February"
    } else if (i === 3) {
      return "March"
    } else if (i === 4) {
      return "April"
    } else if (i === 5) {
      return "May"
    } else if (i === 6) {
      return "June"
    } else if (i === 7) {
      return "July"
    } else if (i === 8) {
      return "August"
    } else if (i === 9) {
      return "September"
    } else if (i === 10) {
      return "October"
    } else if (i === 11) {
      return "November"
    } else if (i === 12) {
      return "December"
    }
  }

  return (
    <div className='mainuserdashboardoverview'>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error' sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <div className='top-mudo'>
        <p className="dashboard-heading">Hey {userInfo.fullName}, welcome back!</p>
      </div>
      <div className='container-mudo'>
        <div className='left-cmudo'>
          <a href='/sessionBooking' style={{ textDecoration: "none" }}>
            <div className='dashboard-containerlctop'>
              <p className='firstheading-clct'>Therapy Sessions</p>
              <p className='secheading-clct'>
                {walletSessionBalance === 0
                  ? walletSessionBalance + " Pending Session"
                  : walletSessionBalance + " Pending Sessions"}
              </p>
              <p className='bottomheading-clct'>Book Now</p>
            </div>
          </a>
          <div className='headings-containerlctop'>
            <div className='top-rdlc'>
              <p className='title-topoverview'>Savings Chart</p>
              <div className='graybuttons'>
                Year:{" "}
                <select
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(e.target.value)
                  }}
                >
                  {
                    years.map((year,index)=>(
                      <option value={year} key={index}>{year}</option>
                    ))
                  }
                </select>
                {/* <button>{currentDateSavings.getDate()} {this.getMonthName(currentDateSavings.getMonth())} <ExpandMoreIcon />
                                      <input type="date" onChange={(e) => {
                                          let newDate = new Date(e.target.value)
                                          this.setState({ currentDateSavings: newDate })
                                      }} /> </button> */}
              </div>
            </div>
          </div>
          <div className='bottom-containerlctop'>
            <DashboardListAreaChart weeklySavings={weeklySavings} />
          </div>
        </div>
        <div className='right-cmudo'>
          <div className='top-rdlc'>
            <p className='title-topoverview'>Transaction History</p>
            <div className='graybuttons'>
              <select
                value={selectedMonth}
                onChange={(e) => {
                  setSelectedMonth(e.target.value)
                }}
              >
                <option value='January'>January</option>
                <option value='February'>February</option>
                <option value='March'>March</option>
                <option value='April'>April</option>
                <option value='May'>May</option>
                <option value='June'>June</option>
                <option value='July'>July</option>
                <option value='August'>August</option>
                <option value='September'>September</option>
                <option value='October'>October</option>
                <option value='January'>November</option>
                <option value='January'>December</option>
              </select>
              {/* <button>{currentDate.getDate()} {this.getMonthName(currentDate.getMonth())} <ExpandMoreIcon />
                                  <input type="date" onChange={(e) => {
                                      let newDate = new Date(e.target.value)
                                      this.setState({ currentDate: newDate })
                                  }} /> </button> */}
            </div>
          </div>
          {transactions && (
            <ul style={{ height: "600px" }}>
              {transactions.map((t) => {
                let dateString = t.transactionDate
                let pos = dateString.indexOf("-")
                let currentMonth =
                  dateString[pos + 1] === "0"
                    ? dateString[pos + 2]
                    : dateString[pos + 1] + dateString[pos + 2]
                if (selectedMonth === getMonthName(parseInt(currentMonth) - 1))
                  return (
                    <li>
                      <div className='container-rdlc'>
                        <div className='topbottomparas'>
                          <p className='username'>
                            {t.operation === "deposit"
                              ? capitalizeFirstLetter(userName)
                              : "GoodLives"}
                          </p>
                          <p className='smalldesc'>
                            {capitalizeFirstLetter(t.operation)}
                          </p>
                        </div>
                        <p className='price-dlc'>
                          {t.operation === "withdrawal" ? (
                            <TrendingDownIcon style={{ color: "red" }} />
                          ) : (
                            <TrendingUpIcon style={{ color: "green" }} />
                          )}{" "}
                          {currency}
                          {t.amount}
                        </p>
                        {t.operation !== "cashback" ? (
                          <button className='btn-greendlc'>PAID</button>
                        ) : (
                          <button className='btn-cashback'>CASHBACK</button>
                        )}
                        <p className='calendar-dlc'>
                          <EventAvailableIcon /> {t.transactionDate}
                        </p>
                      </div>
                    </li>
                  )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserWallet;
