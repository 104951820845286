import React, { Component } from "react";
// import logo from "../assets/images/headerbookinglogo.png"
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ListAltIcon from "@material-ui/icons/ListAlt";
import GroupIcon from "@material-ui/icons/Group";
import BarChart from "@material-ui/icons/BarChart";
import EventAvailable from "@material-ui/icons/EventAvailable";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import MoodIcon from "@material-ui/icons/Mood";
import Apps from "@material-ui/icons/Apps";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import PhoneIcon from "@material-ui/icons/Phone";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Timeline from "@material-ui/icons/Timeline"
import { HR_ASSESSMENT_SCORE, HR_OVERVIEW, HR_SESSIONS, HR_SUPPORT, HR_USERS, HR_ZEN_ANALYTICS } from "./HRDashboard";

export class HeaderDashboard extends Component {
  state = {
    menuOpen: false,
    notifications: [],
    showNotificationsPanel: false,
  };

  render() {
    return (
      <div
        className="headerbookings"
        style={{ position: "fixed", backgroundColor: "white" }}
      >
        <img
          src="https://ucarecdn.com/4748640f-b537-4b9b-8896-362944eaaae3/headerbookinglogo.png"
          className="image-logo-hb"
        />
        <div className="lists-header-booking">
          <ul>
            <li>
              <a href="/" style={{ textDecoration: "none", color: "#212529" }}>
                Home
              </a>
            </li>
            <li>
              <a
                href="https://www.goodlives.in/how-it-works/"
                style={{ textDecoration: "none", color: "#212529" }}
              >
                How it works?
              </a>
            </li>
            <li>
              <a
                href="/corporates"
                style={{ textDecoration: "none", color: "#212529" }}
              >
                For Corporates
              </a>
            </li>
            <li>
              <a
                href="https://www.goodlives.in/blog/"
                style={{ textDecoration: "none", color: "#212529" }}
              >
                Blogs
              </a>
            </li>
          </ul>
        </div>
        <div className="menu-header-booking">
          <div className="lists-tn notificationsboxcontainer">
            <NotificationsNoneIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ showNotificationsPanel: true });
              }}
            />
            <div className="ring-counts">
              <p>{this.state.notifications.length}</p>
            </div>
            {this.state.showNotificationsPanel && (
              <div className="inner-notificationboxmain">
                <div className="top-inbm">
                  <p>Notifications</p>
                </div>
                <div className="bottom-inbm">
                  {this.state.notifications.length > 0 ? (
                    <ol>
                      {this.state.notifications.map((e, i) => {
                        return (
                          <li
                            onClick={() => {
                              this.props.showSessions();
                              document.getElementById("root").style.overflowY =
                                "auto";
                              document.getElementById("root").style.position =
                                "static";
                            }}
                          >
                            {i + 1}. Notification {e}
                          </li>
                        );
                      })}
                    </ol>
                  ) : (
                    <p className="nonewnotficiationslabel">
                      <NotificationsOffIcon />
                      <font>
                        No New <br /> Notifications
                      </font>
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* <div className="lists-tn">
                        <SettingsIcon />
                    </div> */}
          <div className="lists-tn">
            <Tooltip
              title="Log Out"
              zIndex="999999999"
              placement="bottom"
              overlayInnerStyle={{
                borderRadius: "10px",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#000",
              }}
              color="#e8e8e8"
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={this.props.handleLogout}
              >
                <ExitToAppIcon />
              </div>
            </Tooltip>
          </div>
          {window.innerWidth < 1051 && (
            <div className="lists-tn">
              <MenuIcon
                onClick={() => {
                  document.getElementById("root").style.overflowY = "hidden";
                  document.getElementById("root").style.position = "fixed";
                  this.setState({ menuOpen: true });
                }}
              />
            </div>
          )}
        </div>
        {this.state.menuOpen && (
          <div className="menu-lists-mobile">
            <CloseIcon
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                zIndex: 99,
                fill: "white",
              }}
              onClick={() => {
                document.getElementById("root").style.overflowY = "auto";
                document.getElementById("root").style.position = "static";
                this.setState({ menuOpen: false });
              }}
            />
            <div className="left-menustuff">
              <p className="title-lms">
                GoodLives<sup style={{ fontSize: "14px" }}>TM</sup>
              </p>
              <div className="content-lms">
                <ul>
                  <li
                    className={`${this.props.selectedTab === HR_OVERVIEW ? "active" : ""}`}
                    onClick={() => {
                      this.props.showOverView();
                      document.getElementById("root").style.overflowY = "auto";
                      document.getElementById("root").style.position = "static";
                    }}
                  >
                    <ListAltIcon /> Overview
                  </li>
                  <li
                    className={`${this.props.selectedTab === HR_USERS ? "active" : ""}`}
                    onClick={() => {
                      this.props.showUsers();
                      document.getElementById("root").style.overflowY = "auto";
                      document.getElementById("root").style.position = "static";
                    }}
                  >
                    <GroupIcon /> Users
                  </li>
                 
                  <li
                    className={`${this.props.selectedTab === HR_SESSIONS ? "active" : ""}`}
                    onClick={() => {
                      this.props.showSessions();
                      document.getElementById("root").style.overflowY = "auto";
                      document.getElementById("root").style.position = "static";
                    }}
                  >
                    <BarChart /> Sessions
                  </li>
                  <li
                    className={`${
                      this.props.selectedTab === HR_ASSESSMENT_SCORE ? "active" : ""
                    }`}
                    onClick={() => {
                      this.props.showOrganizational();
                      document.getElementById("root").style.overflowY = "auto";
                      document.getElementById("root").style.position = "static";
                    }}
                  >
                    <MoodIcon /> Assessment Score
                  </li>
                  <li
                    className={`${
                      this.props.selectedTab === HR_ZEN_ANALYTICS ? "active" : ""
                    }`}
                    onClick={() => {
                      this.props.showSlack()
                      document.getElementById("root").style.overflowY = "auto";
                      document.getElementById("root").style.position = "static";
                    }}
                  >
                    <Timeline/>Zen  Analytics
                  </li>
                  <li
                    className={`${
                      this.props.selectedTab === HR_SUPPORT ? "active" : ""
                    }`}
                    onClick={() => {
                      this.props.showSupport();
                      document.getElementById("root").style.overflowY = "auto";
                      document.getElementById("root").style.position = "static";
                    }}
                  >
                    <PhoneIcon /> Support
                  </li>
                  {this.props.categoryId === 4 ? (
                    <button
                      className="whiteobottom"
                      style={{ width: "170px" }}
                      onClick={(e) => {
                        const newWindow = window.open(
                          "https://wa.me/917478530132",
                          "_blank",
                          "noopener,noreferrer"
                        );
                        if (newWindow) newWindow.opener = null;
                      }}
                    >
                      Contact Admin
                    </button>
                  ) : (
                    <button className="whiteobottom">
                      <Link
                        to="/sessionBooking"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        Book now!
                      </Link>
                    </button>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
        {this.state.showNotificationsPanel && (
          <div
            className="popup-payment-alert-overaly"
            style={{ backgroundColor: "transparent" }}
            onClick={() => {
              this.setState({ showNotificationsPanel: false });
            }}
          ></div>
        )}
      </div>
    );
  }
}

export default HeaderDashboard;
