import { useEffect, useState } from "react";
import styles from "./MyProfile.module.css";
import { MdClose, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { getUserProfile, updateUserProfile } from "actions/auth";
import { useUserTokenSelector } from "store/reducers";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "store/actions";
import { countryCodes } from "utils/data";

const MyProfilePopup = ({ isOpen, toggleProfilePopup, showError, showMessage }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [age, setAge] = useState();
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [showCountryCodeDropdown, setShowCountryCodeDropdown] = useState(false);
  const token = useUserTokenSelector();
  const dispatch = useDispatch();

  const genderOptions = [
    {
      name: "Male",
      value: "male",
    },
    {
      name: "Female",
      value: "female",
    },
    {
      name: "Other",
      value: "other",
    },
  ];

  const updateProfile = async () => {
    try {
      if (fullName.length < 5) {
        throw new Error("Please provide full name");
      } else if (!mobileNo) {
        throw new Error("Please provide mobile number");
      } else if (mobileNo.length > 10) {
        throw new Error("Please provide valid mobile number");
      } else if (!age || age == 0) {
        throw new Error("Please provide age");
      } else if (!["male", "female", "other"].includes(gender.toLowerCase())) {
        throw new Error("Please provide gender");
      } else if (!(countryCode && countryCodes.includes(countryCode))) {
        throw new Error("Please provide your country's mobile code");
      }

      await updateUserProfile(token, { fullName, mobileNo, age, gender: gender.toLowerCase(), countryCode });
      await fetchUserProfile();
      setIsEditing(false);
      showMessage("Profile updated successfully");
    } catch (error) {
      showError(error.response?.data?.error || error.message);
    }
  };

  const fetchUserProfile = async () => {
    getUserProfile(token)
      .then((res) => {
        const { user } = res.data;
        setFullName(user.fullName);
        setMobileNo(user.contact?.toString());
        setAge(user.age);
        setGender(user.gender);
        setEmail(user.email);
        setCountryCode(user.countrycode || "+91");
        dispatch(setUserInfo(user));
      })
      .catch((error) => {
        console.warn(error);
        showError(error.response?.data?.error || error.message);
      });
  };

  useEffect(() => {
    setIsEditing(false);
  }, [isOpen]);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  return (
    <div className={`${styles["profile-cont"]} ${!isOpen ? styles["profile-cont-hidden"] : ""}`}>
      <div className={styles.profile}>
        <div className={styles["profile-div"]}>
          <button className={styles["profile-close"]} onClick={toggleProfilePopup}>
            <MdClose size={16} />
          </button>
          <div className={styles["profile-cta-cont"]}>
            <Link
              to={{ pathname: "/sessionBooking", state: { change: true } }}
              className={styles["profile-cta"]}
              style={{ backgroundColor: "#DCF6E9", color: "#000", border: "1px solid #49AF7C" }}
            >
              Change Therapist
            </Link>
            {isEditing ? (
              <button className={styles["profile-cta"]} onClick={updateProfile} style={{ backgroundColor: "#49AF7C" }}>
                Save
              </button>
            ) : (
              <button
                className={styles["profile-cta"]}
                onClick={() => setIsEditing(true)}
                style={{ backgroundColor: "#49AF7C" }}
              >
                Edit
              </button>
            )}
          </div>
          <div className={styles["profile-div-input-cont"]}>
            <label for="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              disabled={!isEditing}
              value={fullName}
              onChange={(evt) => setFullName(evt.target.value)}
            />
          </div>
          <div className={styles["profile-div-input-cont2"]}>
            <div className={styles["profile-div-input-cont"]} style={{ width: "65%" }}>
              <label for="contact">Mobile number</label>
              <div className={styles["profile-div-input-mobile"]}>
                <div className={styles["profile-div-input-mobile-cta-cont"]}>
                  <button
                    disabled={!isEditing}
                    onClick={() => setShowCountryCodeDropdown((val) => !val)}
                    className={styles["profile-div-input-mobile-cta"]}
                  >
                    {countryCode} {showCountryCodeDropdown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}{" "}
                  </button>
                  {showCountryCodeDropdown && (
                    <div className={styles["profile-div-input-mobile-cta-drpdwn"]}>
                      {countryCodes.sort().map((code) => (
                        <button onClick={() => setCountryCode(code)}>{code}</button>
                      ))}
                    </div>
                  )}
                </div>
                <input
                  type="number"
                  id="contact"
                  disabled={!isEditing}
                  value={mobileNo}
                  maxLength={10}
                  onInput={(e) => {
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  onChange={(evt) => setMobileNo(evt.target.value)}
                />
              </div>
            </div>
            <div className={styles["profile-div-input-cont"]} style={{ width: "30%" }}>
              <label for="age">Age</label>
              <input
                type="number"
                id="age"
                className={styles["profile-div-input-age"]}
                disabled={!isEditing}
                value={age}
                maxLength={2}
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                onChange={(evt) => setAge(evt.target.value)}
              />
            </div>
          </div>
          <div className={styles["profile-div-input-cont"]}>
            <label for="email">Email</label>
            <input type="text" id="email" disabled value={email} />
          </div>
          <div className={styles["profile-div-input-cont"]}>
            <label for="gender">Gender</label>
            <select
              value={gender}
              id="gender"
              name="gender"
              disabled={!isEditing}
              onChange={(evt) => setGender(evt.target.value)}
            >
              {genderOptions.map((gender) => (
                <option key={gender.value} value={gender.value}>
                  {gender.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfilePopup;
