import React, { Component } from "react";
// import meditation1 from "../../assets/images/meditation1.png";
// import meditation2 from "../../assets/images/meditation2.png";
// import meditation3 from "../../assets/images/meditation3.png";
// import meditation4 from "../../assets/images/meditation4.png";
import "./index.css";
import { useUserInfoSelector } from "store/reducers";

const mindfulness=[
  {
    name:"Grounding Exercise",
    video:"https://app.goodlives.in/videos/Grounding%20Exercise.mp4",
    image:"https://ucarecdn.com/28de3448-1d86-462d-945d-eab9a178010c/meditation1.png"
  },
  {
    name:"Mindfulness",
    video:"https://app.goodlives.in/videos/Mindfulness%202.mp4",
    image:"https://ucarecdn.com/e9608a50-8707-4ffc-a86f-3812fc3a0072/meditation2.png"
  },
  {
    name:"Grounding Exercise for Anxiety",
    video:"https://app.goodlives.in/videos/Grounding%20Exercise%20for%20Anxiety.mp4",
    image:"https://ucarecdn.com/09c70eb0-79e1-437c-affe-a93e7bd70c34/meditation3.png"
  },
  {
    name:"Relaxation before Sleep",
    video:"https://app.goodlives.in/videos/Relaxation before Sleep.mp4",
    image:"https://ucarecdn.com/2a3f5259-2d42-493b-b3cd-f58a2c638dd6/meditation4.png"
  },
  {
    name:"Guided Medidation",
    video:"https://app.goodlives.in/videos/Guided Medidation.mp4",
    image:"https://ucarecdn.com/28de3448-1d86-462d-945d-eab9a178010c/meditation1.png"
  },
  {
    name:"Nostalgia",
    video:"https://app.goodlives.in/videos/Nostalgia.mp4",
    image:"https://ucarecdn.com/e9608a50-8707-4ffc-a86f-3812fc3a0072/meditation2.png"
  },
  {
    name:"Positive Vibes",
    video:"https://app.goodlives.in/videos/Positive Vibes.mp4",
    image:"https://ucarecdn.com/09c70eb0-79e1-437c-affe-a93e7bd70c34/meditation3.png"
  },
  {
    name:"Calm Your Mind",
    video:"https://app.goodlives.in/videos/Calm Your Mind.mp4",
    image:"https://ucarecdn.com/2a3f5259-2d42-493b-b3cd-f58a2c638dd6/meditation4.png"
  },
  {
    name:"Connecting to Innerself",
    video:"https://app.goodlives.in/videos/Connecting to Innerself.mp4",
    image:"https://ucarecdn.com/28de3448-1d86-462d-945d-eab9a178010c/meditation1.png"
  },
  {
    name:"Birds Chirping",
    video:"https://app.goodlives.in/videos/Birds Chirping.mp4",
    image:"https://ucarecdn.com/e9608a50-8707-4ffc-a86f-3812fc3a0072/meditation2.png"
  },
  {
    name:"Connecting to Nature",
    video:"https://app.goodlives.in/videos/Connecting to Nature.mp4",
    image:"https://ucarecdn.com/09c70eb0-79e1-437c-affe-a93e7bd70c34/meditation3.png"
  },
  {
    name:"Finding Hope",
    video:"https://app.goodlives.in/videos/Finding Hope.mp4",
    image:"https://ucarecdn.com/2a3f5259-2d42-493b-b3cd-f58a2c638dd6/meditation4.png"
  },
  {
    name:"Heal Yourself",
    video:"https://app.goodlives.in/videos/Heal Yourself.mp4",
    image:"https://ucarecdn.com/28de3448-1d86-462d-945d-eab9a178010c/meditation1.png"
  },
  {
    name:"Rain & Thunder",
    video:"https://app.goodlives.in/videos/Rain & Thunder.mp4",
    image:"https://ucarecdn.com/e9608a50-8707-4ffc-a86f-3812fc3a0072/meditation2.png"
  },
  {
    name:"Riverside",
    video:"https://app.goodlives.in/videos/Riverside.mp4",
    image:"https://ucarecdn.com/09c70eb0-79e1-437c-affe-a93e7bd70c34/meditation3.png"
  },
  {
    name:"Waves of Ocean",
    video:"https://app.goodlives.in/videos/Waves of Ocean.mp4",
    image:"https://ucarecdn.com/2a3f5259-2d42-493b-b3cd-f58a2c638dd6/meditation4.png"
  }
]
const Mindfulness = () => {
  const userInfo = useUserInfoSelector();
  return (
    <div className="dashboardofboxes">
      <p className="dashboard-heading">Hey {userInfo.fullName}, welcome back!</p>
      <div className="container-dpu">
        {mindfulness.map((item, index) => {
          const imgno = item.image.substring(item.image.length - 5, item.image.length - 4);
          return (
            <div
              key={index}
              className="worker-dpus"
              onClick={() => window.open(item.video, "_blank")}
            >
              <div className={`inner-container-dbp img${imgno}`}>
                <div className="img-idp-main">
                  <img src={item.image} alt={item.name} />
                </div>
                <p>{item.name}</p>
              </div>
            </div>
          );
      
        }
        )}
      </div>
    </div>
  );
};

export default Mindfulness;
