import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
// import { AreaChart, Area, XAxis, Tooltip,  ResponsiveContainer} from "recharts"

// const data = [
//     {
//         name: 'Page A',
//         uv: 4000,
//         pv: 2400,
//         amt: 2400,
//     },
//     {
//         name: 'Page B',
//         uv: 3000,
//         pv: 1398,
//         amt: 2210,
//     },
//     {
//         name: 'Page C',
//         uv: 2000,
//         pv: 9800,
//         amt: 2290,
//     },
//     {
//         name: 'Page D',
//         uv: 2780,
//         pv: 3908,
//         amt: 2000,
//     },
//     {
//         name: 'Page E',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181,
//     },
//     {
//         name: 'Page F',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Page G',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
// ];

export const UsersAreaChart = (props) => {
  return (
    <ResponsiveContainer width="100%" className="user-area-chart">
      <BarChart
        width={500}
        height={300}
        data={props.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Bar name="logins" dataKey="uv" fill="#379E90" />
      </BarChart>
      {/* <AreaChart
        width={500}
        height={400}
        data={props.data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      > */}
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      {/* <XAxis dataKey='name' /> */}
      {/* <YAxis /> */}
      {/* <Tooltip />/
        <Area
          type='monotone'
          dataKey='pv'
          stackId='1'
          stroke='#F98733'
          fill='#FFE9EE'
        />
        <Area
          type='monotone'
          dataKey='uv'
          stackId='1'
          stroke='#7947F7'
          fill='transparent'
        />
        {/* <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" /> */}
      {/* </AreaChart>   */}
    </ResponsiveContainer>
  );
};

export default UsersAreaChart;
