import axios from "axios"
import users from "assets/JSONs/HRDemoData/users.json";
import session from "assets/JSONs/HRDemoData/session.json";
import hr_overview from "assets/JSONs/HRDemoData/hr_overview.json";
import store from "../store/store";

export const loadAllTherapyTypes = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/therapyTypes",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const loadAllFeatures = async (token) =>
  await axios.get(process.env.REACT_APP_SERVER_URI + "/api/sendAllFeatures", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const loadAllProblemTypes = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/problemTypes",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const loadAllAvatars = async () =>
  await axios.get(process.env.REACT_APP_SERVER_URI + "/api/avatar")






export const saveaftercallratingapi = async (data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/aftercallrating",
    data
  )

export const fetchTherapistBookings = async ({ type="", page="", month="", year="", timeOffset=0 }, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + `/api/bookings/therapist?type=${type}&page=${page}&month=${month}&year=${year}&timeOffset=${timeOffset}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchUserBookings = async ({ month="", year="", timeOffset=0 }, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + `/api/bookings/user?month=${month}&year=${year}&timeOffset=${timeOffset}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const loadAllPaymentPlans = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/paymentPlans",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const walletSessionsInfo = async (token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/walletSessionsInfo",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const getRepeatUsers = async (token, adminCorporateId, department, location, gender) =>
{
  if(store.getState().user.isDemoUser){
    const repeatUsers= users.getRepeatUsers;
    let result = [
      {"name":"Jan","uv":0},
      {"name":"Feb","uv":0},
      {"name":"Mar","uv":0},
      {"name":"Apr","uv":0},
      {"name":"May","uv":0},
      {"name":"Jun","uv":0},
      {"name":"Jul","uv":0},
      {"name":"Aug","uv":0},
      {"name":"Sep","uv":0},
      {"name":"Oct","uv":0},
      {"name":"Nov","uv":0},
      {"name":"Dec","uv":0}
    ];
    for(let i=0;i<12;i++){
      // result[i]["uv"]=repeatUsers[department]["monthlyLoginData"][i]["uv"]+repeatUsers[location]["monthlyLoginData"][i]["uv"]+repeatUsers[gender]["monthlyLoginData"][i]["uv"];
      result[i]["uv"]=repeatUsers["monthlyLoginData"][i]["uv"]
    }
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    result=result.slice(0, currentMonth + 1);
    return { 
      data: {
        "monthlyLoginData":result,
        "currMonthSessionCount": 20,
        "uniqueUsers": 30,
        "sessionCountChange": 5,
        "uniqueUserChange": 10
      }
    }
  }
  else{
    try{
      const response = await axios.get(process.env.REACT_APP_SERVER_URI + "/api/repeatUsers/" + adminCorporateId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getClientUsers = async (token, adminCorporateId, department, location, gender) =>
{
  if(store.getState().user.isDemoUser){
    let clientUsers=users.getClientUsers;
    return {data:clientUsers}
  }
  else{
    try{
      const response = await axios.get(process.env.REACT_APP_SERVER_URI + "/api/clientUsers/" + adminCorporateId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getB2BSessionInfo = async (token, adminCorporateId) =>
  await axios.get(process.env.REACT_APP_SERVER_URI + "/api/B2BSessionInfo/" + adminCorporateId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  export const getB2BFilteredSessionInfo = async (token, adminCorporateId, department, location, gender) =>
  {
    if (store.getState().user.isDemoUser) {
      const sessionInfo = session.getB2BFilteredSessionInfo[gender].B2BSessionInfoData;
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
      if (!sessionInfo[currentYear].monthlySessionData) {
          sessionInfo[currentYear].monthlySessionData = months.map(month => ({ name: month, uv: 0 }));
      }
  
      let totalSessionAdjustment = 0;
      for (let i = currentMonth + 1; i < 12; i++) {
          const monthName = months[i];
          const currentYearData = sessionInfo[currentYear].monthlySessionData.find(month => month.name === monthName);
          const allTimeDataIndex = sessionInfo["All Time"].monthlySessionData.findIndex(month => month.name === monthName);
  
          if (currentYearData && allTimeDataIndex !== -1) {
              sessionInfo["All Time"].monthlySessionData[allTimeDataIndex].uv -= currentYearData.uv;
              totalSessionAdjustment += currentYearData.uv;
          }
      }
      sessionInfo["All Time"].totalSessions -= totalSessionAdjustment;
      
      if (sessionInfo[currentYear].monthlySessionData.length > 0) {
          sessionInfo[currentYear].monthlySessionData = sessionInfo[currentYear].monthlySessionData.slice(0, currentMonth + 1);
      }
  
      return {
          data: {
              "B2BSessionInfoData": sessionInfo,
              "yearsArray": [2023, 2024],
          }
      };
  }
  
  
    else{
      try{
        const response = await axios.get(process.env.REACT_APP_SERVER_URI + "/api/B2BSessionInfo/" + adminCorporateId + "?gender=" +  gender, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return response;
      } catch (e){
        console.log(error);
        throw error;
      }
    }
  }

export const getB2BFeedbacks = async (token, adminCorporateId, team, location, gender) =>
{
  if(store.getState().user.isDemoUser){
    const b2bFeedbacks = session.getB2BFeedbacks.feedbacks;
    // const result = b2bFeedbacks[team]["feedbacks"].concat(b2bFeedbacks[location]["feedbacks"],b2bFeedbacks[gender]["feedbacks"])
    return {
      data:{
        "feedbacks": b2bFeedbacks
      }
    }
  }
  else{
    try{
      const response = await axios.get(process.env.REACT_APP_SERVER_URI + "/api/B2BFeedbacks/" + adminCorporateId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getB2BMoodData = async (token, adminCorporateId) =>
{
  if(store.getState().user.isDemoUser){
    return {data:hr_overview.getB2BMoodData}
  }
  else{
    try{
      const response = await axios.get(process.env.REACT_APP_SERVER_URI + "/api/B2BMoodData/" + adminCorporateId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}

export const getBlogData = async (token) =>
  await axios.get(process.env.REACT_APP_SERVER_URI + "/api/blogData", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const getHomePageData = async () =>
  await axios.get(process.env.REACT_APP_SERVER_URI + "/api/homePageData")

export const submitContactFormData = async (data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/contactFormData",
    data
  )

export const submitEmailForNewsLetter = async (data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/emailForNewsLetter",
    data
  )

export const getMoodScoreData = async (token, adminCorporateId, department, location, gender) =>
{
  if(store.getState().user.isDemoUser){
    const moodScoreData= hr_overview.getMoodScoreData.moodData;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    moodScoreData[currentYear].moodScoreChartData=moodScoreData[currentYear].moodScoreChartData.slice(0, currentMonth + 1);
    return { 
      data: {
        "moodData":moodScoreData,
        "yearsArray":[2023,2024],
      }
    }
  }
  else{
    try{
      const response = await axios.get(process.env.REACT_APP_SERVER_URI + "/api/moodScoreData/" + adminCorporateId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response;
    } catch (e){
      console.log(error);
      throw error;
    }
  }
}
