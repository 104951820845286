import React, { useState, useEffect } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { cancelSlot, postFeedback, sendReminder, setTaskComplete } from "../../actions/bookSlot";
import "./index.css";
import { BsInfoCircle } from "react-icons/bs";
import Alert from "@material-ui/lab/Alert";
import { Tooltip } from "antd";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Rating from "@material-ui/lab/Rating";
import CheckIcon from "@material-ui/icons/Check";
import Snackbar from "@material-ui/core/Snackbar";
import { Rate } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import SessionFeedbackPopup from "../../components/SessionFeedbackPopup/SessionFeedbackPopup";
import { fetchUserBookings } from "actions/dataLoad";

const monthOptions = [
  { num: 1, name: "Jan", fullName: "January" },
  { num: 2, name: "Feb", fullName: "February" },
  { num: 3, name: "Mar", fullName: "March" },
  { num: 4, name: "Apr", fullName: "April" },
  { num: 5, name: "May", fullName: "May" },
  { num: 6, name: "June", fullName: "June" },
  { num: 7, name: "July", fullName: "July" },
  { num: 8, name: "Aug", fullName: "August" },
  { num: 9, name: "Sep", fullName: "September" },
  { num: 10, name: "Oct", fullName: "October" },
  { num: 11, name: "Nov", fullName: "November" },
  { num: 12, name: "Dec", fullName: "December" },
];

export const UserBookings = (props) => {
  const [cancelPopup, setCancelPopup] = useState(false);
  const [reschedulePopup, setReschedulePopup] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDateSavings, setCurrentDateSavings] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState("");
  // const [meetingid, setstateformeetid] = useState("")
  const [selectedYear, setSelectedYear] = useState("2021");
  const [freeAllowedSessionReschedules, setFreeAllowedSessionReschedules] = useState(0);
  const [isFreecancellationAvailable, setIsFreecancellationAvailable] = useState(false);
  const [isOutsideModTime, setIsOutsideModTime] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);
  const [elementId, setElementId] = useState("");
  const [therapistId, setTherapistId] = useState("");
  const [meetId, setmeetId] = useState("");
  const [firstBookingId, setFirstBookingId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [completedBookings, setCompletedBookings] = useState([]);
  const userToken = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const [sessionFeedbackPopupState, setSessionFeedbackPopupState] = useState({ show: false, data: {} });  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  //loads all the therapy bookings of the user
  const getAllBookings = async (monthNumber) => {
    try {
      dispatch(startLoading());
      const res = await fetchUserBookings(
        {
          month: monthNumber,
          timeOffset: new Date().getTimezoneOffset(),
        },
        userToken
      );
      const upcomingBookingsData = res.data["upcoming"];
      const completedBookingsData = res.data["completed"];

      let i = 0;
      for (const booking of completedBookingsData) {
        if (!booking.isCancel && i < 2) {
          booking.showRecommendation = true;
          i++;
        } else {
          booking.showRecommendation = false;
        }
      }

      setUpcomingBookings(upcomingBookingsData);
      setCompletedBookings(completedBookingsData);
      setFirstBookingId(res.data.firstBookingId);
      setFreeAllowedSessionReschedules(res.data.freeAllowedSessionReschedules);
      setIsFreecancellationAvailable(res.data.isFreecancellationAvailable);
    } catch (err) {
      showError(err?.response?.data?.error);
    } finally {
      dispatch(stopLoading());
    }
  };

  const sendRating = async (bookingID, rating, therapistID) => {
    try {
      dispatch(startLoading());
      await postFeedback({ bookingID, rating, therapistID }, userToken);
      await getAllBookings(selectedMonth);
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const cancelBookingSlot = async () => {
    if (bookingId && therapistId) {
      let bookingID = bookingId;
      let therapistID = therapistId;
      try {
        const data = {
          bookingID,
          therapistID,
        };
        dispatch(startLoading());
        //returns a message
        const res = await cancelSlot(data, userToken);
        if (res.data.isCancel) {
          setCancelPopup(false);
          await getAllBookings(selectedMonth);
        } else {
          setCancelPopup(false);
          showError(res.data.error);
        }
        setBookingId("");
        // setmeetId("");

        setTherapistId("");
        dispatch(stopLoading());
      } catch (err) {
        dispatch(stopLoading());
        setBookingId("");
        // setmeetId("");
        setTherapistId("");
        setCancelPopup(false);
        if (err.response.data.redirectToSignIn) {
          showError(err.response.data.error);
          setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
        } else {
          showError(err.response.data.error);
        }
      }
    }
  };

  const handleSendReminder = async (mess, bookingID, i) => {
    try {
      dispatch(startLoading());
      await sendReminder({ bookingID, message: mess }, userToken);
      let element = `reminderpopup${i}`;
      document.getElementById(element).style.opacity = 0;
      document.getElementById(element).style.pointerEvents = "none";
      setShowOverlay(false);
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      let element = `reminderpopup${i}`;
      document.getElementById(element).style.opacity = 0;
      document.getElementById(element).style.pointerEvents = "none";
      setShowOverlay(false);
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  useEffect(() => {
    let today = new Date();
    setCurrentDate(today);
    setCurrentDateSavings(today);
    setSelectedMonth(today.getMonth() + 1);
    getAllBookings();
  }, []);

  const getMonthName = (i) => {
    i = Number(i) + 1;
    if (i === 1) {
      return "Jan";
    } else if (i === 2) {
      return "Feb";
    } else if (i === 3) {
      return "Mar";
    } else if (i === 4) {
      return "Apr";
    } else if (i === 5) {
      return "May";
    } else if (i === 6) {
      return "June";
    } else if (i === 7) {
      return "July";
    } else if (i === 8) {
      return "Aug";
    } else if (i === 9) {
      return "Sep";
    } else if (i === 10) {
      return "Oct";
    } else if (i === 11) {
      return "Nov";
    } else if (i === 12) {
      return "Dec";
    }
  };

  const getMonthNumber = (i) => {
    if (i === "Jan") {
      return 0;
    } else if (i === "Feb") {
      return 1;
    } else if (i === "Mar") {
      return 2;
    } else if (i === "Apr") {
      return 3;
    } else if (i === "May") {
      return 4;
    } else if (i === "June") {
      return 5;
    } else if (i === "July") {
      return 6;
    } else if (i === "Aug") {
      return 7;
    } else if (i === "Sep") {
      return 8;
    } else if (i === "Oct") {
      return 9;
    } else if (i === "Nov") {
      return 10;
    } else if (i === "Dec") {
      return 11;
    }
  };

  function capitalize(input) {
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(" ");
  }

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  const checkUpcomingForSelectedMonth = (upcomingBookings, selectedMonth) => {
    if (upcomingBookings.length === 0 || getMonthNumber(selectedMonth) < new Date().getMonth()) {
      return false;
    } else {
      let A = upcomingBookings.filter((b) => {
        let { sessionTime } = b.session;
        let { start } = sessionTime;
        let month = new Date(start).getMonth();
        return getMonthName(month) === selectedMonth;
      });
      if (A.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const checkCompletedForSelectedMonth = (completedBookings, selectedMonth) => {
    if (completedBookings.length === 0 || getMonthNumber(selectedMonth) > new Date().getMonth()) {
      return false;
    } else {
      let A = completedBookings.filter((b) => {
        let { sessionTime } = b.session;
        let { start, end } = sessionTime;
        let month = new Date(start).getMonth();
        return getMonthName(month) === selectedMonth;
      });
      if (A.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  };


  const sendTaskDone = async (taskId, bookingId) => {
    try {
      dispatch(startLoading());
      const data = { taskId, bookingId };
      await setTaskComplete(data, userToken);
      await getAllBookings(selectedMonth);
      dispatch(stopLoading());
    } catch (err) {
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };
  const handleSessionFeedbackSubmit = async ({ rating, sessionId, therapistId, message, comments }) => {
    try {
      setSessionFeedbackPopupState({ show: false, data: {} });
      dispatch(startLoading());
      await postFeedback({ rating, bookingID: sessionId, therapistID: therapistId, message, comments }, userToken);
      await getAllBookings(selectedMonth);
      dispatch(stopLoading());
    }
    catch (err) {
      showError("An error occurred!")
    }
  };
  const handleRating = ({ therapistName, therapistId, sessionId }) => {
    setSessionFeedbackPopupState({ show: true, data: { therapistName, therapistId, sessionId } })
  }
  const handleMonthSelect = async (monthNumber) => {
    dispatch(startLoading());
    await getAllBookings(monthNumber);
    setSelectedMonth(monthNumber);
    dispatch(stopLoading());
  };

  const handleCancelBtnClick = (meetid, therapistID, bookingId, outsideModTime) => {
    setCancelPopup(true);
    setBookingId(bookingId);
    setTherapistId(therapistID);
    setmeetId(meetid);
    setIsOutsideModTime(outsideModTime);
  };

  const rescheduleSession = (bookingId, therapistId) => {
    history.push({ pathname: "/rescheduleSession", state: { therapistID: therapistId, bookingID: bookingId }});
  }

  return (
    <div className="dashboardofboxes scheduledashboard">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <p className="dashboard-heading">Hey {userInfo.fullName}, welcome back!</p>
      <div className="containers-dashboardboxed">
        <div className="left-scd">
          <div className="graybuttons sessions-month-select">
            <select
              value={selectedMonth}
              onChange={(e) => {
                handleMonthSelect(e.target.value);
              }}
            >
              {monthOptions.map((month) => (
                <option value={month.num}>{month.fullName}</option>
              ))}
            </select>
          </div>
          <div className="top-lscd" style={{ justifyContent: "space-between", maxWidth: 850 }}>
            {upcomingBookings.length > 0 && <p className="main-title-lscd">Upcoming 1 on 1 sessions</p>}
          </div>
          {upcomingBookings.length > 0 &&
            upcomingBookings.map((booking, i) => {
              const { userName, isCancel, sessionTime, _id, reschedule, therapistID, orderID, meetid } =
                booking;
              let { start, end } = sessionTime;
              let timeDifference = new Date(start).getTime() - new Date().getTime();
              let timeDifferenceEnd = new Date(end).getTime() - new Date().getTime();
              let date = new Date(start).getDate();
              let month = new Date(start).getMonth();
              start = new Date(start).toLocaleTimeString();
              start = start.replace(":00", "");
              end = new Date(end).toLocaleTimeString();
              end = end.replace(":00", "");

              //what I know is, there are a total of three booking states - completed, upcoming, cancelled
              let in_progress =
                !isCancel && timeDifference <= 900000 && timeDifferenceEnd >= 0;
              let canBeRescheduled = reschedule < freeAllowedSessionReschedules && timeDifference > 3600000 * 10 ? true : timeDifference > 0 ? isFreecancellationAvailable : false;
              const canBeCancelled = timeDifference > 0;

              return (
                <div className="box-clscd" key={_id}>
                  <div className="container-lscd upcoming-sessions-row">
                    <div className="date-clscd therapist-session-date">
                      <p>{date}</p>
                      <p>{getMonthName(month)}</p>
                    </div>
                    <div className="content-clscd">
                      {firstBookingId === _id ? (
                        <p className="title-cclscd">
                          Welcome to session 1 | GoodLives
                          <br />
                          <div style={{ fontSize: "11px", fontWeight: "400" }}>
                            <span style={{ fontWeight: 500 }}>Order ID:</span> {orderID}
                          </div>
                        </p>
                      ) : (
                        <p className="title-cclscd">
                          Making life good for {capitalize(userName.split(" ")[0])}
                          <br />
                          <div style={{ fontSize: "11px", fontWeight: "400" }}>
                            <span style={{ fontWeight: 500 }}>Order ID:</span> {orderID}
                          </div>
                        </p>
                      )}
                      <div className="inline-clscd">
                        <p>
                          {start}-{end}
                        </p>
                      </div>
                    </div>
                    {!isCancel ? (
                      <div className="btns-workinline booking-action-btns">
                        {canBeRescheduled && (
                          <p>
                            <button
                              onClick={() => {
                                if (timeDifference <= 3600000 * 10){
                                  setBookingId(_id);
                                  setTherapistId(therapistID);
                                  setReschedulePopup(true);
                                }
                                else {
                                  rescheduleSession(_id, therapistID);
                                }
                              }}
                              style={{
                                color: "inherit",
                                textDecoration: "none",
                                border: "0px",
                                background: "transparent",
                                padding: "1px"
                              }}
                            >
                              Reschedule{" "}
                              <Tooltip
                                placement="bottomLeft"
                                title="You can only reschedule a session 10 hours prior to the start time of session."
                                color="#e8e8e8"
                                overlayInnerStyle={{
                                  borderRadius: "10px",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#000",
                                  width: "300px",
                                }}
                              >
                                <BsInfoCircle style={{ marginLeft: "4px" }} size="13" />
                              </Tooltip>
                            </button>
                          </p>
                        )}
                        {in_progress && (
                          <>
                            <div style={{ position: "relative" }}>
                              <p>
                                Send Reminder{" "}
                                <div
                                  className="dropdowntsb"
                                  style={{ marginLeft: 5, marginRight: 15 }}
                                  onClick={(e) => {
                                    let element = document.getElementById(`reminderpopup${i}`);
                                    const top = e.clientY + 10;
                                    const right = window.innerWidth - e.clientX;
                                    if (element.style.display === "none") {
                                      element.style.display = "block";
                                      element.style.pointerEvents = "all";
                                      element.style.top = top + "px";
                                      element.style.right = right + "px";
                                      setShowOverlay(true);
                                      setElementId(element);
                                    } else {
                                      element.style.display = "none";
                                      element.style.pointerEvents = "none";
                                      setShowOverlay(false);
                                      setElementId(null);
                                    }
                                  }}
                                >
                                  <KeyboardArrowDownIcon />
                                </div>{" "}
                                <Tooltip
                                  placement="bottomLeft"
                                  title="You can send a notification to your therapist in case you’re running late for the session, your thearpist didn’t join, etc."
                                  color="#e8e8e8"
                                  overlayInnerStyle={{
                                    borderRadius: "10px",
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    color: "#000",
                                    width: "300px",
                                  }}
                                >
                                  <BsInfoCircle style={{ marginLeft: "4px" }} size="13" />
                                </Tooltip>
                              </p>
                              <div
                                className="inner-notificationboxmain notificationsboxreminder"
                                id={`reminderpopup${i}`}
                                style={{ display: "none", position: "fixed", pointerEvents: "none" }}
                              >
                                <div className="top-inbm">
                                  <p>Set reminder</p>
                                </div>
                                <div className="bottom-inbm">
                                  <ol>
                                    <li
                                      onClick={(e) =>
                                        handleSendReminder(
                                          "Hey, I've been waiting for you on the call. Please join.",
                                          _id,
                                          i
                                        )
                                      }
                                    >
                                      1. Hey, I've been waiting for you on the call. Please join.{" "}
                                    </li>
                                    <li
                                      onClick={(e) =>
                                        handleSendReminder(
                                          "I won't be able to join, stuck somewhere.",
                                          _id,
                                          i
                                        )
                                      }
                                    >
                                      2. I won't be able to join, stuck somewhere.{" "}
                                    </li>
                                    <li
                                      onClick={(e) =>
                                        handleSendReminder("I'm running late, please wait for me", _id, i)
                                      }
                                    >
                                      3. I'm running late, please wait for me
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </div>
                            <Link
                              to={{
                                pathname: "/session/" + meetid,
                                state: {
                                  therapistID: therapistID,
                                  bookingID: _id,
                                },
                              }}
                            >
                              Join Now
                            </Link>
                          </>
                        )}

                        { canBeCancelled && (
                          <p
                          onClick={() => handleCancelBtnClick(meetid, therapistID, _id, timeDifference < 3600000 * 10)}
                          >
                            Cancel{" "}
                            <Tooltip
                              placement="bottomLeft"
                              title="Free cancellation is allowed only if you cancel 10 hrs before the session start time. To ease the process, we've made the first cancellation free for You."
                              color="#e8e8e8"
                              overlayInnerStyle={{
                                borderRadius: "10px",
                                fontSize: "10px",
                                color: "#000",
                                fontWeight: "bold",
                                maxWidth: "250px",
                              }}
                            >
                              <BsInfoCircle style={{ marginLeft: "4px" }} size="13" />
                            </Tooltip>
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="btns-workinline">
                        <p
                          onClick={() => {
                            setCancelPopup(false);
                          }}
                        >
                          Cancelled{" "}
                          <Tooltip
                            placement="bottomLeft"
                            title="Free cancellation is allowed only if you cancel 24 hrs before the session start time. To ease the process, we've made the first cancellation free for You."
                            color="#e8e8e8"
                            overlayInnerStyle={{
                              borderRadius: "10px",
                              fontSize: "10px",
                              color: "#000",
                              fontWeight: "bold",
                              maxWidth: "250px",
                            }}
                          >
                            <BsInfoCircle style={{ marginLeft: "4px" }} size="13" />
                          </Tooltip>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          {completedBookings.length > 0 && (
            <p className="main-title-lscd completed-header">Completed sessions</p>
          )}

          {completedBookings.length > 0 &&
            completedBookings.map((booking, i) => {
              const {
                sessionTime,
                _id,
                isCancel,
                userName,
                Rating: StarRating,
                orderID,
                therapistID,
                CaseNotes = "",
                Tasks = [],
              } = booking;
              let { start, end } = sessionTime;
              let timeDifference = new Date(start).getTime() - new Date().getTime();
              let date = new Date(start).getDate();
              let month = new Date(start).getMonth();
              start = new Date(start).toLocaleTimeString();
              start = start.replace(":00", "");
              end = new Date(end).toLocaleTimeString();
              end = end.replace(":00", "");
              const { showRecommendation } = booking;

              return (
                <div className="box-clscd" key={_id}>
                  <div
                    className="container-lscd completed-sessions-row"
                    style={{
                      height: showRecommendation && window.innerWidth > 768 ? 350 : showRecommendation && 600,
                      paddingTop: showRecommendation && 30,
                      alignItems: showRecommendation && "flex-start",
                    }}
                    onClick={(e) => {}}
                  >
                    <div className="date-clscd therapist-session-date">
                      <p>{date}</p>
                      <p>{getMonthName(month)}</p>
                    </div>
                    <div className="content-clscd">
                      {firstBookingId === _id ? (
                        <p className="title-cclscd">
                          Welcome to session 1 | GoodLives
                          <br />
                          <div style={{ fontSize: "11px", fontWeight: "400" }}>
                            <span style={{ fontWeight: 500 }}>Order ID:</span> {orderID}
                          </div>
                        </p>
                      ) : (
                        <p className="title-cclscd">
                          Making life good for {capitalize(userName.split(" ")[0])}
                          <br />
                          <div style={{ fontSize: "11px", fontWeight: "400" }}>
                            <span style={{ fontWeight: 500 }}>Order ID:</span> {orderID}
                          </div>
                        </p>
                      )}
                      <div className="inline-clscd">
                        <p>
                          {start}-{end}
                        </p>
                      </div>
                    </div>
                    {!isCancel ? (
                      <div className="btns-workinline" style={{ bottom: showRecommendation ? 300 : 15 }}>
                        {StarRating && StarRating !== 0 ? (
                          <Rate disabled value={StarRating} />
                        ) : (
                          <button onClick={() => { handleRating({ therapistName: booking.therapistName, therapistId: booking.therapistID, sessionId: booking._id }) }} className="rating-btn">
                              Rate this Session
                          </button>

                        )}
                        {/*<Rating
                            name={
                              StarRating === 0
                                ? "simple-controlled"
                                : "disabled"
                            }
                            disabled={StarRating === 0 ? false : true}
                            value={StarRating}
                            onChange={(event, newValue) => {
                              sendRating(_id, newValue, therapistID)
                            }}
                          />*/}
                      </div>
                    ) : (
                      <div className="btns-workinline">
                        <p
                          onClick={() => {
                            setCancelPopup(false);
                          }}
                        >
                          Cancelled{" "}
                          <Tooltip
                            placement="bottomLeft"
                            title="Free cancellation is allowed only if you cancel 24 hrs before the session start time. To ease the process, we've made the first cancellation free for You."
                            color="#e8e8e8"
                            overlayInnerStyle={{
                              borderRadius: "10px",
                              fontSize: "10px",
                              color: "#000",
                              fontWeight: "bold",
                              maxWidth: "250px",
                            }}
                          >
                            <BsInfoCircle style={{ marginLeft: "4px" }} size="13" />
                          </Tooltip>
                        </p>
                      </div>
                    )}
                    {showRecommendation && (
                      <Recommendations tasks={Tasks} sendTaskDone={sendTaskDone} bookingID={_id} />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Dialog
        open={cancelPopup}
        onClose={() => setCancelPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 999999999 }}
      >
        {/* <DialogTitle id="alert-dialog-title">LET OP</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel this session? {
              isOutsideModTime && (
                isFreecancellationAvailable ? "You'll be charged next time if you cancel within 10 hours of session start time"
                : (
                  userInfo.categoryId === 1 ? "This is a paid cancellation."
                  : "Your company will still be charged for this session"
                )
              )
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelPopup(false)} color="primary">
            No
          </Button>
          <Button onClick={() => cancelBookingSlot()} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={reschedulePopup}
        onClose={() => {
          setBookingId("");
          setTherapistId("");
          setReschedulePopup(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 999999999 }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to reschedule this session? You will not be allowed to reschedule session within 10 hours in future.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setBookingId("");
              setTherapistId("");
              setReschedulePopup(false);
            }} 
            color="primary"
          >
            No
          </Button>
          <Button onClick={() => rescheduleSession(bookingId, therapistId)} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {showOverlay && (
        <div
          className="popup-payment-alert-overaly"
          style={{ backgroundColor: "transparent", zIndex: 9997 }}
          onClick={() => {
            document.getElementById(elementId).style.opacity = 0;
            document.getElementById(elementId).style.pointerEvents = "none";
            //this.setState({ showOverlay: false, elementId: "" })
            setShowOverlay(false);
            setElementId("");
          }}
        ></div>
      )}
      <SessionFeedbackPopup
        isOpen={sessionFeedbackPopupState.show}
        onSessionRatingSubmit={handleSessionFeedbackSubmit}
        sessionData={sessionFeedbackPopupState.data}
      />
    </div>
  );
};

const Recommendations = ({ tasks, sendTaskDone, bookingID }) => {
  const [rec1, setRec1] = useState(false);
  const [rec2, setRec2] = useState(false);
  const [rec3, setRec3] = useState(false);
  const [rec1Text, setRec1Text] = useState("Task 1 Not Assigned.");
  const [rec2Text, setRec2Text] = useState("Task 2 Not Assigned.");
  const [rec3Text, setRec3Text] = useState("Task 3 Not Assigned.");
  const [dR1, setDR1] = useState(false);
  const [dR2, setDR2] = useState(false);
  const [dR3, setDR3] = useState(false);

  useEffect(() => {
    if (tasks[0]) {
      setRec1Text(tasks[0].Task);
      if (tasks[0].CompletedByUser) {
        setRec1(true);
        setDR1(true);
      }
    } else {
      setDR1(true);
    }
    if (tasks[1]) {
      setRec2Text(tasks[1].Task);
      if (tasks[1].CompletedByUser) {
        setRec2(true);
        setDR2(true);
      }
    } else {
      setDR2(true);
    }
    if (tasks[2]) {
      setRec3Text(tasks[2].Task);
      if (tasks[2].CompletedByUser) {
        setRec3(true);
        setDR3(true);
      }
    } else {
      setDR3(true);
    }
  }, []);

  return (
    <div className="select-therapist-recommendations">
      <p className="title-str">Your Therapist's Recommendations</p>
      <div
        className="select-box-lcb"
        onClick={() => {
          if (!dR1) {
            setRec1((prev) => !prev);
            if (tasks[0]) {
              sendTaskDone(tasks[0]._id, bookingID);
              setDR1(1);
            }
          }
        }}
      >
        <div className={rec1 ? `top-sblcb-completed` : `top-sblcb`}>
          {rec1 ? (
            <div className="checkboxtsb">
              <CheckIcon />
            </div>
          ) : (
            <div className="grayboxtsb"></div>
          )}
          <p>{rec1Text}</p>
        </div>
      </div>
      <div
        className="select-box-lcb"
        style={{ cursor: dR2 ? "text" : "pointer" }}
        onClick={() => {
          if (!dR2) {
            setRec2((prev) => !prev);
            if (tasks[1]) {
              sendTaskDone(tasks[1]._id, bookingID);
              setDR2(true);
            }
          }
        }}
      >
        <div className={rec2 ? `top-sblcb-completed` : `top-sblcb`}>
          {rec2 ? (
            <div className="checkboxtsb">
              <CheckIcon />
            </div>
          ) : (
            <div className="grayboxtsb"></div>
          )}
          <p>{rec2Text}</p>
        </div>
      </div>
      <div
        className="select-box-lcb"
        onClick={() => {
          if (!dR3) {
            setRec3((prev) => !prev);
            if (tasks[2]) {
              sendTaskDone(tasks[2]._id, bookingID);
              setDR3(true);
            }
          }
        }}
      >
        <div className={rec3 ? `top-sblcb-completed` : `top-sblcb`}>
          {rec3 ? (
            <div className="checkboxtsb">
              <CheckIcon />
            </div>
          ) : (
            <div className="grayboxtsb"></div>
          )}
          <p>{rec3Text}</p>
        </div>
      </div>
    </div>
  );
};

export default UserBookings;
