import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActionArea,
  CardActions,
  Snackbar,
  Grid,
} from "@material-ui/core";
import { getCurrentWorkshops, joinWorkshop, addWorkshop } from "../../actions/workshop";
import Alert from "@material-ui/lab/Alert";
import "./index.css";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import axios from "axios"; // Import axios for making API calls

const Workshops = (props) => {
  const [currentWorkshops, setCurrentWorkshops] = useState([]);
  const userToken = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const [open, setOpen] = useState(false);
  const [workshopStyle, setWorkshopStyle] = useState({
    backgroundColor: "",
  });
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const dispatch = useDispatch();

  const showMessage = (text, severity) => {
    setMessage(text);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  const getWorkshops = async () => {
    try {
      dispatch(startLoading());
      const date = new Date();
      const res = await getCurrentWorkshops(userToken, date);
      setCurrentWorkshops(res.data.workshops);
      dispatch(stopLoading());
    } catch (error) {
      dispatch(stopLoading());
      showMessage(error.response.data.error, "error");
    }
  };

  const handleJoinWorkshop = async (workshopId, workshopLink) => {
    try {
      window.open(workshopLink, "_blank");
    } catch (error) {
      showMessage(error.response.data.error, "error");
    }
  };

  const handleAddToCalendar = async (workshopId) => {
    try {
      await addWorkshop(userToken, { workshopId });
      showMessage("Workshop added to calendar successfully!", "success");
    } catch (error) {
      console.warn(error);
      showMessage(error.response?.data?.error, "error");
    }
  };

  useEffect(() => {
    getWorkshops();
  }, []);

  useEffect(() => {
    if (props.workshopId) {
      const el = document.getElementById(props.workshopId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
        el.classList.add("highlight");
        setTimeout(() => {
          el.classList.remove("highlight");
        }, 3000);
      }
    }
  }, [currentWorkshops]);

  return (
    <div className="dashboardofboxes">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <p className="dashboard-heading">Hey {userInfo.fullName}, welcome back!</p>
      <p class="main-title-lscd">Upcoming Workshops</p>
      <div className="workshops-cont">
        {currentWorkshops.length === 0 ? (
          <img
            src="https://ucarecdn.com/b9323070-8603-4d0e-acd5-7eab078accae/NoNewEvent.gif"
            className="no-events-img"
          />
        ) : (
          <>
            {currentWorkshops.map((workshop) => {
              let timeDifference = new Date(workshop.workshopStartDatetime).getTime() - new Date().getTime();
              let timeDifferenceEnd = new Date(workshop.workshopEndDatetime).getTime() - new Date().getTime();
              let in_progress = timeDifference <= 900000 && timeDifferenceEnd >= 0;

              return (
                <div className="workshop-card">
                  <img src={workshop.workshopImageUrl} className="workshop-card-img" alt="Grounding Exercise" />
                  <div className="workshop-card-content">
                    <p className="workshop-card-content-topic">{workshop.workshopTopic}</p>
                    <p>{workshop.workshopDescription}</p>
                    <p>
                      Starts At:
                      <br />
                      {new Date(workshop.workshopStartDatetime).toLocaleDateString("en-IN", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}{" "}
                      &nbsp; &nbsp;
                      {new Date(workshop.workshopStartDatetime).toLocaleTimeString("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </p>
                    <p>
                      Ends At:
                      <br />
                      {new Date(workshop.workshopEndDatetime).toLocaleDateString("en-IN", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}{" "}
                      &nbsp; &nbsp;
                      {new Date(workshop.workshopEndDatetime).toLocaleTimeString("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </p>
                  </div>
                  <div className="workshop-card-cta">
                    <button className="workshop-card-add" onClick={() => handleAddToCalendar(workshop._id)}>
                      Add to Calendar
                    </button>
                    {in_progress && (
                      <button
                        className="workshop-card-join"
                        onClick={() => handleJoinWorkshop(workshop._id, workshop.workshopLink)}
                      >
                        Join Now
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default Workshops;
