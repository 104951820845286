import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import { useEffect, useState } from "react";
import {
  getAssessment,
  getStressChartData,
  getMentalScore,
} from "../../actions/assessment";
import AssessmentGraph from "../../components/AssessmentGraph";
import Loading from "../../components/Utilities/Loading/Loading";
import StressChart from "../../components/StressChart";
import MentalHealthGraph from "../../components/MentalHealthGraph";
import { Progress, Steps } from "antd";
import { Select, MenuItem } from "@material-ui/core";
import Box from "@mui/material/Box";
import { getMentalHealthData } from "../../actions/assessment";
import { useUserTokenSelector } from "store/reducers";

const obj = {
  chartData: [
    { name: "FOMO", value: 0 },
    { name: "Occupational Burnout", value: 0 },
    { name: "Self Care", value: 0 },
    { name: "Mood Self", value: 0 },
    { name: "Workplace Inclusion", value: 0 },
    { name: "Self-Test for Anxiety", value: 0 },
    { name: "Digital Well-Being", value: 0 },
    { name: "PTSD Screening", value: 0 },
    { name: "Stress", value: 0 },
  ],
};

const teamArray = ["Sales","Product","Design"]
const locationArray = ["Pune","Bangalore"]

const Organizational = ({ adminCorporateId }) => {
  const userToken = useUserTokenSelector();
  const [loading, setLoading] = useState(false);
  const [assessmentData, setAssessmentData] = useState([]);
  const [stressData, setStressData] = useState([]);
  const [mentalScore, setMentalScore] = useState(0);
  // const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState("All Time");
  const [mentalHealthData, setMentalHealthData] = useState(obj.chartData);
  const [selectedDepartment, setSelectedDepartment] = useState("All Teams");
  const [selectedLocation, setSelectedLocation] = useState("All Locations");
  const [years, setYears] = useState();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedGender,setSelectedGender]=useState("Overall");
  const [teams, setTeams] = useState([]);
  const [locations, setLocations] = useState([]);

  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedMonth, setSelectedMonth] = useState(
    monthArray[currentMonth - 1]
  );
  const [dres, setDres] = useState({
    graphData: {},
    yearArray: [],
  });
  const monthsName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    getMentalHealth();
  }, [selectedYear, selectedLocation, selectedDepartment])

  useEffect(() => {
    const { graphData } = dres;
    if (selectedYear === "All Time"){
      const temp = "All Time";
      setMentalHealthData(
        graphData[temp]?.length > 0 ? graphData[temp] : obj.chartData
      );
    } else{
      setMentalHealthData(
        graphData[selectedMonth]?.length > 0
          ? graphData[selectedMonth]
          : obj.chartData
      );
    }
  }, [selectedMonth, dres]);

  const getMentalHealth = async () => {
    try {
      const res = await getMentalHealthData(
        userToken,
        adminCorporateId,
        selectedYear,
        selectedDepartment,
        selectedLocation,
        selectedGender
      )
      const yearArray = res.data.yearArray;
      const tempYears=yearArray.map((year)=>year);
      tempYears.push("All Time");
      tempYears.sort((a, b) => b - a);
      setYears(tempYears);
      const teamsData = teamArray;
      const teamsArray = teamsData.map((team) => team);
      teamsArray.push("All Teams");
      setTeams(teamsArray);
      const locationsData = locationArray;
      const locationsArray = locationsData.map((location) => location);
      locationsArray.push("All Locations");
      setLocations(locationsArray);
      setDres(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  function handleYearChange(event) {
    const nowSelectedYear = event.target.value;
    setSelectedYear(nowSelectedYear);
  }

  function handleMonthChange(event) {
    const nowSelectedMonth = event.target.value;
    setSelectedMonth(nowSelectedMonth || monthArray[currentMonth - 1]);
  }

  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  useEffect(() => {
    getStressData();
    calcMentalScore();
  }, []);

  const getStressData = async () => {
    try {
      setLoading(true);
      const res = await getStressChartData(userToken, adminCorporateId);
      setStressData(res.data);
      setLoading(false);
    } catch (error) {}
  };
  const calcMentalScore = async () => {
    try {
      // setLoading(true)
      const res = await getMentalScore(userToken, adminCorporateId);
      setMentalScore(res.data.overallHealthScore);
      // setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };
  return (
    // className='overview usersstuff'
    <div
      className="overview usersstuff"
    >
      {/* gender location and team filter */}
      {/* <div className="select-div">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h5 className="select-gender-heading">
            Select Filters{" "}
            <span style={{ fontSize: "0.75rem" }}>
              (it will work for all graphs){" "}
            </span>
          </h5>{" "}
        </div>
        <Box className="filters">
          <Select
            value={selectedGender}
            onChange={(event) => setSelectedGender(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value="Overall">Overall</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
            <Select
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
              label="Department"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200, // Set your desired max height
                  },
                },
              }}
            >
              {
                teams.map((team) => (
                  <MenuItem value={team}>{team}</MenuItem>
                ))
              }
            </Select>
            <Select
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              label="Location"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
            {
              locations.map((location) => (
                <MenuItem value={location}>{location}</MenuItem>
              ))
            }
          </Select>
        </Box>
      </div> */}
      <div className="top-overview">
        <div
          className="left-sectionstufftop"
          style={{
            height: "fit-content",
            width: "auto",
            padding:"20px 50px"
          }}
        >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems:"center",
                marginBottom: "20px",
                width:"100%",
              }}
            >
              <h3 className="title-topoverview" style={{ fontWeight: 600, fontSize:"1.5em" }}>
                Mental Health Assessment Submissions
              </h3>
              <div>
                {selectedYear !== "All Time" ? (
                  <Select
                    value={selectedMonth}
                    onChange={(e) => handleMonthChange(e)}
                    label="month"
                    style={{ marginRight: "1rem" }}
                  >
                    {monthsName.map((month, index) => {
                      if (index > new Date().getMonth() && selectedYear === new Date().getFullYear()) {
                        return null;
                      } else {
                        return (
                          <MenuItem key={index} value={month}>
                            {capitalizeFirstLetter(month)}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                ) : undefined}

                <Select
                  value={selectedYear}
                  onChange={(e) => handleYearChange(e)}
                  label="Year"
                >
                  {(years || []).map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <MentalHealthGraph mentalHealthData={mentalHealthData} />
          </div>
      </div>
      <div className="top-overview" style={{ display: "none" }}>
        <div
          className="left-sectionstufftop"
          style={{ borderRadius: "12px", height: "375px", width: "80%" }}
        >
          <div className="top-lsst">
            <h3 className="title-topoverview">
              Assessment Score for Organization
            </h3>
            <AssessmentGraph adminCorporateId={adminCorporateId} />
          </div>
        </div>
      </div>
      <div className="assessment-bottom">
        <div
          className="left-sectionstufftop"
          style={{
            marginTop: "50px",
            height: "fit-content",
            width: "fit-content",
            padding:"20px"
          }}
        >
          <div>
            <h3 className="title-topoverview" style={{ fontWeight: 600, fontSize:"1.5em" }}>
              Stress-o-Meter
            </h3>
            <StressChart data={stressData} />
          </div>
        </div>
        <div
          className="left-sectionstufftop"
          style={{
            marginTop: "50px",
            height: "fit-content",
            width:"auto",
            padding:"20px",
          }}
        >
          <div className="top-lsst">
            <h3 className="title-topoverview" style={{ fontWeight: 600, fontSize:"1.5em" }}>
              Overall Mental Health Score
            </h3>
            <div className="right-cbc health-score">
              <div className="circle-rcbc">
                <Progress
                  type="circle"
                  percent={mentalScore}
                  format={(percent) => `${percent}/100`}
                  strokeWidth={10}
                  strokeColor="#F9CE55"
                />
                {mentalScore >= 0 && mentalScore <= 50 && (
                  <p>Severe, need to improve</p>
                )}
                {mentalScore >= 51 && mentalScore <= 75 && (
                  <p>Can be improved</p>
                )}
                {mentalScore >= 76 && mentalScore <= 100 && (
                  <p>Healthy workforce</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizational;
