import React, { Component } from "react";
import "./index.css";
import ListAltIcon from "@material-ui/icons/ListAlt";
import BarChart from "@material-ui/icons/BarChart";
import EventAvailable from "@material-ui/icons/EventAvailable";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import MoodIcon from "@material-ui/icons/Mood";
import Apps from "@material-ui/icons/Apps";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import PhoneIcon from "@material-ui/icons/Phone";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import { Link } from "react-router-dom";
import {
  ASSESSMENTS,
  MINDFULNESS,
  MOOD_TRACKER,
  MY_BOOKINGS,
  OVERVIEW,
  SUPPORT,
  WALLET,
  WORKSHOPS,
} from "./UserDashboard";

export class LeftMenuB2B extends Component {
  componentDidMount() {
    if (this.props.bydefaultOnBooking) {
      this.setState({ overviewActive: false, sessionsActive: true });
    }
  }

  render() {
    return (
      <div className="left-menustuff">
        <p className="title-lms">
          GoodLives<sup style={{ fontSize: "14px" }}>TM</sup>
        </p>
        <div className="content-lms">
          <ul>
            <li
              className={`${this.props.selectedTab === OVERVIEW ? "active" : ""}`}
              onClick={() => {
                this.props.showOverView();
              }}
            >
              <ListAltIcon /> Overview
            </li>
            <li
              className={`${this.props.selectedTab === MOOD_TRACKER ? "active" : ""}`}
              onClick={() => {
                this.props.showMoodTracker();
              }}
            >
              <BarChart /> Mood Tracker
            </li>
            <li
              className={`${this.props.selectedTab === MY_BOOKINGS ? "active" : ""}`}
              onClick={() => {
                this.props.showMyBookings();
              }}
            >
              <EventAvailable /> My bookings
            </li>
            <li
              className={`${this.props.selectedTab === MINDFULNESS ? "active" : ""}`}
              onClick={() => {
                this.props.showMindfulness();
              }}
            >
              <MoodIcon /> Mindfulness
            </li>
            <li
              className={`${this.props.selectedTab === ASSESSMENTS ? "active" : ""}`}
              onClick={() => {
                this.props.showAssessments();
              }}
            >
              <Apps /> Assessments
            </li>
            <li
              className={`${this.props.selectedTab === WORKSHOPS ? "active" : ""}`}
              onClick={() => {
                this.props.showWorkshops();
              }}
            >
              <WorkOutlineOutlinedIcon /> Workshops
            </li>
            {this.props.shouldWalletAppear && (
              <li
                className={`${this.props.selectedTab === WALLET ? "active" : ""}`}
                onClick={() => {
                  this.props.showWallet();
                }}
              >
                <AccountBalanceWallet /> Wallet
              </li>
            )}
            <li
              className={`${this.props.selectedTab === SUPPORT ? "active" : ""}`}
              onClick={() => {
                this.props.showSupport();
              }}
            >
              <PhoneIcon /> Support
            </li>

            <Link to="/sessionBooking" style={{ color: "inherit", textDecoration: "none" }}>
              <button className="whiteobottom">Book now!</button>
            </Link>
          </ul>
        </div>
      </div>
    );
  }
}

export default LeftMenuB2B;
