import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";


export default class SessionAreaChart extends PureComponent {
  // static demoUrl = 'https://codesandbox.io/s/simple-area-chart-4ujxw';
  state = {
    activeIndex: 3,
  };

  renderTooltip() {
    return <div className="tooltipcontent">4.5</div>;
  }

  render() {
    const data = this.props.data;
    const uniqueWholeNumbers = Array.from(
      new Set(data.map((entry) => Math.floor(entry.uv)))
    );
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={300} height={100} data={this.props.data}>
          <XAxis dataKey="name" />
          <YAxis ticks={[0, 1, 2, 3, 4, 5]} domain={[0, 5]} />
          <Tooltip />
          {/* <Legend /> */}
          <Line
            name="score"
            // type="monotone"
            dataKey="uv"
            stroke="#FF8B36"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>

    );
  }
}
