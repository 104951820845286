import React, { Component, useState } from "react";
// import logo from "../assets/images/headerbookinglogo.png";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ListAltIcon from "@material-ui/icons/ListAlt";
import BarChart from "@material-ui/icons/BarChart";
import EventAvailable from "@material-ui/icons/EventAvailable";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import MoodIcon from "@material-ui/icons/Mood";
import Apps from "@material-ui/icons/Apps";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import PhoneIcon from "@material-ui/icons/Phone";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import {
  ASSESSMENTS,
  MINDFULNESS,
  MOOD_TRACKER,
  MY_BOOKINGS,
  OVERVIEW,
  SUPPORT,
  WALLET,
  WORKSHOPS,
} from "./UserDashboard";
import { useUserInfoSelector } from "store/reducers";

const avatar = {
  female: "https://ucarecdn.com/5c10944e-91bc-4ee6-a259-23565aac6100/avatar3.png",
  male: "https://ucarecdn.com/e6d48801-632e-4d97-b297-bd15292f07ed/avatar2.png",
  other: "https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png",
};

const HeaderDashboard = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotificationsPanel, setShowNotificationsPanel] = useState(false);
  const userInfo = useUserInfoSelector();

  return (
    <div className="headerbookings" style={{ position: "fixed", backgroundColor: "white" }}>
      <img
        src="https://ucarecdn.com/4748640f-b537-4b9b-8896-362944eaaae3/headerbookinglogo.png"
        className="image-logo-hb"
      />
      <div className="lists-header-booking">
        <ul>
          <li>
            <Link to="/" style={{ textDecoration: "none", color: "#212529" }}>
              Home
            </Link>
          </li>
          <li>
            <a
              href="https://www.goodlives.in/how-it-works/"
              target="_blank"
              style={{ textDecoration: "none", color: "#212529" }}
            >
              How it works?
            </a>
          </li>
          <li>
            <Link to="/corporates" style={{ textDecoration: "none", color: "#212529" }}>
              For Corporates
            </Link>
          </li>
          <li>
            <a
              href="https://www.goodlives.in/blog/"
              target="_blank"
              style={{ textDecoration: "none", color: "#212529" }}
            >
              Blogs
            </a>
          </li>
        </ul>
      </div>
      <div className="menu-header-booking">
        <div className="lists-tn notificationsboxcontainer">
          <NotificationsNoneIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowNotificationsPanel(true);
            }}
          />
          <div className="ring-counts">
            <p>{notifications.length}</p>
          </div>
          {showNotificationsPanel && (
            <div className="inner-notificationboxmain">
              <div className="top-inbm">
                <p>Notifications</p>
              </div>
              <div className="bottom-inbm">
                {notifications.length > 0 ? (
                  <ol>
                    {notifications.map((e, i) => {
                      return (
                        <li
                          onClick={() => {
                            props.showMyBookings();
                            document.getElementById("root").style.overflowY = "auto";
                            document.getElementById("root").style.position = "static";
                          }}
                        >
                          {i + 1}. Notification {e}
                        </li>
                      );
                    })}
                  </ol>
                ) : (
                  <p className="nonewnotficiationslabel">
                    <NotificationsOffIcon />
                    <font>
                      No New <br /> Notifications
                    </font>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        {/* <div className="lists-tn">
                        <SettingsIcon />
                    </div> */}
        <button
          onClick={props.toggleProfilePopup}
          style={{ outline: "none", border: "0", borderRadius: "100px", margin: "0 10px" }}
        >
          <img
            src={avatar[userInfo.gender || "male"]}
            alt="user-avatar"
            className="avatar"
            style={{ width: "34px", height: "34px", borderRadius: "100px", margin: "-5px" }}
          />
        </button>
        <div className="lists-tn">
          <Tooltip
            title="Log Out"
            zIndex="999999999"
            placement="bottom"
            overlayInnerStyle={{
              borderRadius: "10px",
              fontSize: "10px",
              fontWeight: "bold",
              color: "#000",
            }}
            color="#e8e8e8"
          >
            <div style={{ cursor: "pointer" }} onClick={props.handleLogout}>
              <ExitToAppIcon />
            </div>
          </Tooltip>
        </div>
        {window.innerWidth < 1051 && (
          <div className="lists-tn">
            <MenuIcon
              onClick={() => {
                document.getElementById("root").style.overflowY = "hidden";
                document.getElementById("root").style.position = "fixed";
                setMenuOpen(true);
              }}
            />
          </div>
        )}
      </div>
      {menuOpen && (
        <div className="menu-lists-mobile">
          <CloseIcon
            style={{
              position: "absolute",
              top: 15,
              right: 15,
              zIndex: 99,
              fill: "white",
            }}
            onClick={() => {
              document.getElementById("root").style.overflowY = "auto";
              document.getElementById("root").style.position = "static";
              setMenuOpen(false);
            }}
          />
          <div className="left-menustuff">
            <p className="title-lms">
              GoodLives<sup style={{ fontSize: "14px" }}>TM</sup>
            </p>
            <div className="content-lms">
              <ul>
                <li
                  className={`${props.selectedTab === OVERVIEW ? "active" : ""}`}
                  onClick={() => {
                    props.showOverView();
                    document.getElementById("root").style.overflowY = "auto";
                    document.getElementById("root").style.position = "static";
                  }}
                >
                  <ListAltIcon /> Overview
                </li>
                <li
                  className={`${props.selectedTab === MOOD_TRACKER ? "active" : ""}`}
                  onClick={() => {
                    props.showMoodTracker();
                    document.getElementById("root").style.overflowY = "auto";
                    document.getElementById("root").style.position = "static";
                  }}
                >
                  <BarChart /> Mood Tracker
                </li>
                <li
                  className={`${props.selectedTab === MY_BOOKINGS ? "active" : ""}`}
                  onClick={() => {
                    props.showSessions();
                    document.getElementById("root").style.overflowY = "auto";
                    document.getElementById("root").style.position = "static";
                  }}
                >
                  <EventAvailable /> My bookings
                </li>
                <li
                  className={`${props.selectedTab === MINDFULNESS ? "active" : ""}`}
                  onClick={() => {
                    props.showMindfulness();
                    document.getElementById("root").style.overflowY = "auto";
                    document.getElementById("root").style.position = "static";
                  }}
                >
                  <MoodIcon /> Mindfulness
                </li>
                <li
                  className={`${props.selectedTab === ASSESSMENTS ? "active" : ""}`}
                  onClick={() => {
                    props.sshowAssessments();
                    document.getElementById("root").style.overflowY = "auto";
                    document.getElementById("root").style.position = "static";
                  }}
                >
                  <Apps /> {props.categoryId === 3 ? "Assessment Score" : "Assessment"}
                </li>
                <li
                  className={`${props.selectedTab === WORKSHOPS ? "active" : ""}`}
                  onClick={() => {
                    props.showWorkshops();
                    document.getElementById("root").style.overflowY = "auto";
                    document.getElementById("root").style.position = "static";
                  }}
                >
                  <WorkOutlineOutlinedIcon /> Workshops
                </li>
                {props.shouldWalletAppear && (
                  <li
                    className={`${props.selectedTab === WALLET ? "active" : ""}`}
                    onClick={() => {
                      props.showWallet();
                      document.getElementById("root").style.overflowY = "auto";
                      document.getElementById("root").style.position = "static";
                    }}
                  >
                    <AccountBalanceWallet /> Wallet
                  </li>
                )}
                <li
                  className={`${props.selectedTab === SUPPORT ? "active" : ""}`}
                  onClick={() => {
                    props.showSupport();
                    document.getElementById("root").style.overflowY = "auto";
                    document.getElementById("root").style.position = "static";
                  }}
                >
                  <PhoneIcon /> Support
                </li>
                {props.categoryId === 4 ? (
                  <button
                    className="whiteobottom"
                    style={{ width: "170px" }}
                    onClick={(e) => {
                      const newWindow = window.open("https://wa.me/917478530132", "_blank", "noopener,noreferrer");
                      if (newWindow) newWindow.opener = null;
                    }}
                  >
                    Contact Admin
                  </button>
                ) : (
                  <button className="whiteobottom">
                    <Link to="/sessionBooking" style={{ color: "inherit", textDecoration: "none" }}>
                      Book now!
                    </Link>
                  </button>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
      {showNotificationsPanel && (
        <div
          className="popup-payment-alert-overaly"
          style={{ backgroundColor: "transparent" }}
          onClick={() => {
            setShowNotificationsPanel(false);
          }}
        ></div>
      )}
    </div>
  );
};

export default HeaderDashboard;
