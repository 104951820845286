import React, { Component } from "react"
import "./index.css"
import ListAltIcon from "@material-ui/icons/ListAlt"
import GroupIcon from "@material-ui/icons/Group"
import BarChart from "@material-ui/icons/BarChart"
import MoodIcon from "@material-ui/icons/Mood"
import PhoneIcon from "@material-ui/icons/Phone"
import { Link } from 'react-router-dom'
import Timeline from "@material-ui/icons/Timeline"
import { HR_ASSESSMENT_SCORE, HR_OVERVIEW, HR_SESSIONS, HR_SUPPORT, HR_USERS, HR_ZEN_ANALYTICS } from "./HRDashboard"
export class LeftMenu extends Component {

  render() {
    return (
      <div className='left-menustuff'>
        <p className='title-lms'>
          GoodLives<sup>TM</sup>
        </p>
        <div className='content-lms'>
          <ul>
            <li
              className={`${this.props.selectedTab === HR_OVERVIEW ? "active" : ""}`}
              onClick={() => {
                this.props.showOverView()
              }}
            >
              <ListAltIcon /> Overview
            </li>
            <li
              className={`${this.props.selectedTab === HR_USERS ? "active" : ""}`}
              onClick={() => {
                this.props.showUsers()
              }}
            >
              <GroupIcon /> Users
            </li>
            <li
              className={`${this.props.selectedTab === HR_SESSIONS ? "active" : ""}`}
              onClick={() => {
                this.props.showSessions()
              }}
            >
              <BarChart /> Sessions
            </li>
            <li
              className={`${this.props.selectedTab === HR_ASSESSMENT_SCORE ? "active" : ""}`}
              onClick={() => {
                this.props.showOrganizational()
              }}
            >
              <MoodIcon /> Assessment Score
            </li>
            <li  className={`${this.props.selectedTab === HR_ZEN_ANALYTICS ? "active" : ""}`}
              onClick={() => {
                this.props.showSlack()
              }}
            > 
            <Timeline/>Zen  Analytics</li>
            {/* <li onClick={() => {
              this.props.showstartchallenge()
              this.setState({
                startchallenge: true,
                organizationalActive: false,
                sessionsActive: false,
                usersActive: false,
                supportActive: false,
                overviewActive: false,
                viewchallenge: false,
                startsurvey: false,
              })
            }}><MoodIcon />Challenge</li> */}

            {/* <li onClick={() => {
              this.props.showmychallenge()
              this.setState({
                startchallenge: false,
                viewchallenge: true,
                organizationalActive: false,
                sessionsActive: false,
                usersActive: false,
                supportActive: false,
                overviewActive: false,
                startsurvey:false,
              })
            }}><MoodIcon />View my Challenge</li> */}

            {/* <li onClick={() => {
              this.props.showstartsurvey()
              this.setState({
                startsurvey:true,
                startchallenge: false,
                organizationalActive: false,
                sessionsActive: false,
                usersActive: false,
                supportActive: false,
                overviewActive: false,
                viewchallenge: false,
              })
            }}><MoodIcon />Survey</li> */}

            <li
              className={`${this.props.selectedTab === HR_SUPPORT ? "active" : ""}`}
              onClick={() => {
                this.props.showSupport()
              }}
            >
              <PhoneIcon /> Support
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default LeftMenu
