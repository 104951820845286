import axios from "axios";

export const loadAllQuiz = async (token) =>
  await axios.get(process.env.REACT_APP_SERVER_URI + "/api/quiz", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const loadSpecificQuiz = async (quizNo, token) =>
  await axios.get(process.env.REACT_APP_SERVER_URI + `/api/quiz/${quizNo}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const loadSpecificQuizQuestions = async (quizNo, token) => {
  return await axios.get(
    process.env.REACT_APP_SERVER_URI + `/api/quiz/question/${quizNo}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const loadResult = async (quizNo, score, token) => {
  return await axios.get(
    process.env.REACT_APP_SERVER_URI + `/api/quiz/range/${quizNo}/${score}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const loadQuizNumber = async (urlSlug, token) => {
  return await axios.get(
    process.env.REACT_APP_SERVER_URI + `/api/quiz/getQuizNo/${urlSlug}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
