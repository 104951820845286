import React, { PureComponent } from "react";
import { Treemap, ResponsiveContainer, Tooltip } from "recharts";

class CustomizedContent extends PureComponent {
    render() {
        const {
            root,
            depth,
            x,
            y,
            width,
            height,
            index,
            payload,
            rank,
            name,
            color,
        } = this.props;
        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    style={{
                        fill: color,
                        stroke: "#fff",
                        strokeWidth: 2 / (depth + 1e-10),
                        strokeOpacity: 1 / (depth + 1e-10),
                    }}
                />
                {depth === 1 ? (
                    <text
                        x={x + width / 2}
                        y={y + height / 2 + 7}
                        textAnchor="middle"
                        fill="#fff"
                        fontSize={14}
                    >
                        {name}
                    </text>
                ) : null}
                {depth === 1 ? (
                    <text
                        x={x + 4}
                        y={y + 18}
                        fill="#fff"
                        fontSize={16}
                        fillOpacity={0.9}
                    >
                        {index + 1}
                    </text>
                ) : null}
            </g>
        );
    }
}

const OverviewChart = ({ items }) => {
    const CustomTooltip = ({ active, payload, items }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p style={{ color: "#fff", marginTop: "10px", fontWeight: 600 }}>
                        {`Problem : ${payload[0].payload.name}`}
                        <br></br>
                        {`Session Count : ${payload[0].payload.size}`}
                        <br></br>
                        {`Users Count : ${payload[0].payload.count}`}
                    </p>
                </div>
            );
        }

        return null;
    };
    return (
        <ResponsiveContainer width="100%" height="100%">
            <Treemap
                width={400}
                height={200}
                data={items}
                dataKey="size"
                ratio={4 / 3}
                stroke="#fff"
                fill="#8884d8"
                content={<CustomizedContent />}
            >
                <Tooltip content={<CustomTooltip />} />
            </Treemap>
        </ResponsiveContainer>
    );
};

export default OverviewChart;